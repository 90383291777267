import { ProductType, SubscriptionDuration } from '../types';
import { Entity } from './entity';

/** Tracks subscription details for any product. */
export class Subscription extends Entity {
  /** Creates a Subscription instance with the given data. */
  static create(
    entityId: string,
    owner: string,
    product: ProductType,
    duration: SubscriptionDuration,
    isRecurring: boolean,
    expiryDate: Date,
    searchTags: string[] = [],
    subscriptionId = '',
  ): Subscription {
    return new Subscription(
      subscriptionId,
      entityId,
      owner,
      product,
      duration,
      isRecurring,
      expiryDate,
      searchTags,
    );
  }

  /** Creates a Subscription instance for the given entity. */
  static forEntity(
    entityId: string,
    owner: string,
    product: ProductType,
    searchTags: string[] = [],
  ): Subscription {
    return Subscription.create(
      entityId,
      owner,
      product,
      SubscriptionDuration.Monthly,
      false,
      // Subscription must be inactive.
      new Date(2000, 0, 1),
      searchTags,
    );
  }

  /** Descriptive message based on the expiry date. */
  get expiryText(): string {
    const today = new Date();

    if (
      this.expiryDate.getFullYear() === today.getFullYear() &&
      this.expiryDate.getMonth() === today.getMonth() &&
      this.expiryDate.getDate() === today.getDate()
    ) {
      return 'Expires today.';
    }

    if (this.isActive()) {
      return `Expires on ${this.expiryDate.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'long',
        weekday: 'long',
        year: 'numeric',
      })}`;
    }

    return 'Inactive.';
  }

  /** Determines whether this Subscription instance is active or not. */
  isActive(): boolean {
    // 11:59:59 PM of the expiry date.
    const endOfExpirationDate = new Date(
      this.expiryDate.getFullYear(),
      this.expiryDate.getMonth(),
      this.expiryDate.getDate(),
      23,
      59,
      59,
    );

    return endOfExpirationDate.valueOf() > Date.now();
  }

  /** Returns the future expiry date for a one month extension. */
  getMonthlyExtension(): Date {
    const expiry = this.isActive() ? this.expiryDate : new Date();

    return new Date(
      expiry.getFullYear(),
      expiry.getMonth() + 1,
      expiry.getDate(),
    );
  }

  /** Compares this Subscription instance to another. */
  isEqual(other: Subscription): boolean {
    return (
      other &&
      other.id === this.id &&
      other.entityId === this.entityId &&
      other.owner === this.owner &&
      other.product === this.product &&
      other.duration === this.duration &&
      other.isRecurring === this.isRecurring &&
      other.expiryDate.valueOf() === this.expiryDate.valueOf() &&
      other.searchTags.join() === this.searchTags.join()
    );
  }

  /** Creates a copy of this Subscription instance with a new expiry date. */
  withExpiryDate(expiryDate: Date): Subscription {
    return Subscription.create(
      this.entityId,
      this.owner,
      this.product,
      this.duration,
      this.isRecurring,
      expiryDate,
      this.searchTags,
      this.id,
    );
  }

  /** Creates a copy of this Subscription instance with a new ID. */
  withId(id: string): Subscription {
    return Subscription.create(
      this.entityId,
      this.owner,
      this.product,
      this.duration,
      this.isRecurring,
      this.expiryDate,
      this.searchTags,
      id,
    );
  }

  /** Creates a copy of this subscription instance. */
  copy(): Subscription {
    return this.withId(this.id);
  }

  protected constructor(
    // Unique Firestore ID.
    readonly id: string,
    // ID of the related Firestore document.
    readonly entityId: string,
    // ID of the user who created this subscription.
    readonly owner: string, // Rename this?
    // Related product.
    readonly product: ProductType,
    // Duration of the subscription.
    readonly duration: SubscriptionDuration,
    // Whether the subscription is recurring or not.
    readonly isRecurring: boolean,
    // Expiry date.
    readonly expiryDate: Date,
    // Search tags related to the underlying Firestore document.
    readonly searchTags: string[],
  ) {
    super('sbn');
  }
}
