/**
 * Geo-location utilities.
 */
import { getGeocoder } from '@libraries/google';
import debounce from 'lodash/debounce';

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
}

/**
 * Retrieves an address component from a {@link google.maps.GeocoderResult}.
 */
function getComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string,
  shortName?: boolean,
) {
  const values = components
    .filter((c) => c.types.includes(type))
    .map((c) => (shortName ? c.short_name : c.long_name));

  return values.length ? values[0] : '';
}

/**
 * Converts a {@link google.maps.GeocoderResult} to an {@link Address}.
 */
export function convertGeocoderResultToAddress(
  result: google.maps.GeocoderResult,
): Address {
  const {
    address_components: components,
    formatted_address: address,
    geometry,
  } = result;

  return {
    address,
    city: getComponent(components, 'locality'),
    state: getComponent(components, 'administrative_area_level_1', true),
    zip: getComponent(components, 'postal_code'),
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
  };
}

/**
 * Makes use of the Geocoder API to turn a search query into an address.
 *
 * https://developers.google.com/maps/documentation/javascript/geocoding
 */
export async function searchForAddresses(query: string): Promise<Address[]> {
  const sanitizedQuery = query.trim();
  const geocoder = getGeocoder();

  if (!geocoder || sanitizedQuery.length < 2) {
    return [];
  }

  const request: google.maps.GeocoderRequest = {
    address: sanitizedQuery,
    componentRestrictions: {
      country: 'US',
    },
    // region: 'US',
  };

  return new Promise((resolve) => {
    geocoder.geocode(request, (results, status) => {
      if (status !== 'OK') {
        return resolve([]);
      }

      resolve(results.map(convertGeocoderResultToAddress));
    });
  });
}

export const debounceSearchForAddresses = debounce(searchForAddresses, 200);
