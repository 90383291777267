import { FaLightbulb } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const iconAnimation = keyframes`
  from {
    transform: rotate(-20deg);
  }

  to {
    transform: rotate(20deg);
  }
`;

const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  text-align: center;
`;

const EmptyStateMessage = styled.div`
  font-size: 1.2rem;
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.lightGrey.string()};
  font-size: 3rem;
  padding: 1rem;

  svg {
    animation: ${iconAnimation} 700ms ease-in-out infinite alternate;
  }
`;

interface EmptyStateProps {
  children?: React.ReactNode;
  msg: string;
}

export function EmptyState({ children, msg }: EmptyStateProps) {
  return (
    <EmptyStateWrapper>
      <EmptyStateMessage>{msg}</EmptyStateMessage>

      <IconWrapper>
        <FaLightbulb />
      </IconWrapper>

      {children}
    </EmptyStateWrapper>
  );
}
