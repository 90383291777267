import {
  FacebookAuthProvider,
  getAuth,
  signInWithRedirect,
} from 'firebase/auth';

export async function signInWithFacebook() {
  const provider = new FacebookAuthProvider();

  signInWithRedirect(getAuth(), provider);
}
