import React from 'react';
import styled from 'styled-components';

const OuterWrapper = styled.section<{ highlight: boolean }>`
  background-color: transparent;
  background-image: ${(props) =>
    props.highlight
      ? `linear-gradient(270deg, ${props.theme.cyan.string()} -10%, ${props.theme.navy.string()} 110%)`
      : 'none'};
  background-origin: padding-box;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  color: ${(props) =>
    props.highlight
      ? props.theme.backgroundColor.string()
      : props.theme.textColor.string()};
  width: 100%;
`;

const BorderWrapper = styled.div<{ borderBottom: string }>`
  border-bottom: ${(props) => props.borderBottom};
`;

interface InnerWrapperProps {
  flexDirection: FlexDirection;
  justifyContent: string;
  paddingBottom: string;
  paddingTop: string;
}

const InnerWrapper = styled.div<InnerWrapperProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent};
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem 2rem;

  @media (min-width: 800px) {
    padding-bottom: ${(props) => props.paddingBottom};
    padding-top: ${(props) => props.paddingTop};
  }
`;

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

interface SectionProps {
  as?: string;
  borderBottom?: string;
  children: React.ReactNode;
  flexDirection?: FlexDirection;
  highlight?: boolean;
  justifyContent?: string;
  paddingBottom?: string;
  paddingTop?: string;
}

export function Section({
  as,
  borderBottom,
  children,
  flexDirection,
  highlight,
  justifyContent,
  paddingBottom,
  paddingTop,
}: SectionProps) {
  return (
    <OuterWrapper as={as} highlight={highlight}>
      <BorderWrapper borderBottom={borderBottom}>
        <InnerWrapper
          flexDirection={flexDirection}
          justifyContent={justifyContent}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}>
          {children}
        </InnerWrapper>
      </BorderWrapper>
    </OuterWrapper>
  );
}

Section.defaultProps = {
  as: 'section',
  borderBottom: 'none',
  flexDirection: 'row',
  highlight: false,
  justifyContent: 'space-between',
  paddingBottom: '4rem',
  paddingTop: '4rem',
};
