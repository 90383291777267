import { SITE_NAME } from '@utils';
import Head from 'next/head';

export function HeadTitle({ title }: { title: string }) {
  return (
    <Head>
      <title>
        {title} | {SITE_NAME}
      </title>
    </Head>
  );
}
