import { getProductDetails, ProductDetails } from '@dao';
import { useEffect, useState } from 'react';

interface ProductDetailsHookResponse {
  isLoading: boolean;
  productDetails: ProductDetails;
}

/** Hook for retrieving Product details from Firestore. */
export function useProductDetails(): ProductDetailsHookResponse {
  const [isLoading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState<ProductDetails>(
    ProductDetails.DEFAULT,
  );

  useEffect(() => {
    setLoading(true);
    getProductDetails().then((details) => {
      setProductDetails(details);
      setLoading(false);
    });
  }, []);

  return { isLoading, productDetails };
}
