import { ProfileType } from '@dao';
import styled from 'styled-components';
// TODO: why does importing from "@shared" not work?
import { Pill } from '../../shared/components/Pill';
import { PersonnelSearchFilterOptions } from './types';

const EmptyFiltersLabel = styled(Pill).attrs({ cyan: true })`
  cursor: pointer;
`;

const CollapsedFiltersWrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: auto;
`;

const Label = styled.span`
  cursor: pointer;
`;

function getOptionsList(options: PersonnelSearchFilterOptions): string[] {
  const list: string[] = [];

  if (options.city) {
    list.push(options.city);
  }

  if (options.state) {
    list.push(options.state);
  }

  if (options.type) {
    for (const type of options.type) {
      if (type === ProfileType.Coordinator) {
        list.push('Coordinator');
      } else if (type === ProfileType.Instructor) {
        list.push('Instructor');
      }
    }
  }

  return list;
}

interface CollapsedFiltersProps {
  onExpand: () => void;
  options: PersonnelSearchFilterOptions;
}

export function CollapsedFilters({ onExpand, options }: CollapsedFiltersProps) {
  const optionsList = getOptionsList(options);

  return (
    <CollapsedFiltersWrapper onClick={onExpand}>
      {optionsList.length ? (
        <>
          <Label>Current search filters:</Label>
          {optionsList.map((option) => (
            <Pill cyan key={option}>
              {option}
            </Pill>
          ))}
        </>
      ) : (
        <EmptyFiltersLabel>
          Advanced search: click here to filter search results
        </EmptyFiltersLabel>
      )}
    </CollapsedFiltersWrapper>
  );
}
