import logoImg from '@assets/logo.png';
import Image from 'next/legacy/image';
import { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const ModalWrapper = styled.div<{ visible?: boolean }>`
  bottom: 0;
  color: ${(props) => props.theme.textColor.string()};
  display: ${(props) => (props.visible ? 'block' : 'none')};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
`;

const Background = styled.div`
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: ${(props) => props.theme.navy.fade(0.4).string()};
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  transition: all 300ms;
  width: 100%;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.string()};
  box-shadow: 0 0 10px ${(props) => props.theme.textColor.string()};
  height: 100%;
  padding: 1rem;
  position: relative;
  width: 100%;

  @media (min-width: 1024px) {
    border-radius: 4px;
    height: auto;
    max-height: 60vh;
    overflow-y: auto;
    top: 20vh;
    width: 600px;
  }
`;

const Logo = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 20vh;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.lightGrey.string()};
  cursor: pointer;
  position: absolute;
  right: 35px;
  transition: color 300ms;
  width: 1rem;

  &:hover {
    color: ${(props) => props.theme.grey.string()};
  }

  @media (min-width: 1024px) {
    right: 20px;
    width: 1.5rem;
  }
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 5rem 1rem 1rem;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
`;

const Body = styled.div`
  padding: 1rem;
`;

interface ModalProps {
  children: React.ReactNode;
  onHide: () => void;
  title?: string;
  visible?: boolean;
}

export function Modal({ children, onHide, title, visible }: ModalProps) {
  const bgRef = useRef();

  if (!visible) {
    return null;
  }

  function handleOutsideClick(event: Event) {
    if (event.target === bgRef.current) {
      onHide();
    }
  }

  return (
    <ModalWrapper visible={visible}>
      <Background onClick={handleOutsideClick} ref={bgRef}>
        <Logo>
          <Image
            alt="CNA Classes For Me Logo"
            height="60"
            src={logoImg}
            width="60"
          />
        </Logo>
        <Content>
          <CloseButton onClick={onHide}>
            <FaTimes />
          </CloseButton>
          <Title>{title}</Title>
          <Body>{children}</Body>
        </Content>
      </Background>
    </ModalWrapper>
  );
}
