import {
  isEmailVerified,
  useFirebaseAuthUserContext,
} from '@libraries/firebase';
import { useEffect, useState } from 'react';
import { UserProfile, UserProfileDao } from '../../dao';
import { logDebug, logError } from '../reporting';

/** React hook for getting the profile of the signed-in user. */
export function useUserProfile() {
  const { user } = useFirebaseAuthUserContext();
  const [profile, setProfile] = useState<UserProfile>(null);

  useEffect(() => {
    if (user) {
      // Get user profile.
      UserProfileDao.getUserProfile(user.uid)
        .then((userProfile) => {
          // If profile exists, store it in state. If not, create a new one and
          // store it in state and in Firestore.
          if (userProfile) {
            setProfile(userProfile);
            logDebug('User profile:', userProfile, user);
          } else {
            const newProfile = UserProfile.fromFirebaseUser(user);

            setProfile(newProfile);
            logDebug('[NEW] User profile:', newProfile, user);

            if (isEmailVerified(user)) {
              UserProfileDao.upsertUserProfile(newProfile);
            }
          }
        })
        .catch(logError);
    }
  }, [user]);

  return profile;
}
