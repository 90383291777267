import { Address } from '@utils';
import styled from 'styled-components';
import { useAddressSearchOptions } from '../../hooks';
import { DataList } from './DataList';
import { SearchInput, SearchInputProps } from './SearchInput';

const AddressInputWrapper = styled.div`
  margin-left: -2rem;
  width: 100%;

  ${DataList} {
    margin-left: 1rem;
  }
`;

type AddressInputProps = Omit<SearchInputProps<Address>, 'options'>;

export function AddressInput(props: AddressInputProps) {
  const { options } = useAddressSearchOptions(props.value);

  return (
    <AddressInputWrapper>
      <SearchInput {...props} options={options} />
    </AddressInputWrapper>
  );
}
