import { PersonnelSearchFilters } from '@personnel';
import { logDebug } from '@reporting';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  QueryConstraint,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import {
  PersonnelProfile,
  PersonnelProfileVisibility,
} from './entities/personnelProfile';
import { ListResourceResponse } from './listResponse';

/** Handles data access for {@link PersonnelProfile} entities. */
export class PersonnelDao {
  static readonly COLLECTION_NAME = 'personnel';

  /** Firestore data converter for {@link PersonnelProfile} entities. */
  static readonly CONVERTER: FirestoreDataConverter<PersonnelProfile> = {
    fromFirestore: function (document) {
      const {
        city,
        email,
        firstName,
        lastName,
        name,
        phone,
        photo,
        social,
        state,
        type,
        visibility,
        zip,
      } = document.data();

      const profile = PersonnelProfile.create(
        city,
        email,
        firstName,
        lastName,
        phone,
        photo,
        { ...social },
        state,
        type,
        document.id,
        visibility,
        zip,
      );

      profile.name = name;

      return profile;
    },
    toFirestore: function (profile: PersonnelProfile) {
      const { userId, entityCode, ...document } = profile;

      return { ...document };
    },
  };

  /** Returns a {@link PersonnelProfile} document. */
  static async getPersonnelProfile(userId: string): Promise<PersonnelProfile> {
    const record = await getDoc(this.getDocumentRef(userId));

    return record.exists()
      ? record.data()
      : PersonnelProfile.createEmpty(userId);
  }

  /** Lists {@link PersonnelProfile} documents. */
  static async listPersonnel(
    filters: PersonnelSearchFilters,
  ): Promise<ListResourceResponse<PersonnelProfile>> {
    logDebug(
      '[dao]',
      '[personnelDao]',
      '[listPersonnel]',
      '[filters]',
      filters,
    );

    const clauses: QueryConstraint[] = [];
    const clausesDebug: string[] = [];

    if (filters.city) {
      clauses.push(where('city', '==', filters.city));
      clausesDebug.push(`city == ${filters.city}`);
    }

    if (filters.state) {
      clauses.push(where('state', '==', filters.state));
      clausesDebug.push(`state == ${filters.state}`);
    }

    if (filters.type) {
      clauses.push(where('type', 'in', filters.type));
      clausesDebug.push(`type == ${filters.type}`);
    }

    clauses.push(
      where('visibility', '==', PersonnelProfileVisibility.Published),
    );
    clausesDebug.push(`visibility == ${PersonnelProfileVisibility.Published}`);

    clauses.push(limit(filters.limit));
    clausesDebug.push(`limit == ${filters.limit}`);

    if (filters.page.isValid) {
      const record = await getDoc(this.getDocumentRef(filters.page.entityId));

      clauses.push(startAfter(record));
      clausesDebug.push(`startAfter == ${filters.page.entityId}`);
    }

    logDebug(
      '[dao]',
      '[personnelDao]',
      '[listPersonnel]',
      '[clauses]',
      clausesDebug,
    );

    const results = await getDocs(query(this.getCollectionRef(), ...clauses));

    logDebug(
      '[dao]',
      '[personnelDao]',
      '[listPersonnel]',
      '[results]',
      results.docs.map((r) => r.data().firstNameComputed),
    );

    return ListResourceResponse.fromFirestoreResults(results, filters.limit);
  }

  /** Creates or updates a {@link PersonnelProfile} document. */
  static async upsertPersonnelProfile(
    personnelProfile: PersonnelProfile,
  ): Promise<PersonnelProfile> {
    await setDoc(
      this.getDocumentRef(personnelProfile.userId),
      personnelProfile,
    );

    return personnelProfile;
  }

  /** Deletes a {@link PersonnelProfile} document. */
  static async deletePersonnelProfile(userId: string): Promise<void> {
    await deleteDoc(this.getDocumentRef(userId));
  }

  /** Returns a Firestore collection reference for personnel. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<PersonnelProfile> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given personnel profile. */
  protected static getDocumentRef(
    userId: string,
  ): DocumentReference<PersonnelProfile> {
    return doc(getFirestore(), this.COLLECTION_NAME, userId).withConverter(
      this.CONVERTER,
    );
  }
}
