import { getProtocolAndHost } from '@utils';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth';
import { logError } from '../../../shared/reporting';
import { handleError } from './handleError';

export async function createEmailAccount(
  email: string,
  password: string,
  redirectTo: string,
  onError: (emailError: string, passwordError: string) => void,
  onSignedIn: () => void,
) {
  try {
    const userCredentials = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );

    await sendEmailVerification(userCredentials.user, {
      url: `${getProtocolAndHost()}${redirectTo}`,
    });

    onSignedIn();
  } catch (error) {
    const [errorField, errorMsg] = handleError(error);

    if (!errorField) {
      logError(error);
    } else if (errorField === 'email') {
      onError(errorMsg, null);
    } else {
      onError(null, errorMsg);
    }
  }
}
