import Link from 'next/link';
import { forwardRef } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';

interface AnchorProps {
  active?: boolean;
}

interface NavLinkProps extends AnchorProps {
  href: string;
  label: string;
  onClick?: () => void;
}

const Anchor = styled.a<AnchorProps>`
  align-items: center;
  background-color: ${(props) =>
    props.active ? props.theme.lightGrey.fade(0.9).string() : 'transparent'};
  border-left: 3px solid
    ${(props) => (props.active ? props.theme.navy.string() : 'transparent')};
  border-radius: 4px;
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  min-height: 1.6rem;
  padding: 0.5rem;
  text-decoration: none;
  transition:
    background-color 300ms,
    border-left-color 300ms;

  span:last-child {
    color: ${(props) => props.theme.lightGrey.string()};
    display: flex;
    opacity: 0;
    transition: opacity 300ms;
    width: 1rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.lightGrey.fade(0.9).string()};
    border-left-color: ${(props) =>
      props.theme.cyan.mix(props.theme.white).string()};

    span:last-child {
      opacity: 1;
    }
  }
`;

/** Used to link to pages OUTSIDE the app. */
export const VerticalNavAnchorLink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>(({ active, href, label, onClick }, ref) => (
  <Anchor active={active} href={href} onClick={onClick} ref={ref}>
    <span>{label}</span>
    <span>
      <FaArrowRight />
    </span>
  </Anchor>
));
VerticalNavAnchorLink.displayName = 'VerticalNavAnchorLink';

/** Used to link to pages WITHIN the app. */
export function VerticalNavLink({
  active,
  href,
  label,
  onClick,
}: NavLinkProps) {
  return (
    <Link href={href} passHref legacyBehavior>
      <VerticalNavAnchorLink
        active={active}
        href={href}
        label={label}
        onClick={onClick}
      />
    </Link>
  );
}

VerticalNavAnchorLink.defaultProps = {
  active: false,
  onClick: () => null,
};

VerticalNavLink.defaultProps = {
  active: false,
  onClick: () => null,
};
