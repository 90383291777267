import { getInvoiceRoute } from '../../shared/routes';
import { calculateTaxAmount } from '../../utils/money';
import { Entity } from './entity';

/** Represents an invoice for a subscription purchase. */
export class Invoice extends Entity {
  /** Creates an Invoice instance with the given data. */
  static create(
    profileId: string,
    date: Date,
    description: string,
    amount: number,
    taxes: Tax[],
    contact: ContactInfo,
    invoiceId = '',
  ): Invoice {
    return new Invoice(invoiceId, profileId, date, description, amount, taxes, {
      ...contact,
    });
  }

  /** Human-friendly invoice date. */
  get formattedDate(): string {
    return this.date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  /** The address, city, state and ZIP code formatted. */
  get fullAddress(): string {
    return `${this.contact.address}, ${this.contact.city}, ${this.contact.state} ${this.contact.zip}`;
  }

  /** The first and last name formatted. */
  get fullName(): string {
    return `${this.contact.firstName} ${this.contact.lastName}`;
  }

  /** The invoice ID that will be displayed on the actual invoice. */
  get invoiceId(): string {
    return `INV-${this.id.substring(0, 8)}`;
  }

  /** The URL path representing this invoice. */
  get route(): string {
    return getInvoiceRoute(this.id);
  }

  /** The total cost in cents. */
  get totalAmount(): number {
    return this.taxes.reduce((total, tax) => {
      return total + calculateTaxAmount(this.amount, tax.percentage);
    }, this.amount);
  }

  /** Compares this Invoice instance to another. */
  isEqual(other: Invoice): boolean {
    return (
      other &&
      other.id === this.id &&
      other.profileId === this.profileId &&
      other.date.toString() === this.date.toString() &&
      other.description === this.description &&
      other.amount === this.amount &&
      JSON.stringify(other.taxes) === JSON.stringify(this.taxes) &&
      other.contact.address === this.contact.address &&
      other.contact.city === this.contact.city &&
      other.contact.companyName === this.contact.companyName &&
      other.contact.firstName === this.contact.firstName &&
      other.contact.lastName === this.contact.lastName &&
      other.contact.state === this.contact.state &&
      other.contact.zip === this.contact.zip
    );
  }

  /** Creates a copy of this invoice with the provided invoice ID. */
  withInvoiceId(invoiceId: string): Invoice {
    return Invoice.create(
      this.profileId,
      new Date(this.date.valueOf()),
      this.description,
      this.amount,
      this.taxes.map((tax) => ({ ...tax })),
      { ...this.contact },
      invoiceId,
    );
  }

  /** Creates a copy of this invoice with the provided profile ID. */
  withProfileId(profileId: string): Invoice {
    return Invoice.create(
      profileId,
      new Date(this.date.valueOf()),
      this.description,
      this.amount,
      this.taxes.map((tax) => ({ ...tax })),
      { ...this.contact },
      this.invoiceId,
    );
  }

  protected constructor(
    // Firestore ID.
    readonly id: string,
    // ID of the profile.
    readonly profileId: string,
    // Invoice date.
    readonly date: Date,
    // Invoice description.
    readonly description: string,
    // Base amount before taxes in USD cents.
    readonly amount: number,
    // Tax percentages.
    readonly taxes: Tax[],
    // Contact info.
    readonly contact: ContactInfo,
  ) {
    super('inv');
  }
}

/** Contact info passed to Invoice class when creating an instance. */
interface ContactInfo {
  // Address.
  address: string;
  // City.
  city: string;
  // Company name.
  companyName: string;
  // Email address for the provided contact.
  email: string;
  // First name.
  firstName: string;
  // Last name.
  lastName: string;
  // US State.
  state: string;
  // ZIP code.
  zip: string;
}

interface Tax {
  description: string;
  name: string;
  // Percentage as a decimal between 0 and 1.
  percentage: number;
}
