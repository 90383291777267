import Color from 'color';
import { DefaultTheme } from 'styled-components';

export const COLOR_WHITE = Color('#fff');
export const COLOR_TEXT = Color('#222');
export const COLOR_CYAN = Color('#10bce6');
export const COLOR_GREEN = Color('#329f50');
export const COLOR_GREY = Color('#959b9d');
export const COLOR_LIGHT_GREY = COLOR_GREY.mix(COLOR_WHITE);
export const COLOR_NAVY = Color('#22346a');
export const COLOR_RED = Color('#bc3a3a');
export const COLOR_YELLOW = Color('#f0c161');

export const theme: DefaultTheme = {
  // General theme
  fontFamily: '"Lato", sans-serif',
  fontSize: '16px',
  fontWeight: '400',
  fontWeightBold: '700',
  fontWeightLight: '300',
  lineHeight: '1.6',

  // Branding
  backgroundColor: COLOR_WHITE,
  textColor: COLOR_TEXT,
  cyan: COLOR_CYAN,
  green: COLOR_GREEN,
  grey: COLOR_GREY,
  lightGrey: COLOR_LIGHT_GREY,
  navy: COLOR_NAVY,
  red: COLOR_RED,
  white: COLOR_WHITE,
  yellow: COLOR_YELLOW,

  // Inputs and buttons
  buttonFontSize: '0.8rem',
  iconSize: '0.8rem',
  inputBorderRadius: '4px',
  inputFontSize: '1rem',
  inputHeight: '40px',
};
