export function PrivateHeaderTags() {
  return (
    <>
      <meta
        name="robots"
        content="noindex, noimageindex, nofollow, noarchive, nocache"
      />
    </>
  );
}
