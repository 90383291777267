import Link from 'next/link';
import { HTMLProps, useEffect, useRef } from 'react';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface BaseProps {
  inHighlightedSection?: boolean;
  margin?: string;
  primary?: boolean;
  red?: boolean;
  secondary?: boolean;
  warning?: boolean;
}

export interface ButtonProps extends BaseProps, HTMLProps<HTMLButtonElement> {}

type ThemedButtonProps = ButtonProps & ThemeProps<DefaultTheme>;

/** Determines the background color based on the Button props. */
function getBg(props: ThemedButtonProps, active?: boolean) {
  switch (true) {
    case props.inHighlightedSection:
      return active ? props.theme.white.fade(0.6) : props.theme.white.fade(0.8);

    case props.disabled:
      return props.theme.grey;

    case props.secondary:
      return active ? props.theme.cyan.darken(0.1) : props.theme.cyan;

    case props.warning:
      return active ? props.theme.yellow.darken(0.1) : props.theme.yellow;

    case props.primary:
    default:
      return active
        ? props.theme.navy.mix(props.theme.backgroundColor, 0.1)
        : props.theme.navy;
  }
}

/** Determines the text color based on the Button props. */
function getColor(props: ThemedButtonProps) {
  switch (true) {
    case props.disabled:
      return props.theme.backgroundColor;

    case props.red:
      return props.theme.red;

    case props.secondary:
    case props.warning:
      return props.theme.textColor;

    case props.primary:
    default:
      return props.theme.backgroundColor;
  }
}

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => getBg(props).string()};
  border: none;
  border-radius: ${(props) => props.theme.inputBorderRadius};
  box-shadow: 0 0 20px ${(props) => props.theme.textColor.fade(0.8).string()};
  color: ${(props) => getColor(props).string()};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: ${(props) => props.theme.fontWeight};
  height: ${(props) => props.theme.inputHeight};
  line-height: ${(props) => props.theme.inputHeight};
  margin: ${(props) => props.margin || '1rem'};
  padding: 0 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms;

  &:active,
  &:focus,
  &:hover {
    background-color: ${(props) => getBg(props, true).string()};
    color: ${(props) => getColor(props).string()};
    outline: none;
  }
`;

// Reuse the Button element for the HTML anchor element (i.e. links).
// Docs: https://styled-components.com/docs/basics#attaching-additional-props
const ButtonLinkWrapper = styled(Button).attrs({ as: 'a' })``;

export interface ButtonLinkProps
  extends BaseProps,
    HTMLProps<HTMLAnchorElement> {}

type ThemedButtonLinkProps = ButtonLinkProps & ThemeProps<DefaultTheme>;

export function ButtonLink(props: ThemedButtonLinkProps) {
  const { children, href, ...btnProps } = props;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Link href={href || '/'} passHref legacyBehavior>
      <ButtonLinkWrapper {...btnProps}>{children}</ButtonLinkWrapper>
    </Link>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

// Reuse the button link for round buttons.
const IconButtonLinkWrapper = styled(ButtonLinkWrapper).attrs({ as: 'a' })`
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 1.4rem;
  height: 28px;
  justify-content: center;
  line-height: initial;
  margin: 5px;
  padding: 0;
  text-align: center;
  width: 28px;

  svg {
    max-width: 45%;
    position: relative;
  }

  ${(props) => props.inHighlightedSection && 'box-shadow: none;'}
`;

export interface IconButtonLinkProps extends BaseProps, ButtonLinkProps {
  copyText?: string;
  icon: JSX.Element;
}

type ThemedIconButtonLinkProps = IconButtonLinkProps & ThemeProps<DefaultTheme>;

export function IconButtonLink(props: ThemedIconButtonLinkProps) {
  const linkRef = useRef();
  const { copyText, href, icon, ...btnProps } = props;

  useEffect(() => {
    if (copyText) {
      // TODO: copy text to clipboard.
    }
  }, [copyText]);

  return (
    <Link href={href || '/'} passHref legacyBehavior>
      <IconButtonLinkWrapper {...btnProps} ref={linkRef}>
        {icon}
      </IconButtonLinkWrapper>
    </Link>
  );
}
