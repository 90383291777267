import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';

export async function signOut(reload = true) {
  await firebaseSignOut(getAuth());

  if (reload) {
    // Refresh page.
    window.location.reload();
  }
}
