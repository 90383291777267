import { FaGraduationCap } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const bounceAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  
  to {
    transform: translateY(-30px);
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(-20deg);
  }
  
  to {
    transform: rotate(20deg);
  }
`;

const LoadingWrapper = styled.div`
  animation: ${bounceAnimation} 700ms ease infinite alternate;
  color: ${(props) => props.theme.lightGrey.string()};
  font-size: 2rem;
  margin: 80px auto;
  max-height: 60px;
  max-width: 60px;

  svg {
    animation: ${rotateAnimation} 1400ms ease-in-out infinite alternate;
    height: 100%;
    transform: rotate(-20deg);
    width: 100%;
  }
`;

export function Loading() {
  return (
    <LoadingWrapper>
      <FaGraduationCap />
    </LoadingWrapper>
  );
}
