import { setUserForReporting } from '@reporting';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { FirebaseAuthProps } from '../types';
import { useFirebase } from './useFirebase';

/** React hook for tracking Firebase auth state changes. */
export function useFirebaseAuthUser(): FirebaseAuthProps {
  // Initialize Firebase, since this might be the first place it is accessed
  // (through FirebaseAuthUserProvider).
  useFirebase();

  const [user, setUser] = useState<User>(null);
  const [isLoading, setLoading] = useState(true);

  async function authStateHandler(authState) {
    setUser(authState);
    setLoading(false);

    if (authState?.uid !== user?.uid) {
      setUserForReporting(authState?.email);
    }
  }

  useEffect(() => onAuthStateChanged(getAuth(), authStateHandler));

  return { isLoading, user };
}
