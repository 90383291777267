import { DefaultTheme, ThemeProps } from 'styled-components';
import { HeadingProps } from './types';

type ThemedHeadingProps = HeadingProps & ThemeProps<DefaultTheme>;

export function getColor(props: ThemedHeadingProps) {
  if (props.black) {
    return props.theme.black.string();
  }

  if (props.cyan) {
    return props.theme.cyan.string();
  }

  if (props.navy) {
    return props.theme.navy.string();
  }

  if (props.white) {
    return props.theme.white.string();
  }

  return 'inherit';
}
