import logoImg from '@assets/logo.png';
import { signOut } from '@libraries/firebase';
import Image from 'next/legacy/image';
import { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { getAdminRoute, getPersonnelRoute } from '../../../routes';
import { Anchor, Link } from '../../Link';
import { Greeting } from './Greeting';
import { NavChildProps } from './Nav';

const NavWrapper = styled.nav<{ inHighlightedSection: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const MenuButton = styled.span<{ light?: boolean; show?: boolean }>`
  background: none;
  border: none;
  color: ${(props) =>
    props.light
      ? props.theme.white.string()
      : props.theme.textColor.mix(props.theme.white, 0.3).string()};
  cursor: pointer;
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
  font-size: 1.4rem;
  height: 24px;
  width: 24px;
`;

const enterAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ExpandedMenu = styled.div<{ show?: boolean }>`
  align-items: flex-end;
  animation: ${enterAnimation} 200ms ease-in-out;
  background-color: ${(props) => props.theme.navy.string()};
  box-sizing: border-box;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  height: 100vh;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 5;

  a {
    color: ${(props) => props.theme.white.string()};
    padding: 1rem 0;
    text-align: center;
    width: 100%;
  }
`;

const LinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;

  ${Anchor} {
    background: ${(props) => props.theme.white.fade(0.9).string()};
    border-radius: 4px;
    margin: 1rem auto;
  }
`;

export function MobileNav(props: NavChildProps) {
  const [opened, setOpened] = useState(false);

  function handleHide() {
    setOpened(false);
  }

  function handleShow() {
    setOpened(true);
  }

  return (
    <NavWrapper highlight={props.inHighlightedSection}>
      <LogoWrapper>
        <Link href={props.homeRoute}>
          <Image
            alt="CNA Classes For Me Logo"
            height="60"
            src={logoImg}
            width="60"
          />
        </Link>
      </LogoWrapper>

      <MenuButton
        light={props.inHighlightedSection}
        onClick={handleShow}
        show={!opened}>
        <FaBars />
      </MenuButton>

      <ExpandedMenu show={opened}>
        <MenuButton light onClick={handleHide} show>
          <FaTimes />
        </MenuButton>

        {props.profile && (
          <Greeting inHighlightedSection mobile profile={props.profile} />
        )}

        <LinksWrapper>
          <Link href={props.directoryRoute}>Schools</Link>

          {props.isPersonnelFeatureEnabled && (
            <Link href={getPersonnelRoute()}>Personnel</Link>
          )}

          {props.profile?.isAdmin ? (
            <Link href={getAdminRoute()}>Admin</Link>
          ) : (
            <Link href={props.newSchoolRoute}>Add your school</Link>
          )}

          {!props.profile && (
            <>
              <Link href={props.logInRoute}>Log in</Link>
              <Link href={props.registerRoute}>Sign Up</Link>
            </>
          )}

          {props.profile && (
            <Link href="#?" onClick={() => signOut()}>
              Log out
            </Link>
          )}
        </LinksWrapper>
      </ExpandedMenu>
    </NavWrapper>
  );
}
