import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface NoticeProps {
  error?: boolean;
  info?: boolean;
  warning?: boolean;
}

function getColor(props: NoticeProps & ThemeProps<DefaultTheme>) {
  if (props.error) {
    return props.theme.red.mix(props.theme.white, 0.85);
  }

  if (props.warning) {
    return props.theme.yellow.mix(props.theme.white, 0.85);
  }

  if (props.info) {
    return props.theme.white;
  }

  return props.theme.cyan.mix(props.theme.white, 0.9);
}

export const Notice = styled.div<NoticeProps>`
  background-color: ${(props) => getColor(props).string()};
  border-radius: 4px;
  margin: 1rem;
  padding: 1rem;
`;

export const NoticeTitle = styled.div`
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 0 0 0.8rem;
`;
