import * as Sentry from '@sentry/nextjs';
import { ReportHandler } from 'web-vitals';
import { IS_DEVELOPMENT_ENV, IS_PRODUCTION_ENV } from '../utils/env';

export function logDebug(...messages: any[]): void {
  if (IS_DEVELOPMENT_ENV) {
    console.debug('[debug]', ...messages);
  }
}

export function logInfo(...messages: any[]): void {
  if (!IS_PRODUCTION_ENV) {
    console.info('[info]', ...messages);
  }
}

export function logWarning(...messages: any[]): void {
  console.warn(...messages);
}

export function logError(...messages: any[]): void {
  console.error(...messages);

  if (messages.length && messages[0].cause) {
    console.error(messages[0].cause);
  }

  if (IS_PRODUCTION_ENV) {
    const error =
      messages.length === 1 && messages[0] instanceof Error
        ? messages[0]
        : new Error(JSON.stringify(messages));

    reportError(error);
  }
}

/**
 * Sets the user within the current reporting context.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/identify-user/
 */
export function setUserForReporting(email: string | null): void {
  logDebug('[setUserForReporting]', email);

  if (email) {
    Sentry.setUser({ email });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

export function reportError(error: Error): void {
  if (IS_DEVELOPMENT_ENV) {
    console.error('[reportError]', error);
  } else {
    Sentry.captureException(error);
  }
}

export async function reportWebVitals(onPerfEntry?: ReportHandler) {
  if (!onPerfEntry) {
    return;
  }

  const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import(
    'web-vitals'
  );

  getCLS(onPerfEntry);
  getFID(onPerfEntry);
  getFCP(onPerfEntry);
  getLCP(onPerfEntry);
  getTTFB(onPerfEntry);
}
