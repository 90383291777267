import { useState } from 'react';

export function useSelectInput<T>(initialValue?: T) {
  const [value, setValue] = useState<T>(initialValue);

  function updateValue(value: T) {
    setValue(value);
  }

  return { updateValue, value };
}
