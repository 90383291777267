import {
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
} from 'firebase/firestore';
import { ADMIN_COLLECTION } from '../constants/shared';

/**
 * Retrieves a Firestore document for the requested admin resource.
 */
export function getAdminDocRef<T = DocumentData>(
  id: string,
): DocumentReference<T> {
  return doc(getFirestore(), ADMIN_COLLECTION, id) as DocumentReference<T>;
}
