import styled from 'styled-components';

export const InputWrapper = styled.div`
  // Cancel side margins on input.
  margin: auto -1rem;
  max-width: 100%;
  position: relative;
  width: 100%;

  // Hide down arrow in Chrome.
  &::-webkit-calendar-picker-indicator {
    display: block !important;
  }
`;
