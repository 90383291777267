import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled from 'styled-components';

interface AccordionProps {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  title: React.ReactNode;
}

const Header = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  z-index: 1;

  svg {
    margin-right: 0.5rem;
    width: 0.5rem;
  }
`;

const Hint = styled.span`
  font-size: 0.5rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
`;

const Body = styled.div``;

const AccordionWrapper = styled.div<{ expanded: boolean }>`
  width: 100%;

  ${Body} {
    height: ${(props) => (props.expanded ? 'auto' : '0')};
    overflow: ${(props) => (props.expanded ? 'auto' : 'hidden')};
    visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  }
`;

export function Accordion({
  children,
  defaultExpanded,
  title,
}: AccordionProps) {
  const [isExpanded, setExpanded] = useState(!!defaultExpanded);

  function handleClick(event) {
    event.preventDefault();

    setExpanded(!isExpanded);
  }

  return (
    <AccordionWrapper expanded={isExpanded}>
      <Header onClick={handleClick}>
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        {title}
        <Hint>[ click to {isExpanded ? 'hide' : 'show'} ]</Hint>
      </Header>
      <Body>{children}</Body>
    </AccordionWrapper>
  );
}
