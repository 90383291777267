import { useEffect } from 'react';
import { getFirebaseAnalytics } from '../analytics';
import { getFirebaseApp } from '../app';

/**
 * Initializes Firebase.
 *
 * @deprecated Use initFirebase() instead.
 */
export function useFirebase(): void {
  useEffect(() => {
    getFirebaseApp();
    getFirebaseAnalytics();
  });
}
