import Link from 'next/link';
import styled from 'styled-components';
import { UserProfile } from '../../../../dao/entities/userProfile';
import { getProfileRoute } from '../../../routes';
import { Avatar } from '../../Avatar';

const GreetingWrapper = styled.a<{
  inHighlightedSection?: boolean;
  mobile?: boolean;
}>`
  align-items: center;
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  text-decoration: none;

  span:first-child {
    color: ${(props) =>
      props.inHighlightedSection
        ? props.theme.white.string()
        : props.theme.textColor.string()};
    margin-top: ${(props) => (props.mobile ? '1rem' : '0')};
    order: ${(props) => (props.mobile ? '1' : '0')};
    transition: color 300ms;
  }
`;

interface GreetingProps {
  inHighlightedSection?: boolean;
  mobile?: boolean;
  profile: UserProfile;
}

export function Greeting({
  inHighlightedSection,
  mobile,
  profile,
}: GreetingProps) {
  const name = profile.name || 'Guest';

  return (
    <Link href={getProfileRoute()} passHref legacyBehavior>
      <GreetingWrapper
        inHighlightedSection={inHighlightedSection}
        mobile={mobile}>
        <span>Hi, {profile.name || 'Guest'}</span>
        <Avatar
          inHighlightedSection={inHighlightedSection}
          mobileMenu={mobile}
          name={name}
          src={profile.avatar}
        />
      </GreetingWrapper>
    </Link>
  );
}
