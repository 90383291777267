import { StoredPaymentMethod } from '@libraries/payments';
import { User } from 'firebase/auth';
import { DocumentData } from 'firebase/firestore';
import { UserProfileDao } from '../userProfileDao';

export interface UserData {
  // URL for profile picture.
  avatar?: string;
  // Preferred email address.
  email: string;
  // Bookmarked school IDs.
  faves: Set<string>;
  // First name.
  firstName?: string;
  // Last name.
  lastName?: string;
  // User's name or nickname.
  name?: string;
  // Customer ID. Used for payments.
  paymentsCustomerId?: string;
  // Payment methods.
  paymentMethods?: StoredPaymentMethod[];
}

export enum UserRole {
  Admin = 1,
}

export enum ProfileType {
  Student = 1,
  Instructor = 2,
  Coordinator = 3,
  General = 4,
}

export const PROFILE_TYPE_VALUES: ProfileType[] = Object.values(ProfileType)
  .map((profileTypeValue) => Number(profileTypeValue))
  .filter((profileTypeValue) => !isNaN(profileTypeValue));

export function getProfileTypeLabel(profileType: ProfileType): string {
  if (profileType === ProfileType.General) {
    return 'General (default)';
  }

  return ProfileType[profileType];
}

/**
 * Represents a user profile. To learn more about javascript classes, see:
 *
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes
 * - https://www.typescriptlang.org/docs/handbook/2/classes.html
 */
export class UserProfile {
  /** Creates a UserProfile instance with the given data. */
  static create(
    data: UserData,
    roles: UserRole[] = [],
    type: ProfileType = ProfileType.Student,
    userId: string = '',
    deprecatedPaymentData?: any,
  ): UserProfile {
    return new UserProfile(userId, type, roles, { ...data });
  }

  /** Creates a UserProfile instance from the given Firebase user. */
  static fromFirebaseUser(user: User): UserProfile {
    const names = user.displayName?.split(' ');

    return UserProfile.create(
      {
        avatar: user.photoURL,
        email: user.email,
        faves: new Set(),
        firstName: names ? names.slice(0, names.length - 1).join(' ') : '',
        lastName: names ? names[names.length - 1] : '',
        paymentMethods: [],
      },
      [],
      ProfileType.General,
      user.uid,
    );
  }

  /** Profile picture URL. */
  get avatar(): string {
    return this.data.avatar;
  }

  /** Name or nickname. */
  get name(): string {
    return this.data.firstName && this.data.lastName
      ? `${this.data.firstName} ${this.data.lastName}`
      : '';
  }

  /** Determines whether the user is an admin or not. */
  get isAdmin(): boolean {
    return this.roles.includes(UserRole.Admin);
  }

  /** Determines whether the user is a coordinator or not. */
  get isCoordinator(): boolean {
    return this.type === ProfileType.Coordinator;
  }

  /** Determines whether the user is an instructor or not. */
  get isInstructor(): boolean {
    return this.type === ProfileType.Instructor;
  }

  /** Creates a copy of this UserProfile instance with the added bookmark. */
  addFave(schoolId: string): UserProfile {
    return UserProfile.create(
      {
        ...this.data,
        faves: new Set([...Array.from(this.data.faves), schoolId].sort()),
      },
      this.roles,
      this.type,
      this.uid,
    );
  }

  /** Checks whether a school exists in the user's bookmarks. */
  hasFave(schoolId: string): boolean {
    return this.data.faves.has(schoolId);
  }

  /** Creates a copy of this UserProfile instance with the bookmark removed. */
  removeFave(schoolId: string): UserProfile {
    const newFaves = new Set(Array.from(this.data.faves));

    newFaves.delete(schoolId);

    return UserProfile.create(
      { ...this.data, faves: newFaves },
      this.roles,
      this.type,
      this.uid,
    );
  }

  /** Create a copy of this {@link UserProfile} instance. */
  copy(): UserProfile {
    return this.withUpdatedData({});
  }

  /** Creates a copy of this profile instance with the given ID. */
  withProfileId(profileId: string): UserProfile {
    return UserProfile.create(
      { ...this.data },
      this.roles,
      this.type,
      profileId,
    );
  }

  /** Creates a copy of this UserProfile instance with updated user data. */
  withUpdatedData(data: Partial<UserData>): UserProfile {
    return UserProfile.create(
      { ...this.data, ...data },
      this.roles,
      this.type,
      this.uid,
    );
  }

  /**
   * Creates a copy of this UserProfile instance with updated payment data.
   *
   * @deprecated
   */
  withPaymentData(data: Partial<any>): UserProfile {
    return UserProfile.create(
      { ...this.data },
      this.roles,
      this.type,
      this.uid,
    );
  }

  /** Creates a copy of this UserProfile instance with the given profile type. */
  withProfileType(type: ProfileType): UserProfile {
    return UserProfile.create({ ...this.data }, this.roles, type, this.uid);
  }

  /** Compares this UserProfile instance to another. */
  isEqual(other: UserProfile): boolean {
    return (
      other &&
      other.uid === this.uid &&
      JSON.stringify(other.roles) === JSON.stringify(this.roles) &&
      other.type === this.type &&
      other.data.avatar === this.data.avatar &&
      other.data.email === this.data.email &&
      Array.from(other.data.faves).join() ===
        Array.from(this.data.faves).join() &&
      other.data.firstName === this.data.firstName &&
      other.data.lastName === this.data.lastName &&
      other.data.name === this.data.name &&
      other.data.paymentsCustomerId === this.data.paymentsCustomerId &&
      JSON.stringify(other.data.paymentMethods) ===
        JSON.stringify(this.data.paymentMethods)
    );
  }

  /** Converts this UserProfile instance into a plain JavaScript object. */
  serialize(): DocumentData {
    return UserProfileDao.CONVERTER.toFirestore(this);
  }

  /**
   * Constructor parameters define the class properties. To learn more, see:
   * https://www.typescriptlang.org/docs/handbook/2/classes.html#parameter-properties
   */
  constructor(
    // Firebase user ID.
    readonly uid: string,
    // Profile type.
    readonly type: ProfileType,
    // List of ACL roles.
    readonly roles: UserRole[],
    // User details.
    readonly data: UserData,
  ) {}
}
