import { SocialHandles } from '../types';
import { Entity } from './entity';
import { ProfileType } from './userProfile';

export enum PersonnelProfileVisibility {
  Draft = 1,
  Published = 2,
}

/**
 * Represents the profile of a potential personnel member. This profile is
 * accessible via the private directory.
 */
export class PersonnelProfile extends Entity {
  /** @deprecated Only here for backwards compatibility */
  name = '';

  /** Creates a PersonnelProfile instance with the given data. */
  static create(
    city: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    photo: string,
    social: SocialHandles,
    state: string,
    type: ProfileType,
    userId: string,
    visibility: PersonnelProfileVisibility,
    zip: string,
  ): PersonnelProfile {
    return new PersonnelProfile(
      city,
      email,
      firstName,
      lastName,
      phone,
      photo,
      social,
      state,
      type,
      userId,
      visibility,
      zip,
    );
  }

  /** Creates an empty PersonnelProfile instance with the given uid. */
  static createEmpty(userId: string): PersonnelProfile {
    return PersonnelProfile.create(
      '',
      '',
      '',
      '',
      '',
      '',
      {},
      '',
      ProfileType.Instructor,
      userId,
      PersonnelProfileVisibility.Draft,
      '',
    );
  }

  get firstNameComputed(): string {
    if (this.firstName) {
      return this.firstName;
    }

    const names = this.name.split(' ');

    return names.slice(0, names.length - 1).join(' ');
  }

  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.name;
  }

  get isValid(): boolean {
    return !!this.email.length;
  }

  get lastNameComputed(): string {
    if (this.lastName) {
      return this.lastName;
    }

    const names = this.name.split(' ');

    return names[names.length - 1];
  }

  get publicBasePath(): string {
    return `/personnel/${this.userId}/public`;
  }

  /** Compares this PersonnelProfile instance to another. */
  isEqual(other: PersonnelProfile): boolean {
    return (
      other &&
      other.city === this.city &&
      other.email === this.email &&
      other.firstName === this.firstName &&
      other.lastName === this.lastName &&
      other.phone === this.phone &&
      other.photo === this.photo &&
      JSON.stringify(other.social) === JSON.stringify(this.social) &&
      other.state === this.state &&
      other.type === this.type &&
      other.userId === this.userId &&
      other.visibility === this.visibility &&
      other.zip === this.zip
    );
  }

  /** Creates a copy of this PersonnelProfile instance with the given data. */
  withData(
    city: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    photo: string,
    social: SocialHandles,
    state: string,
    type: ProfileType,
    visibility: PersonnelProfileVisibility,
    zip: string,
  ): PersonnelProfile {
    return PersonnelProfile.create(
      city,
      email,
      firstName,
      lastName,
      phone,
      photo,
      social,
      state,
      type,
      this.userId,
      visibility,
      zip,
    );
  }

  /** Creates a copy of this PersonnelProfile instance with a new photo. */
  withPhoto(photo: string): PersonnelProfile {
    return PersonnelProfile.create(
      this.city,
      this.email,
      this.firstName,
      this.lastName,
      this.phone,
      photo,
      this.social,
      this.state,
      this.type,
      this.userId,
      this.visibility,
      this.zip,
    );
  }

  withName(first: string, last: string): PersonnelProfile {
    return PersonnelProfile.create(
      this.city,
      this.email,
      first,
      last,
      this.phone,
      this.photo,
      this.social,
      this.state,
      this.type,
      this.userId,
      this.visibility,
      this.zip,
    );
  }

  withType(type: ProfileType): PersonnelProfile {
    return PersonnelProfile.create(
      this.city,
      this.email,
      this.firstName,
      this.lastName,
      this.phone,
      this.photo,
      this.social,
      this.state,
      type,
      this.userId,
      this.visibility,
      this.zip,
    );
  }

  protected constructor(
    // City.
    readonly city: string,
    // Contact email.
    readonly email: string,
    // First name.
    readonly firstName: string,
    // Last name.
    readonly lastName: string,
    // Contact phone.
    readonly phone: string,
    // Photo URL.
    readonly photo: string,
    // Social handles.
    readonly social: SocialHandles,
    // US state.
    readonly state: string,
    // Profile type.
    readonly type: ProfileType,
    // Firebase user ID.
    readonly userId: string,
    // Visibility.
    readonly visibility: PersonnelProfileVisibility,
    // Zip code.
    readonly zip: string,
  ) {
    super('pp');
  }
}
