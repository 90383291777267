import { ProductDetails } from '../../entities/productDetails';
import { getProductDetailsDocRef } from './utils/getProductDetailsDocRef';
import { getProductDetailsFromRef } from './utils/getProductDetailsFromRef';

/**
 * Retrieves all product details from Firestore. Product details are configured
 * by administrators.
 */
export async function getProductDetails(): Promise<ProductDetails> {
  return getProductDetailsFromRef(getProductDetailsDocRef());
}
