import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface PillProps {
  autoMargin?: boolean;
  cursor?: string;
  cyan?: boolean;
  inHighlightedSection?: boolean;
  yellow?: boolean;
}

type ThemedPillProps = ThemeProps<DefaultTheme>;

function getBgColor(props: ThemedPillProps) {
  if (props.cyan) {
    return props.theme.cyan.mix(props.theme.white, 0.6).string();
  }

  if (props.yellow) {
    return props.theme.yellow.string();
  }

  return props.theme.white.string();
}

function getBoxShadowColor(props: ThemedPillProps) {
  return props.inHighlightedSection
    ? props.theme.textColor.fade(0.5).string()
    : props.theme.grey.fade(0.5).string();
}

function getColor(props: ThemedPillProps) {
  if (props.cyan || props.yellow) {
    return props.theme.textColor.string();
  }

  // TODO
  return props.theme.textColor.string();
}

export const Pill = styled.span<PillProps>`
  background-color: ${getBgColor};
  border-radius: 15px;
  box-shadow: 0 0 10px ${getBoxShadowColor};
  box-sizing: border-box;
  color: ${getColor};
  cursor: ${(props) => props.cursor ?? 'default'};
  display: inline-block;
  font-size: 0.8rem;
  height: 30px;
  line-height: 30px;
  margin: ${(props) => (props.autoMargin ? 'auto' : '0.4rem')} 0.4rem;
  padding: 0 1rem;
  text-transform: uppercase;
  vertical-align: middle;
`;
