import { useState } from 'react';

export function useFileInput() {
  const [blobUrls, setBlobUrls] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [files, setFiles] = useState<FileList>(null);

  function removeFiles() {
    setFiles(null);
    updateBlobUrls([]);
  }

  function updateBlobUrls(blobUrls: string[]) {
    setBlobUrls((currBlobUrls) => {
      currBlobUrls.forEach((blobUrl) => URL.revokeObjectURL(blobUrl));

      return blobUrls;
    });
  }

  function updateError(error: string) {
    setError(error);
  }

  function updateFiles(files: FileList) {
    const blobUrls: string[] = [];

    for (let i = 0; i < files.length; i++) {
      blobUrls.push(URL.createObjectURL(files.item(i)));
    }

    setFiles(files);
    updateBlobUrls(blobUrls);
  }

  return {
    blobUrls,
    error,
    files,
    removeFiles,
    updateError,
    updateFiles,
  };
}
