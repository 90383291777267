import { AspectRatioValue } from './types';

const DEFAULT_ASPECT_RATIO = '75%';

/**
 * Converts a ratio to a height percentage.
 *
 * @param ratio: A ratio expressed as "x:y" (e.g. "4:3") or a percentage
 *               (e.g. 75).
 */
export function toPercentage(ratio: AspectRatioValue): string {
  if (typeof ratio === 'number') {
    return `${ratio}%`;
  }

  if (!ratio) {
    return DEFAULT_ASPECT_RATIO;
  }

  try {
    const colon = ratio.indexOf(':');

    if (colon > -1) {
      const x = parseInt(ratio.substring(0, colon), 10);
      const y = parseInt(ratio.substring(colon + 1), 10);

      return `${(y * 100) / x}%`;
    }

    return `${parseInt(ratio, 10)}%`;
  } catch {
    return DEFAULT_ASPECT_RATIO;
  }
}
