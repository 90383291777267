import styled from 'styled-components';
import { getColor } from './getColor';
import { HeadingProps } from './types';

export const SecondHeading = styled.h2<HeadingProps>`
  color: ${getColor};
  font-size: 26px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 800px) {
    font-size: 30px;
    text-align: ${(props) => props.textAlign || 'left'};
  }
`;
