import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { InputProps, TextAreaProps } from './types';

interface TextAreaInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: string) => void;
}

export const TextArea = styled(Input).attrs({
  as: 'textarea',
})<TextAreaProps>`
  height: auto;
  resize: vertical;
`;

export function TextAreaInput(props: TextAreaInputProps) {
  const { onChange, ...textAreaProps } = props;

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    onChange(event.target.value);
  }

  return <TextArea {...textAreaProps} onChange={handleChange} />;
}
