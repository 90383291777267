import { DeployedEnvironment, getDeployedEnvironment } from '@utils';
import { useEffect, useState } from 'react';
import { FEATURES } from '../constants';
import { Feature, FeatureFlag } from '../types';

export function useFeatures(feature: Feature) {
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const env = getDeployedEnvironment();
    const flag = FEATURES[feature];

    if (flag === FeatureFlag.Enabled) {
      setEnabled(true);
    } else if (
      flag === FeatureFlag.Preview &&
      env !== DeployedEnvironment.Production
    ) {
      setEnabled(true);
    } else if (
      flag === FeatureFlag.Dev &&
      env === DeployedEnvironment.Development
    ) {
      setEnabled(true);
    }

    setLoading(false);
  }, [feature]);

  return { enabled, loading };
}
