import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { logError } from '../../../shared/reporting';

export async function reauthenticateUser(
  password: string,
  onAuthenticated: () => void,
  onAuthenticationError: (error: string) => void,
) {
  try {
    const user = getAuth().currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    await reauthenticateWithCredential(user, credential);

    onAuthenticated();
  } catch (error) {
    logError('Could not re-authenticate:', error);

    onAuthenticationError(error);
  }
}
