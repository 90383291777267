import { logError } from '@reporting';
import { FirebaseError } from 'firebase/app';

export function handleFirebaseError(
  message: string,
  cause: FirebaseError,
): void {
  // Error with cause isn't supported in all browsers, so we add the original
  // error to the message of this new one.
  logError(
    new Error(
      `${message}. Cause by Firebase error "${cause.name}": ${cause.message} (code: ${cause.code}).`,
    ),
  );

  // TODO: Handle client offline errors (code: unavailable)
}
