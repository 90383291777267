import { FaChevronUp } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const DropdownWrapper = styled.div`
  animation: ${fadeIn} 200ms ease-in-out;
  display: none;
  position: absolute;
  text-align: center;
  top: 80%;
  width: 100%;
  z-index: 10;
`;

const DropdownArrow = styled.div`
  color: ${(props) =>
    props.theme.textColor.mix(props.theme.backgroundColor, 0.85).string()};
  margin: auto;
  width: 12px;
`;

const DropdownBody = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.string()};
  border: 1px solid ${(props) => props.theme.grey.string()};
  border-radius: ${(props) => props.theme.inputBorderRadius};
  box-shadow: 0 0 30px
    ${(props) => props.theme.grey.mix(props.theme.white, 0.7).string()};
`;

export const DropdownMenuItem = styled.div`
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 4px;
  transition: background-color 300ms;
  width: 100%;

  &:hover {
    background-color: ${(props) =>
      props.theme.textColor.mix(props.theme.backgroundColor, 0.95).string()};
  }
`;

export const DROPDOWN_CLASSNAME = 'shared-dropdown-component';

export function Dropdown({ children }) {
  return (
    <DropdownWrapper className={DROPDOWN_CLASSNAME}>
      <DropdownArrow>
        <FaChevronUp />
      </DropdownArrow>
      <DropdownBody>{children}</DropdownBody>
    </DropdownWrapper>
  );
}
