export function formatCreditCard(input: string): string {
  return (input || '')
    .replaceAll(/\s+/g, '')
    .substring(0, 16)
    .replaceAll(/([0-9]{4})/g, '$1 ')
    .trim();
}

export function formatCreditCardExpiryMonthYear(input: string): string {
  const expiry = input?.replaceAll(/[^0-9]/g, '');

  if (expiry.length < 3) {
    return expiry;
  }

  return expiry.substring(0, 4).replace(/([0-9]+)([0-9]{2})/, '$1/$2');
}

export function formatPhone(
  phoneText?: string,
  defaultValue?: string | null,
): string | null {
  const phone = phoneText?.replaceAll(/[^0-9]/g, '');

  if (phone?.length === 10) {
    // Format: (123) 123-4567
    return phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, '($1) $2-$3');
  }

  if (phone?.length === 7) {
    // Format: 123-4567
    return phone.replace(/([0-9]{3})([0-9]{4})/, '$1-$2');
  }

  return defaultValue === undefined ? phone : defaultValue;
}

export function formatStateCode(code?: string): string {
  return (code || '')
    .toUpperCase()
    .replaceAll(/[^A-Z]/g, '')
    .substring(0, 2);
}

/**
 * Formats a tax percentage (decimal) into human-friendly text.
 */
export function formatTax(percentage: number): string {
  if (percentage < 0) {
    return '0%';
  }

  if (percentage > 1) {
    return '100%';
  }

  return `${percentage * 100}%`;
}

export function formatUrl(input: string, host: string = ''): string {
  if (!input) {
    return '';
  }

  let url = input.trim().replace(/^https?\:\/\/(www\.)?/, '');

  if (!url.startsWith(host)) {
    url = `${host}/${url}`;
  }

  try {
    return new URL(`https://${url}`).toString();
  } catch {
    return '';
  }
}

/** Returns the URL from a video embed string. */
export function formatVideoEmbed(input: string): string {
  if (!input) {
    return '';
  }

  const cleanInput = input.trim();

  const regex = /src="https:\/\/www\.youtube\.com\/embed\/[-\w]+"/g;
  const src = cleanInput.match(regex);

  if (!src || src.length !== 1) {
    // Input does not have exactly one src attribute with youtube embed URL.
    return '';
  }

  const embedUrl = src[0].substring(5, src[0].length - 1);

  return embedUrl;
}

export function formatZip(input: string): string {
  return (input || '').replaceAll(/[^0-9]/g, '').substring(0, 5);
}
