import { MutableRefObject } from 'react';

/**
 * Scrolls view to the specified DOM element.
 */
export function scrollTo(ref: MutableRefObject<HTMLElement>): void {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
}
