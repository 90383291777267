import { PersonnelProfile } from '@dao';
import { EmptyState, Loading } from '@shared';
import { PersonnelProfileCard } from './PersonnelProfileCard';

export interface ResultsProps {
  isLoading: boolean;
  personnel: PersonnelProfile[];
}

export function Results({ isLoading, personnel }: ResultsProps) {
  if (isLoading && personnel.length === 0) {
    return <Loading />;
  }

  if (!personnel.length) {
    return (
      <EmptyState msg="We couldn't find anything matching that query."></EmptyState>
    );
  }

  return (
    <>
      {personnel.map((personnelProfile) => (
        <PersonnelProfileCard
          key={personnelProfile.userId}
          personnelProfile={personnelProfile}
        />
      ))}
    </>
  );
}
