import Color from 'color';
import { COLOR_CYAN } from '../shared/theme';

const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
const MAPS_URL_ROOT = 'https://maps.googleapis.com/maps/api/staticmap';

interface StaticMapUrlProps {
  height?: number;
  latitude: number;
  longitude: number;
  markerColor?: Color;
  width?: number;
}

/** Generates the URL for a static map image. */
export function getStaticMapUrl({
  height,
  latitude,
  longitude,
  markerColor,
  width,
}: StaticMapUrlProps): string {
  const color = markerColor || COLOR_CYAN;
  const imgWidth = width ?? 800;
  const imgHeight = height ?? 400;

  // https://developers.google.com/maps/documentation/maps-static/start#Markers
  const markers =
    'markers=' +
    encodeURI(
      [
        `color:${color.hex().replace('#', '0x')}`,
        `${latitude},${longitude}`,
      ].join('|'),
    );

  // https://developers.google.com/maps/documentation/maps-static/start#Imagesizes
  const size = `size=${imgWidth}x${imgHeight}`;

  const params = [markers, size].join('&');

  return `${MAPS_URL_ROOT}?${params}&key=${GOOGLE_API_KEY}`;
}
