import styled from 'styled-components';
import { getPrivacyRoute, getTermsRoute } from '../../routes';
import { Link } from '../Link';
import { MenuItem, MenuLinks } from './MenuLinks';

const FooterWrapper = styled.footer`
  border-top: 1px solid ${(props) => props.theme.grey.fade(0.9).string()};
  margin-bottom: 4rem;
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  max-width: 1200px;

  @media (min-width: 800px) {
    justify-content: flex-end;
    padding: 0 2rem;
  }
`;

const Copyright = styled.div`
  font-size: 0.8rem;
  text-align: center;

  a {
    color: ${(props) => props.theme.textColor.string()};
    margin: auto;
    text-align: center;
  }
`;

export function Footer() {
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <LinksWrapper>
        <MenuLinks>
          <MenuItem>
            <Link href={getPrivacyRoute()}>Privacy Policy</Link>
          </MenuItem>
          <MenuItem>
            <Link href={getTermsRoute()}>Terms &amp; Conditions</Link>
          </MenuItem>
        </MenuLinks>
      </LinksWrapper>
      <Copyright>
        &copy; {year}{' '}
        <a
          href="https://thesecretcocktail.com"
          target="_blank"
          rel="noreferrer">
          The Secret Cocktail.
        </a>{' '}
        All Rights Reserved.
      </Copyright>
    </FooterWrapper>
  );
}
