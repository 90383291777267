import { getGeocoder } from '@libraries/google';
import { logDebug } from '@reporting';
import { Address, convertGeocoderResultToAddress } from '@utils';
import { useEffect, useState } from 'react';
import { useUserCoordinates } from './useUserCoordinates';

/**
 * Determines the U.S. state the user is currently in, based on their
 * coordinates.
 */
export function useUserLocation(): Address {
  const coordinates = useUserCoordinates();
  const [location, setLocation] = useState<Address>(null);
  const geocoder = getGeocoder();

  useEffect(() => {
    if (!coordinates || !geocoder) {
      return;
    }

    const request: google.maps.GeocoderRequest = {
      location: {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
      region: 'US',
    };

    logDebug('[useUserLocation]', '[request]', request);

    // Reverse geocode from coordinates.
    // https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
    geocoder.geocode(request, (results) => {
      logDebug('[useUserLocation]', '[response]', results);

      const filtered = results.filter(filterResults);

      if (filtered.length) {
        setLocation(convertGeocoderResultToAddress(filtered[0]));
      }
    });
  }, [coordinates, geocoder]);

  return location;
}

/** Filters out geocoder results that are not from the U.S. */
function filterResults({ address_components }: google.maps.GeocoderResult) {
  return !!address_components.find((c) => c.short_name === 'US');
}
