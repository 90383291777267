import { createContext } from 'react';
import { FirebaseAuthProps } from './types';

/**
 * Context for Firebase auth user.
 *
 * https://reactjs.org/docs/context.html
 */
export const FirebaseAuthUserContext = createContext<FirebaseAuthProps>({
  isLoading: true,
  user: null,
});
