import styled from 'styled-components';

export const DataList = styled.div<{ visible?: boolean }>`
  background-color: ${(props) => props.theme.white.string()};
  border-radius: 4px;
  box-shadow: 0 0 20px ${(props) => props.theme.lightGrey.fade(0.7).string()};
  display: ${(props) => (props.visible ? 'block' : 'none')};
  max-height: 190px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const DataListOptionWrapper = styled.div`
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  transition: background-color 300ms;
  z-index: 11;

  &:focus,
  &:hover {
    background-color: ${(props) =>
      props.theme.cyan.mix(props.theme.white, 0.8).string()};
  }
`;

export interface DataListOptionProps<T> {
  label?: string;
  onClick: (option: T) => void;
  value: T;
}

export function DataListOption<T>({
  label,
  onClick,
  value,
}: DataListOptionProps<T>) {
  function handleClick(event: Event) {
    event.preventDefault();
    onClick(value);
  }

  return (
    <DataListOptionWrapper onClick={handleClick}>
      {label || value}
    </DataListOptionWrapper>
  );
}
