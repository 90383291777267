import { Entity } from '@dao';
import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

/**
 * The FirestorePage uses resource IDs to allow paging using document snapshots.
 *
 * https://firebase.google.com/docs/firestore/query-data/query-cursors#use_a_document_snapshot_to_define_the_query_cursor
 */
export class FirestorePage {
  static FIRST = FirestorePage.create();

  static create(entityCode: string = '', entityId: string = ''): FirestorePage {
    return new FirestorePage(entityCode, entityId);
  }

  static decode(encoded: string) {
    if (!encoded || encoded.indexOf('-') < 0) {
      return FirestorePage.FIRST;
    }

    const [entityCode, entityId] = encoded
      .replace(/[^a-zA-Z0-9-]/, '')
      .split('-', 2);

    return FirestorePage.create(entityCode, entityId);
  }

  static fromDocument<Cursor extends Entity>(doc: DocumentSnapshot<Cursor>) {
    const entity = doc.data();

    return new FirestorePage(entity.entityCode, doc.id);
  }

  static fromQueryResults<Cursor extends Entity>(
    results: QuerySnapshot<Cursor>,
  ) {
    // TODO
    return FirestorePage.FIRST;
  }

  get isFirstPage() {
    return !this.isValid;
  }

  get isValid() {
    return this.entityCode.length > 0 && this.entityId.length > 0;
  }

  get hash() {
    return this.toString();
  }

  isEqual(other: FirestorePage) {
    return this.hash === other.hash;
  }

  toString(): string {
    return `${this.entityCode}-${this.entityId}`;
  }

  protected constructor(
    readonly entityCode: string,
    readonly entityId: string,
  ) {}
}
