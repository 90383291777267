import { User } from 'firebase/auth';

export function isEmailVerified(user?: User) {
  if (!user || user.providerData.length < 1) {
    return false;
  }

  if (user.providerData[0].providerId !== 'password') {
    return true;
  }

  if (user.email && SKIP_EMAIL_VERIFICATION.includes(user.email)) {
    return true;
  }

  return user.emailVerified;
}

const SKIP_EMAIL_VERIFICATION = ['ama@example.com'];
