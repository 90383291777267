import { getProtocolAndHost } from '@utils';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { logError } from '../../../shared/reporting';
import { handleError } from './handleError';

export async function sendSignInLink(
  email: string,
  redirectPath: string,
  onError: (emailError: string) => void,
  onEmailSent: () => void,
) {
  try {
    await sendSignInLinkToEmail(getAuth(), email, {
      handleCodeInApp: true,
      url: `${getProtocolAndHost()}${redirectPath}`,
    });

    onEmailSent();
  } catch (error) {
    const [errorField, errorMsg] = handleError(error);

    if (errorField !== 'email') {
      logError(error);
    } else {
      onError(errorMsg);
    }
  }
}
