import { Notice } from './Notice';

interface EmailVerifiedMessageProps {
  verified?: boolean;
}

export function EmailVerifiedMessage({ verified }: EmailVerifiedMessageProps) {
  if (!verified) {
    return null;
  }

  return (
    <Notice>
      Thank you for verifying your email address. Feel free to log into your new
      account anytime.
    </Notice>
  );
}
