import { DocumentReference } from 'firebase/firestore';
import { ProductDetails } from '../../../entities/productDetails';
import { getAdminDocRef } from '../../utils/getAdminDocRef';
import { ADMIN_PRODUCT_DETAILS_ID } from '../constants';
import { PRODUCT_DETAILS_CONVERTER } from '../converter';

export function getProductDetailsDocRef(): DocumentReference<ProductDetails> {
  return getAdminDocRef<ProductDetails>(ADMIN_PRODUCT_DETAILS_ID).withConverter(
    PRODUCT_DETAILS_CONVERTER,
  );
}
