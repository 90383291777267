import {
  SchoolAttributeKey,
  SCHOOL_ATTRIBUTE_KEY_MAP,
} from './keyAttributeMap';
import { SchoolAttribute } from './list';

/**
 * Converts a school attribute constant to its related key.
 */
export function getSchoolAttributeKey(
  attribute: SchoolAttribute,
): SchoolAttributeKey {
  return SCHOOL_ATTRIBUTE_KEY_MAP[attribute];
}
