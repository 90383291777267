import styled from 'styled-components';
import { toPercentage } from './toPercentage';
import { AspectRatioProps } from './types';

/**
 * A div that maintains a certain aspect ratio.
 *
 * For example, pass the prop "ratio" with a value of "4:3" to create an element
 * that maintains that aspect ratio:
 *
 *  <AspectRatio ratio="4:3" />
 */
export const AspectRatio = styled.div<AspectRatioProps>`
  background: transparent center center no-repeat;
  background-size: cover;
  padding-bottom: ${(props) => toPercentage(props.ratio)};
  position: relative;
  width: 100%;
`;
