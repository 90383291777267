import { UserProfileDao } from '@dao';
import { logError } from '@reporting';
import { useEffect, useState } from 'react';

/** Retrieves the name of the user profile linked to the personnel profile. */
export function usePersonnelProfileName(userId: string) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');

  useEffect(() => {
    if (userId) {
      UserProfileDao.getUserProfile(userId)
        .then((userProfile) => setName(userProfile.name))
        .catch(logError)
        .finally(() => setLoading(false));
    }
  }, [userId]);

  return { loading, name };
}
