import { FirestorePage } from '@libraries/firebase';
import { QuerySnapshot } from 'firebase/firestore';
import { Entity } from './entities/entity';

/** Creates a generic response to listing a resource. */
export class ListResourceResponse<Resource extends Entity> {
  static fromFirestoreResults<Resource extends Entity>(
    results: QuerySnapshot<Resource>,
    queryLimit: number,
  ): ListResourceResponse<Resource> {
    const nextPage =
      queryLimit > 0 && results.size === queryLimit
        ? FirestorePage.fromDocument(results.docs[results.size - 1])
        : null;

    return new ListResourceResponse(
      nextPage,
      results.docs.map((record) => record.data()),
    );
  }

  protected constructor(
    readonly nextPage: FirestorePage,
    readonly results: Resource[],
  ) {}
}
