/**
 * Table UI components. See src/shared/components/Table.stories.tsx for examples
 * on how to use these.
 */
import styled from 'styled-components';

const BG_COLOR = '#eee';
const BORDER_RADIUS = '4px';

export const Table = styled.div`
  display: table;
  text-align: center;
  width: 100%;
`;

export const TableCell = styled.div`
  display: table-cell;
  padding: 8px 12px;
`;

export const TableRow = styled.div`
  display: table-row;
`;

export const TableHead = styled.div`
  display: table-header-group;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin-bottom: 4px;
`;

export const TableBody = styled.div`
  display: table-row-group;

  ${TableRow} {
    background-color: transparent;
    transition: background-color 300ms;

    &:hover {
      background-color: #f7f7f7;
    }

    ${TableCell} {
      border-bottom: 1px solid ${BG_COLOR};

      &:first-child {
        border-left: 1px solid ${BG_COLOR};
      }

      &:last-child {
        border-right: 1px solid ${BG_COLOR};
      }
    }

    &:first-child {
      ${TableCell} {
        border-top: 1px solid ${BG_COLOR};

        &:first-child {
          border-top-left-radius: ${BORDER_RADIUS};
        }

        &:last-child {
          border-top-right-radius: ${BORDER_RADIUS};
        }
      }
    }

    &:last-child {
      ${TableCell} {
        &:first-child {
          border-bottom-left-radius: ${BORDER_RADIUS};
        }

        &:last-child {
          border-bottom-right-radius: ${BORDER_RADIUS};
        }
      }
    }
  }
`;

export const TableFooter = styled.div`
  display: table-footer-group;
`;

interface TableColSpanRowProps {
  backgroundColor?: string;
  children: React.ReactNode;
  numCols: number;
  leftPosition?: string;
  padding?: string;
}

const TableColSpanRowWrapper = styled.div<TableColSpanRowProps>`
  display: table-row;

  ${TableCell} {
    padding: ${(props) => props.padding || 'inherit'};
    visibility: hidden;

    &:first-child {
      background-color: ${(props) => props.backgroundColor || 'transparent'};
      left: ${(props) => props.leftPosition || '0'};
      position: absolute;
      visibility: visible;
      width: 100%;
    }
  }
`;

export function TableColSpanRow(props: TableColSpanRowProps) {
  const children = [];
  const total = Math.max(props.numCols || 0, 1);

  // Duplicate children in each cell to get the right height.
  for (let i = 0; i < total; i++) {
    children.push(<TableCell key={i}>{props.children}</TableCell>);
  }

  return <TableColSpanRowWrapper>{children}</TableColSpanRowWrapper>;
}
