import styled from 'styled-components';
import { Heading } from '../heading/Heading';

export const Name = styled(Heading)`
  margin: 0 0 1rem;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }
`;
