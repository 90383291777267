import { logError } from '@reporting';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDocs,
  getFirestore,
  query,
  setDoc,
} from 'firebase/firestore';
import { SchoolClaim, SchoolClaimStatus } from './entities/schoolClaim';
import { SchoolClaimFilter } from './schoolClaim/filter';
import { SchoolDao } from './schoolDao';

/**  Handles data access for {@link SchoolClaim} entities. */
export class SchoolClaimDao {
  static readonly COLLECTION_NAME = 'schoolClaims';

  /** Firestore data converter for {@link SchoolClaim} entities. */
  static readonly CONVERTER: FirestoreDataConverter<SchoolClaim> = {
    fromFirestore: function (document, options?) {
      const {
        schoolId,
        profileId,
        status,
        claimTimestamp,
        resolutionTimestamp,
        resolverId,
      } = document.data() as SchoolClaim;

      return SchoolClaim.create(
        schoolId,
        profileId,
        status || SchoolClaimStatus.Pending,
        new Date(claimTimestamp),
        resolutionTimestamp ? new Date(resolutionTimestamp) : null,
        resolverId,
        document.id,
      );
    },
    toFirestore: function (schoolClaim: SchoolClaim, options?) {
      return {
        schoolId: schoolClaim.schoolId,
        profileId: schoolClaim.profileId,
        status: schoolClaim.status,
        claimTimestamp: schoolClaim.claimTimestamp.valueOf(),
        resolutionTimestamp: schoolClaim.resolutionTimestamp
          ? schoolClaim.resolutionTimestamp.valueOf()
          : 0,
        resolverId: schoolClaim.resolverId,
      };
    },
  };

  /** Creates a {@link SchoolClaim} document. */
  static async createSchoolClaim(claim: SchoolClaim): Promise<SchoolClaim> {
    const docRef = await addDoc(this.getCollectionRef(), claim);

    return claim.withId(docRef.id);
  }

  /**
   * Lists {@link SchoolClaim} documents using the provided
   * {@link SchoolClaimFilter}.
   */
  static async listSchoolClaims(
    filter: SchoolClaimFilter,
  ): Promise<SchoolClaim[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), ...filter.getQueryConstraints()),
    );

    return results.docs.map((record) => record.data());
  }

  /** Updates a {@link SchoolClaim} document. */
  static async updateSchoolClaim(
    claim: SchoolClaim,
    updateSchoolEditors: boolean = false,
  ): Promise<SchoolClaim> {
    await setDoc(this.getDocumentRef(claim.id), claim);

    // Update school editors.
    if (updateSchoolEditors && claim.status === SchoolClaimStatus.Approved) {
      const school = await SchoolDao.getSchool(claim.schoolId);

      if (!school) {
        logError(
          `Could not add new school owner "${claim.profileId}" to school "${claim.schoolId}": School not found.`,
        );

        return claim;
      }

      await SchoolDao.updateSchool(school.withEditor(claim.profileId));
    }

    return claim;
  }

  /** Returns a Firestore collection reference for the school claims. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<SchoolClaim> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given school claim. */
  private static getDocumentRef(
    claimId: string,
  ): DocumentReference<SchoolClaim> {
    return doc(getFirestore(), this.COLLECTION_NAME, claimId).withConverter(
      this.CONVERTER,
    );
  }
}
