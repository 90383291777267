import { costToString } from '@utils';
import {
  PaidProduct,
  PREMIUM_ACCESS_PRODUCT_DEFAULT,
  PROMOTED_PAGE_PRODUCT_DEFAULT,
} from '../admin/products/constants';
import { validateProductDetails } from '../admin/products/utils/validateProductDetails';
import { Entity } from './entity';

/**
 * Defines the details for all paid products. Only one record of this type
 * exists in Firestore. This unique record is stored in the "admin" collection.
 */
export class ProductDetails extends Entity {
  /** ProductDetails instance with the default values. */
  static DEFAULT = ProductDetails.create(
    PREMIUM_ACCESS_PRODUCT_DEFAULT,
    PROMOTED_PAGE_PRODUCT_DEFAULT,
  );

  /** Creates a ProductDetails instance with the given data. */
  static create(premiumAccess: PaidProduct, promotedSchoolPage: PaidProduct) {
    return new ProductDetails(premiumAccess, promotedSchoolPage);
  }

  /** The formatted monthly cost of Premium Access. */
  get premiumAccessMonthlyCost() {
    return costToString(this.premiumAccess.monthlyCost);
  }

  /** The formatted yearly cost of Premium Access. */
  get premiumAccessYearlyCost() {
    return costToString(this.premiumAccess.yearlyCost);
  }

  /** The formatted monthly cost of a Promoted School Page. */
  get promotedSchoolPageMonthlyCost() {
    return costToString(this.promotedSchoolPage.monthlyCost);
  }

  /** The formatted yearly cost of a Promoted School Page. */
  get promotedSchoolPageYearlyCost() {
    return costToString(this.promotedSchoolPage.yearlyCost);
  }

  /** Compares this ProductDetails instance to another. */
  isEqual(other: ProductDetails) {
    return (
      other &&
      JSON.stringify(this.premiumAccess) ===
        JSON.stringify(other.premiumAccess) &&
      JSON.stringify(this.promotedSchoolPage) ===
        JSON.stringify(other.promotedSchoolPage)
    );
  }

  /**
   * Creates a copy of this ProductDetails instance with the given Premium
   * Access product details.
   */
  withPremiumAccessDetails(product: PaidProduct) {
    return ProductDetails.create(
      { ...product },
      { ...this.promotedSchoolPage },
    );
  }

  /**
   * Creates a copy of this ProductDetails instance with the given Promoted
   * School Page product details.
   */
  withPromotedSchoolPageDetails(product: PaidProduct) {
    return ProductDetails.create({ ...this.premiumAccess }, { ...product });
  }

  protected constructor(
    // Access to the paid directory.
    readonly premiumAccess: PaidProduct,
    // Promote one school in the school directory.
    readonly promotedSchoolPage: PaidProduct,
  ) {
    super('apd');

    validateProductDetails(premiumAccess);
    validateProductDetails(promotedSchoolPage);
  }
}
