import styled from 'styled-components';
import { Notice } from './Notice';

const Emphasize = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export function SecurePaymentNotice() {
  return (
    <Notice warning>
      Your payment details are secured by{' '}
      <a href="https://authorize.net" target="_blank" rel="noreferrer">
        Authorize.net
      </a>
      , a Visa company.&nbsp;
      <Emphasize>We never store your payment details.</Emphasize>
    </Notice>
  );
}
