export enum Feature {
  PaidProduct = 'PAID PRODUCT',
  PaidProductUi = 'PAID PRODUCT UI',
  PersonnelDirectory = 'PERSONNEL DIRECTORY',
}

export enum FeatureFlag {
  Dev = 'dev',
  Enabled = 'enabled',
  Preview = 'preview',
}
