import { Button } from '../../Button';

interface LoadMoreProps {
  hasNextPage: boolean;
  isLoading: boolean;
  onNextPage: () => void;
}

export function LoadMore({
  hasNextPage,
  isLoading,
  onNextPage,
}: LoadMoreProps) {
  if (!hasNextPage || isLoading) {
    return null;
  }

  return (
    <Button onClick={onNextPage} secondary>
      Load More
    </Button>
  );
}
