import { getGoogleClient } from './client';

let geocoder: google.maps.Geocoder | null = null;

/**
 * https://developers.google.com/maps/documentation/javascript/geocoding
 */
export function getGeocoder(): google.maps.Geocoder | null {
  if (geocoder) {
    return geocoder;
  }

  const client = getGoogleClient();

  if (!client) {
    return null;
  }

  geocoder = new client.maps.Geocoder();

  return geocoder;
}
