import { useEffect, useState } from 'react';
import { logError } from '../../shared/reporting';
import { getHash } from '../../utils/getHash';
import { FirebaseFile } from '../entities/firebaseFile';

/** Uploads the cloud file. */
export function useUploadCloudFile(
  basePath: string,
  file: File,
  onUpload: (filename: string) => void,
) {
  const [uploading, setUploading] = useState(false);

  function uploadFile() {
    setUploading(true);
  }

  useEffect(() => {
    if (!uploading) {
      return;
    }

    if (!file) {
      return;
    }

    const reader = new FileReader();

    // Get the file base 64 string.
    reader.readAsDataURL(file);
    reader.onloadend = (event) => {
      // Hash the file's data string and use as the unique filename.
      const filename = getHash(event.target.result);
      const fullPath = `${basePath}/${filename}`;

      const firebaseFile = FirebaseFile.create(fullPath);

      firebaseFile
        .upload(file)
        .then(() => onUpload(filename))
        .catch((err) => {
          const uploadError = new Error('Could not upload cloud file', {
            cause: err,
          });

          logError(uploadError);
        })
        .finally(() => setUploading(false));
    };

    // The effect does not depend on onUpload.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  return { uploadFile, uploading };
}
