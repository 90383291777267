import styled from 'styled-components';
import { getBgColor } from './getBgColor';
import { getColor } from './getColor';
import { getCursor } from './getCursor';
import { InputProps } from './types';

interface TextInputProps extends Omit<InputProps, 'onChange'> {
  formatter?: (...args: any[]) => string;
  onChange: (value: string) => void;
}

export const Input = styled.input<InputProps>`
  background-color: ${(props) => getBgColor(props).string()};
  border: none;
  border-radius: ${(props) => props.theme.inputBorderRadius};
  box-sizing: border-box;
  color: ${(props) => getColor(props).string()};
  cursor: ${(props) => getCursor(props)};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.inputFontSize};
  height: ${(props) => props.theme.inputHeight};
  line-height: ${(props) => props.theme.inputHeight};
  margin: 0.4rem 1rem;
  max-width: ${(props) => props.maxWidth || '100%'};
  padding: 0.4rem 1rem;
  transition:
    background-color 300ms,
    box-shadow 300ms;
  width: 100%;

  &:focus {
    background-color: ${(props) => getBgColor(props).opaquer(0.5).string()};
    box-shadow: 0 0 15px ${(props) => props.theme.textColor.fade(0.95).string()};
    outline: none;
  }

  // Disable input outline in Firefox
  &::-moz-focus-inner {
    border: 0;
  }

  &::placeholder {
    color: ${(props) => getColor(props, true).string()};
    opacity: 1;
  }
`;

export function TextInput(props: TextInputProps) {
  const { formatter, onChange, ...inputProps } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(formatter ? formatter(event.target.value) : event.target.value);
  }

  return <Input {...inputProps} onChange={handleChange} />;
}
