import styled from 'styled-components';
import { getColor } from './getColor';
import { HeadingProps } from './types';

export const Heading = styled.h1<HeadingProps>`
  color: ${getColor};
  font-size: 30px;

  @media screen and (min-width: 800px) {
    font-size: 40px;
  }
`;
