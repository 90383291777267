import { DefaultTheme, ThemeProps } from 'styled-components';
import { InputProps } from './types';

export function getColor(
  { disabled, isLoading, theme }: InputProps & ThemeProps<DefaultTheme>,
  placeholder: boolean = false,
) {
  if (disabled) {
    return theme.grey.mix(theme.white, 0.7);
  }

  if (isLoading || placeholder) {
    return theme.grey;
  }

  return theme.textColor;
}
