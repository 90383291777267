import { DocumentReference, getDoc } from 'firebase/firestore';
import { ProductDetails } from '../../../entities/productDetails';

/**
 * Retrieves the document snapshot for school stats.
 */
export async function getProductDetailsFromRef(
  ref: DocumentReference<ProductDetails>,
): Promise<ProductDetails> {
  const doc = await getDoc(ref);

  return doc.exists() ? doc.data() : ProductDetails.DEFAULT;
}
