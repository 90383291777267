import styled from 'styled-components';

interface LetterAvatarProps {
  inHighlightedSection?: boolean;
  mobileMenu?: boolean;
}

const LetterAvatar = styled.span<LetterAvatarProps>`
  align-items: center;
  background-color: ${(props) =>
    props.inHighlightedSection || props.mobileMenu
      ? props.theme.white.fade(0.9).string()
      : props.theme.grey.fade(0.4).string()};
  border-radius: 4px;
  color: ${(props) => props.theme.white.string()};
  display: flex;
  font-size: ${(props) => (props.mobileMenu ? '2rem' : '1.2rem')};
  font-weight: ${(props) => props.theme.fontWeightLight};
  height: ${(props) => (props.mobileMenu ? '80px' : '40px')};
  justify-content: center;
  margin: 0 0 0 1rem;
  width: ${(props) => (props.mobileMenu ? '80px' : '40px')};
`;

interface AvatarProps {
  inHighlightedSection?: boolean;
  mobileMenu?: boolean;
  name: string;
  src: string;
}

export function Avatar({
  inHighlightedSection,
  mobileMenu,
  name,
  src,
}: AvatarProps) {
  const letter = name.length > 0 ? name[0].toUpperCase() : '?';

  return (
    <LetterAvatar
      inHighlightedSection={inHighlightedSection}
      mobileMenu={mobileMenu}>
      {letter}
    </LetterAvatar>
  );
}
