import styled from 'styled-components';

export const MenuLinks = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
`;

interface MenuItemProps {
  dropLeftMargin: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  margin: auto 0.7rem;

  ${(props) => props.dropLeftMargin && 'margin-left: 0'}
`;
