import { Anchor, Button } from '@shared';
import { ChangeEvent, HTMLProps, useRef } from 'react';
import styled from 'styled-components';
import { getFileName } from './getFileName';

interface FileInputProps extends HTMLProps<HTMLInputElement> {
  clearFiles: () => void;
  files: FileList;
  label?: string;
  onFilesSelected: (files: FileList) => void;
}

const FileInputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const FileNames = styled.span``;

const Input = styled.input`
  display: none;
`;

export function FileInput(props: FileInputProps) {
  const inputRef = useRef<HTMLInputElement>();

  const { clearFiles, files, label, onFilesSelected, ...inputProps } = props;
  const displayLabel = label ?? 'Select a file';
  const fileNames = getFileName(files);

  function handleSelect() {
    inputRef.current.click();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      onFilesSelected(event.target.files);
    }
  }

  return (
    <FileInputWrapper>
      <Button onClick={handleSelect} type="button">
        {displayLabel}
      </Button>

      <FileNames>
        {fileNames}{' '}
        {files?.length > 0 && (
          <Anchor href="#?" onClick={clearFiles}>
            (click here to remove)
          </Anchor>
        )}
      </FileNames>
      <Input
        {...inputProps}
        onChange={handleChange}
        ref={inputRef}
        type="file"
      />
    </FileInputWrapper>
  );
}
