/**
 * UI components for rendering key-value data in a tabular list.
 */
import styled from 'styled-components';

export const TableList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableListRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.3rem auto;
  position: relative;
  width: 100%;
`;

export { FormLabelRow as TableListLabel } from './form/Form';

export const TableListValue = styled.div``;

export const TableListActionsRow = styled(TableListRow)`
  flex-direction: row;
  justify-content: flex-start;
  margin: 0.5rem 0;
`;
