import { ProfileType } from '@dao';
import { FirestorePage } from '@libraries/firebase';
import { getQueryParamValue } from '@utils';
import { ParsedUrlQuery } from 'querystring';
import { PersonnelSearchFilterOptions } from './types';

export class PersonnelSearchFilters {
  static BLANK = PersonnelSearchFilters.create({});

  /** Creates a new instance. */
  static create(
    options: PersonnelSearchFilterOptions,
    page?: FirestorePage,
    limit?: number,
  ) {
    return new PersonnelSearchFilters(
      { ...options },
      page ?? FirestorePage.FIRST,
      limit ?? 8,
    );
  }

  /** Creates a new instance from the given URL query.  */
  static fromUrlQuery(query: ParsedUrlQuery): PersonnelSearchFilters {
    const options: PersonnelSearchFilterOptions = {};

    options.city = getQueryParamValue(query.city);
    options.state = getQueryParamValue(query.state);

    if (query.type) {
      const types: ProfileType[] = getQueryParamValue(query.type)
        .split(',')
        .map((type) => {
          if (type === 'coordinator') {
            return ProfileType.Coordinator;
          }

          if (type === 'instructor') {
            return ProfileType.Instructor;
          }

          return null;
        })
        .filter(Boolean);

      options.type = types;
    }

    return PersonnelSearchFilters.create(options);
  }

  /** Returns the city option. */
  get city(): string {
    return this.options.city;
  }

  /** Unique hash representing this set of filters. */
  get hash(): string {
    return JSON.stringify(this);
  }

  /** Returns the state option. */
  get state(): string {
    return this.options.state;
  }

  /** Returns the type option. */
  get type(): ProfileType[] {
    return this.options.type;
  }

  /** Returns a URL route with the options as the query. */
  getRoute(base: string): string {
    const queries: string[] = [];

    if (this.city) {
      queries.push(`city=${this.city}`);
    }

    if (this.state) {
      queries.push(`state=${this.state}`);
    }

    if (this.type) {
      const types: string[] = [];

      for (const value of this.type) {
        if (value === ProfileType.Coordinator) {
          types.push('coordinator');
        } else if (value === ProfileType.Instructor) {
          types.push('instructor');
        }
      }

      if (types.length) {
        queries.push(`type=${types.join(',')}`);
      }
    }

    if (queries.length) {
      return `${base}?${queries.join('&')}`;
    }

    return base;
  }

  /** Creates a new instance with the given options. */
  withOptions(options: PersonnelSearchFilterOptions) {
    return PersonnelSearchFilters.create({ ...options }, undefined, this.limit);
  }

  constructor(
    // Search filters.
    readonly options: PersonnelSearchFilterOptions,
    // Pagination cursor.
    readonly page: FirestorePage,
    // Query limit.
    readonly limit = 8,
  ) {}
}
