import { logError } from '@reporting';
import { getHash } from '@utils';
import { FirebaseFile } from '../entities/firebaseFile';

/**
 * Uploads a file and optionally deletes the previous one.
 * @returns The unique filename.
 */
export async function uploadAndDelete(
  basePath: string,
  file: File,
  fileToDelete = '',
): Promise<string> {
  const filename = await readFile(file);

  // Upload the file.
  try {
    const firebaseFile = FirebaseFile.create(`${basePath}/${filename}`);

    await firebaseFile.upload(file);
  } catch (err) {
    const uploadError = new Error('Could not upload cloud file', {
      cause: err,
    });

    logError(uploadError);

    throw uploadError;
  }

  // Delete the previous file if exists.
  if (!fileToDelete) {
    return filename;
  }

  try {
    const firebaseFile = FirebaseFile.create(`${basePath}/${fileToDelete}`);

    await firebaseFile.delete();
  } catch (err) {
    logError(
      new Error('Could not delete cloud file', {
        cause: err,
      }),
    );
  }

  return filename;
}

/** Reads a file and generates a unique filename. */
function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (event) => {
      // Hash the file's data string and use as the unique filename.
      resolve(getHash(event.target.result));
    };

    reader.onerror = () => {
      reject(new Error('Could not read file content.'));
    };

    // Get the file base 64 string.
    reader.readAsDataURL(file);
  });
}
