/**
 * Ensures that a value from a JSON object is an array.
 */
export function deserializeArray<T = any>(value: any, defaultValue = []): T[] {
  if ([undefined, null].includes(value)) {
    return defaultValue;
  }

  if (Array.isArray(value)) {
    return value.length ? value : defaultValue;
  }

  return [value];
}

/**
 * Ensures that a value from a JSON object is a number.
 */
export function deserializeNumber(value: any, defaultValue = 0): number {
  if ([undefined, null].includes(value) || value === '') {
    return defaultValue;
  }

  const deserialized = Number(value);

  return isNaN(deserialized) ? defaultValue : deserialized;
}

/**
 * Ensures that a value from a JSON object is a string.
 */
export function deserializeString(value: any, defaultValue = ''): string {
  if ([undefined, null].includes(value) || value === '') {
    return defaultValue;
  }

  return String(value);
}
