import { School } from './entities/school';
import { SchoolMetadata } from './entities/schoolMetadata';

export interface SchoolWithMetadata {
  school: School;
  metadata: SchoolMetadata;
}

export interface SocialHandles {
  // Facebook
  fb?: string;
  // Instagram
  ig?: string;
  // LinkedIn
  in?: string;
  // Twitter
  tw?: string;
  // YouTube
  yt?: string;
}

export enum ProductType {
  PremiumAccess = 'premium',
  PromotedSchool = 'promo-school',
}

export enum SubscriptionDuration {
  Monthly = 'monthly',
  Yearly = 'yearly',
}
