import { PersonnelProfile, useCloudFile } from '@dao';
import { ResultCard, getPersonnelRoute } from '@shared';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { FaUsers } from 'react-icons/fa';
import styled from 'styled-components';

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0.4rem;
`;

const Location = styled.span`
  line-height: 2rem;
  margin-right: 1rem;
`;

const Photo = styled.div`
  color: ${(props) => props.theme.lightGrey.string()};
  font-size: 2rem;
  margin-right: 1rem;
  width: 50px;
`;

const Name = styled.div`
  font-weight: ${(props) => props.theme.fontWeightBold};
  line-height: 1.8rem;
  text-align: left;
`;

const PersonnelProfileDetails = styled.div`
  flex-grow: 1;
`;

const TextWithBg = styled.div<{ yellow?: boolean }>`
  background-color: ${(props) =>
    props.yellow
      ? props.theme.yellow.mix(props.theme.white, 0.4).string()
      : props.theme.cyan.mix(props.theme.white, 0.8).string()};
  border-radius: 5px;
  margin-right: 6px;
  padding: 4px;
`;

export interface PersonnelProfileCardProps {
  personnelProfile: PersonnelProfile;
}

/** Renders a horizontal personnel profile card. */
export function PersonnelProfileCard({
  personnelProfile,
}: PersonnelProfileCardProps) {
  const { blobUrl } = useCloudFile(
    personnelProfile.publicBasePath,
    personnelProfile.photo,
  );

  const router = useRouter();

  function navigateToPersonnelProfile() {
    router.push(getPersonnelRoute(personnelProfile.userId));
  }

  return (
    <ResultCard onClick={navigateToPersonnelProfile}>
      <Photo>
        {blobUrl ? (
          <Image alt="" height={50} src={blobUrl} width={50} />
        ) : (
          <FaUsers />
        )}
      </Photo>

      <PersonnelProfileDetails>
        <Name>{personnelProfile.fullName}</Name>
        <Info>
          <Location>
            {personnelProfile.city}, {personnelProfile.state}
          </Location>
          <TextWithBg>{personnelProfile.email}</TextWithBg>
          <TextWithBg yellow>{personnelProfile.phone}</TextWithBg>
        </Info>
      </PersonnelProfileDetails>
    </ResultCard>
  );
}
