import { useContext } from 'react';
import { FirebaseAuthUserContext } from '../FirebaseAuthUserContext';

/**
 * React hook for accessing the Firebase user auth and loading state through the
 * {@link FirebaseAuthUserContext}.
 */
export function useFirebaseAuthUserContext() {
  return useContext(FirebaseAuthUserContext);
}
