import { Address, debounceSearchForAddresses } from '@utils';
import { useEffect, useState } from 'react';
import { SearchOption } from '../components/form/SearchInput';

interface AddressSearchOptionsResult {
  isLoading: boolean;
  options: SearchOption<Address>[];
}

/** React hook for getting address search results from a query. */
export function useAddressSearchOptions(
  originalQuery: string,
): AddressSearchOptionsResult {
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState<SearchOption<Address>[]>([]);
  const query = originalQuery?.trim() || '';

  useEffect(() => {
    setLoading(true);
    debounceSearchForAddresses(query)?.then((results) => {
      setOptions(
        results.map((result) => ({ label: result.address, value: result })),
      );
      setLoading(false);
    });
  }, [query]);

  return { isLoading, options };
}
