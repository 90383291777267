import { InputProps } from './types';

export function getCursor({ disabled, isLoading }: InputProps) {
  if (disabled) {
    return 'not-allowed';
  }

  if (isLoading) {
    return 'progress';
  }

  return 'text';
}
