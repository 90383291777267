/** Handles errors while authenticating with Firebase. */
export function handleError(error) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return ['email', 'Looks like that email address is already being used.'];

    case 'auth/invalid-email':
      return ['email', 'Please double-check your email address.'];

    case 'auth/user-not-found':
      return [
        'email',
        "Looks like there's no account with that email address.",
      ];

    case 'auth/weak-password':
      return [
        'password',
        'Your password should be at least 6 characters long.',
      ];

    case 'auth/wrong-password':
      return [
        'password',
        "Please double-check that you're using the right password.",
      ];

    case 'auth/internal-error':
    default:
      return [null, error.message];
  }
}
