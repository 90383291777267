import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { SelectProps } from './types';

export const Select = styled(Input).attrs({ as: 'select' })<SelectProps>`
  cursor: default;
`;

export function SelectInput(props: SelectProps) {
  const { onChange, ...inputProps } = props;

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value);
  }

  return <Select {...inputProps} onChange={handleChange} />;
}
