import styled from 'styled-components';
import { Address } from '../../../../utils/geo';
import { getStaticMapUrl } from '../../../../utils/maps';
import { AspectRatio } from '../../layout/AspectRatio';

interface AddressMapProps {
  address: Address;
}

const aspectRatioProps = {
  ratio: '10:3',
};

const AddressMapWrapper = styled(AspectRatio).attrs(aspectRatioProps)<{
  background: string;
}>`
  background-image: url(${(props) => props.background});
  border-radius: 4px;
`;

export function AddressMap({ address }: AddressMapProps) {
  if (isInvalid(address?.latitude) || isInvalid(address?.longitude)) {
    return null;
  }

  const src = getStaticMapUrl({
    height: 200,
    latitude: address.latitude,
    longitude: address.longitude,
  });

  return <AddressMapWrapper background={src} />;
}

function isInvalid(num) {
  if (num === null || num === undefined) {
    return true;
  }

  return isNaN(num);
}
