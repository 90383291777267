import styled from 'styled-components';

export const Contact = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto;

  & > a,
  & > span {
    margin: 0.4rem 0;
  }

  @media (min-width: 1024px) {
    align-items: flex-end;
    margin: 0;
    text-align: left;
  }
`;

export const ContactAnchor = styled.a`
  color: ${(props) => props.theme.white.string()};
  text-decoration: none;
`;
