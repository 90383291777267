import { Entity } from './entity';

/** Represents a claim on a CNA school. */
export class SchoolClaim extends Entity {
  /** Creates a SchoolClaim instance with the given data. */
  static create(
    schoolId: string,
    profileId: string,
    status: SchoolClaimStatus,
    claimTimestamp: Date,
    resolutionTimestamp: Date = null,
    resolverId: string = '',
    claimId = '',
  ) {
    return new SchoolClaim(
      claimId,
      schoolId,
      profileId,
      status,
      claimTimestamp,
      resolutionTimestamp,
      resolverId,
    );
  }

  /** Creates a SchoolClaim instance for the given school. */
  static forSchool(schoolId: string, profileId: string) {
    return SchoolClaim.create(
      schoolId,
      profileId,
      SchoolClaimStatus.Pending,
      new Date(),
    );
  }

  /** Descriptive message determined by the school claim status. */
  get statusLabel() {
    switch (this.status) {
      case SchoolClaimStatus.Approved:
        return 'Approved';

      case SchoolClaimStatus.Pending:
        return 'Pending';

      case SchoolClaimStatus.Rejected:
        return 'Denied';

      default:
        return 'Unknown';
    }
  }

  /** Compares this SchoolClaim instance to another. */
  isEqual(other: SchoolClaim) {
    return (
      other &&
      other.id === this.id &&
      other.schoolId === this.schoolId &&
      other.profileId === this.profileId &&
      other.status === this.status &&
      other.claimTimestamp.valueOf() === this.claimTimestamp.valueOf() &&
      other.resolutionTimestamp?.valueOf() ===
        this.resolutionTimestamp?.valueOf() &&
      other.resolverId === this.resolverId
    );
  }

  /** Creates a copy of this SchoolClaim instance with the given ID. */
  withId(id: string) {
    return SchoolClaim.create(
      this.schoolId,
      this.profileId,
      this.status,
      this.claimTimestamp,
      this.resolutionTimestamp,
      this.resolverId,
      id,
    );
  }

  /** Creates a copy of this SchoolClaim instance with the given status. */
  withStatus(status: SchoolClaimStatus, resolvedBy: string = '') {
    if (status !== SchoolClaimStatus.Pending && !resolvedBy) {
      throw new Error(
        'ID of resolver is required when resolving a school claim.',
      );
    }

    const resolutionTimestamp =
      status === SchoolClaimStatus.Pending
        ? this.resolutionTimestamp
        : new Date();
    const resolverId = resolvedBy || this.resolverId;

    return SchoolClaim.create(
      this.schoolId,
      this.profileId,
      status,
      this.claimTimestamp,
      resolutionTimestamp,
      resolverId,
      this.id,
    );
  }

  protected constructor(
    // Auto-generated Firestore ID.
    readonly id: string,
    // ID of the claimed school.
    readonly schoolId: string,
    // ID of the claimant.
    readonly profileId: string,
    // Status of the claim.
    readonly status: SchoolClaimStatus,
    // Timestamp when claim was created.
    readonly claimTimestamp: Date,
    // Timestamp when claim was resolved.
    readonly resolutionTimestamp: Date,
    // ID of the profile responsible for resolving this claim.
    readonly resolverId: string,
  ) {
    super('sc');
  }
}

export enum SchoolClaimStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}
