import { useEffect } from 'react';
import { reauthenticateUser } from '../../libraries/firebase';

export function useReauthenticateUser(
  authenticating: boolean,
  password: string,
  onAuthenticated: () => void,
  onAuthenticationError: (error: string) => void,
) {
  useEffect(() => {
    if (!authenticating) {
      return;
    }

    reauthenticateUser(password, onAuthenticated, onAuthenticationError);

    // The effect does not depend on the callback functions.
    // eslint-disable-next-line
  }, [authenticating, password]);
}
