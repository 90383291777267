import { DefaultTheme, ThemeProps } from 'styled-components';
import { InputProps } from './types';

export function getBgColor({
  disabled,
  hasError,
  isLoading,
  theme,
}: InputProps & ThemeProps<DefaultTheme>) {
  if (disabled) {
    return theme.grey.mix(theme.white, 0.95);
  }

  if (hasError) {
    return theme.red.mix(theme.white, 0.85);
  }

  if (isLoading) {
    return theme.cyan.mix(theme.white, 0.9);
  }

  return theme.grey.mix(theme.white, 0.9);
}
