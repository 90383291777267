import deburr from 'lodash/deburr';
import kebabCase from 'lodash/kebabCase';
import { NextRouter } from 'next/router';
import { logWarning } from '../shared/reporting';
import { isAuthRoute } from '../shared/routes';
import { IS_DEVELOPMENT_ENV, IS_STAGING_ENV } from './env';

/**
 * Determines whether the string contains encoded components, such as ?,=,&,/
 */
export function containsEncodedComponents(input: string): boolean {
  return decodeURI(input) !== decodeURIComponent(input);
}

/** Retrieves the current URL path. */
export function getCurrentPath(router: NextRouter) {
  let path = router.pathname;

  for (const key in router.query) {
    const placeholder = `[${key}]`;

    if (path.includes(placeholder)) {
      path = path.replace(placeholder, getQueryParamValue(router.query[key]));
    }
  }

  return path;
}

/** Returns the host name, based on the deployed environment. */
export function getProtocolAndHost() {
  if (IS_DEVELOPMENT_ENV) {
    return 'http://localhost:3000';
  }

  if (IS_STAGING_ENV) {
    return 'https://preview.cnaclassesforme.com';
  }

  return 'https://cnaclassesforme.com';
}

/** Retrieves the value of a Next router query parameter. */
export function getQueryParamValue(param: string | string[]): string {
  if (!param) {
    return '';
  }

  return typeof param === 'string' ? param : String(param[0]);
}

/** Determines the path to redirect to after authenticating. */
export function getRedirectPath(router: NextRouter) {
  if (router.query.next) {
    return sanitizePath(router.query.next);
  }

  return isAuthRoute(router.pathname) ? '/' : getCurrentPath(router);
}

export function getSlug(id: string, title?: string): string {
  const newTitle = kebabCase(deburr(title));

  return newTitle ? `${newTitle}-${id}` : id;
}

export function getSocialHandle(input: string): string {
  const domainRegex = /.+\.com\//;
  const hashRegex = /#.+/;
  const queryRegex = /\?.+/;
  const trimRegex = /^\/+|\/+$/g;

  return input
    .trim()
    .replace(queryRegex, '')
    .replace(hashRegex, '')
    .replace(domainRegex, '')
    .replace(trimRegex, '')
    .trim();
}

export function parseIdFromSlug(slugParam: string | string[]): string {
  const slug = getQueryParamValue(slugParam);

  if (!slug) {
    return '';
  }

  const parts = slug.split('-');

  return parts[parts.length - 1].replace(/[^a-zA-Z0-9-]/g, '');
}

/** Sanitizes a user-provided path, e.g. a redirect path. */
export function sanitizePath(next?: string | string[] | null) {
  let rawPath = getQueryParamValue(next);

  if (containsEncodedComponents(rawPath)) {
    try {
      rawPath = decodeURIComponent(rawPath);
    } catch (error) {
      logWarning(error);

      return '/';
    }
  }

  const host = getProtocolAndHost();
  const isFullUrl = rawPath.indexOf(host) === 0;

  if (isFullUrl) {
    rawPath = rawPath.substring(host.length);
  }

  const paths = rawPath
    .split('/')
    // Remove invalid characters.
    .map((path) => path.replace(/[^a-zA-Z0-9_\.,+:\-/?=&]+/, ''))
    // Filter-out invalid paths.
    .filter((path) => path && path.trim().length > 0);
  const sanitizedPath = `/${paths.join('/')}`;

  return isFullUrl ? `${host}${sanitizedPath}` : sanitizedPath;
}
