import { setDoc } from 'firebase/firestore';
import { AdminSchoolStats } from './constants/schoolStats';
import { getSchoolStatsDocRef, getSchoolStatsFromRef } from './utils/school';

export async function updateAdminSchoolStats(
  updatedStats: Partial<AdminSchoolStats>,
): Promise<AdminSchoolStats> {
  const ref = getSchoolStatsDocRef();
  const stats = await getSchoolStatsFromRef(ref);
  let hasChanges = false;

  for (const key in stats) {
    if (key in updatedStats) {
      if (stats[key] !== updatedStats[key]) {
        hasChanges = true;
        stats[key] += updatedStats[key];
      }
    }
  }

  if (hasChanges) {
    await setDoc(ref, stats);
  }

  return stats;
}
