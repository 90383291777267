import styled from 'styled-components';
import { ErrorMessage } from '../error/ErrorMessage';

export const Form = styled.form<{ alignItems?: string }>`
  align-items: ${(props) => props.alignItems || 'flex-start'};
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.div`
  color: ${(props) => props.theme.navy.string()};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  text-align: left;
  width: 100%;
`;

export const FormSectionTitle = styled.div`
  color: ${(props) => props.theme.textColor.string()};
  font-size: 0.7em;
  font-weight: ${(props) => props.theme.fontWeightBold};
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;

export const FormRow = styled.div<{
  flush?: string;
  half?: boolean;
  horizontal?: boolean;
  justifyContent?: string;
  maxWidth?: string;
  narrow?: boolean;
  padLeft?: boolean;
  premium?: boolean;
  width?: string;
}>`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  justify-content: ${(props) => props.justifyContent || 'center'};
  // Make top/bottom margin 0.3rem for narrow FormRow.
  // Remove left/right margin for flush FormRow.
  margin: ${(props) => (props.narrow ? '0.3rem' : '0.7rem')}
    ${(props) => (props.flush ? '-1rem' : 'auto')};
  max-width: ${(props) => props.maxWidth || '600px'};
  position: relative;
  width: ${(props) => props.width ?? '100%'};

  ${(props) => props.half && 'width: calc(50% - 0.5rem);'}
  ${(props) => props.padLeft && 'margin-left: 1rem;'}
`;

export const FormRowNote = styled.div`
  color: ${(props) => props.theme.green.string()};
  font-size: 0.7em;
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: -0.2rem 1.2rem 0 1.2rem;
  width: 100%;
`;

export const FormLabelRow = styled.div<{ padding?: string }>`
  box-sizing: border-box;
  color: ${(props) => props.theme.navy.string()};
  font-size: 0.7em;
  font-weight: ${(props) => props.theme.fontWeightBold};
  max-width: 600px;
  padding: ${(props) => props.padding || '0'};
  text-align: left;
  width: 100%;
`;

export const WideFormLabelRow = styled(FormLabelRow)`
  max-width: 100%;
`;

const FormErrorWrapper = styled(ErrorMessage)`
  margin: -0.2rem 1.2rem 0 1.2rem;
  width: 100%;
`;

export function FormError({ msg }: { msg: string }) {
  if (!msg) {
    return null;
  }

  return <FormErrorWrapper>{msg}</FormErrorWrapper>;
}
