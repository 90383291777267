/**
 * Input with auto-complete.
 */
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DataList, DataListOption } from './DataList';
import { Input } from './Input';
import { InputProps } from './types';

const SearchInputWrapper = styled.div<{ flush?: boolean; width?: string }>`
  margin: auto 1rem auto ${(props) => (props.flush ? '-1rem' : '0')};
  max-width: ${(props) => props.width || '100%'};
  position: relative;
  width: 100%;

  // Hide down arrow in Chrome.
  &::-webkit-calendar-picker-indicator {
    display: block !important;
  }
`;

export interface SearchOption<T> {
  label?: string;
  value: T;
}

export interface SearchInputProps<OptionType>
  extends Omit<InputProps, 'onChange' | 'onSelect'> {
  flush?: boolean;
  onChange: (value: string) => void;
  onSelect: (value: OptionType) => void;
  options: SearchOption<OptionType>[];
  placeholder?: string;
  value: string;
}

export function SearchInput<OptionType>(props: SearchInputProps<OptionType>) {
  const {
    flush,
    options,
    onChange,
    onSelect,
    placeholder,
    value,
    width,
    ...inputProps
  } = props;
  const [hasFocus, setFocus] = useState(false);

  // Set a timeout on Input blur to give time for the component tree to update.
  // If the input was blurred after selecting an option, we cancel the timeout.
  // Otherwise, we set focus to false after the timeout.
  const [hasFocusTimeout, setFocusTimeout] = useState(false);

  useEffect(() => {
    if (!hasFocusTimeout) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setFocus(false);
      setFocusTimeout(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [hasFocusTimeout]);

  function handleBlur() {
    setFocusTimeout(true);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  function handleFocus() {
    setFocus(true);
  }

  return (
    <SearchInputWrapper flush={flush} width={width}>
      <Input
        {...inputProps}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        value={value}
        width={width}
      />

      {options.length > 0 && (
        <DataList visible={hasFocus}>
          {options.map(({ label, value: optionValue }) => (
            <DataListOption
              key={JSON.stringify(optionValue)}
              label={label}
              onClick={onSelect}
              value={optionValue}
            />
          ))}
        </DataList>
      )}
    </SearchInputWrapper>
  );
}
