import { PersonnelDao, PersonnelProfile } from '@dao';
import {
  Contact,
  ContactAnchor,
  ImageUpload,
  Link,
  Loading,
  Name,
  ProfileDetails,
  ProfileDetailsWrapper,
  Section,
  logError,
} from '@shared';

interface PersonnelProfileDetailsProps {
  loading: boolean;
  personnelProfile: PersonnelProfile;
}

export function PersonnelProfileDetails({
  loading,
  personnelProfile,
}: PersonnelProfileDetailsProps) {
  function deletePersonnelPhoto() {
    PersonnelDao.upsertPersonnelProfile(personnelProfile.withPhoto('')).catch(
      (err) => {
        logError(
          new Error('Failed to update personnel profile', { cause: err }),
        );
      },
    );
  }

  function uploadPersonnelPhoto(photo: string) {
    PersonnelDao.upsertPersonnelProfile(
      personnelProfile.withPhoto(photo),
    ).catch((err) => {
      logError(new Error('Failed to update personnel profile', { cause: err }));
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Section highlight>
      <ProfileDetailsWrapper>
        <ProfileDetails>
          <Name>{personnelProfile.fullName}</Name>
        </ProfileDetails>

        <Contact>
          <ImageUpload
            alt="Personnel Profile Photo"
            basePath={personnelProfile.publicBasePath}
            emptyText="Upload your photo"
            filename={personnelProfile.photo}
            onDelete={deletePersonnelPhoto}
            onUpload={uploadPersonnelPhoto}
          />

          <ContactAnchor href={`tel:${personnelProfile.phone}`}>
            {personnelProfile.phone}
          </ContactAnchor>

          <span>
            {personnelProfile.city}, {personnelProfile.state}
          </span>

          <Link
            href={`mailto:${personnelProfile.email}`}
            inHighlightedSection
            target="_blank">
            {personnelProfile.email}
          </Link>
        </Contact>
      </ProfileDetailsWrapper>
    </Section>
  );
}
