import styled from 'styled-components';
import { Address } from '../../../../utils/geo';
import { DataList, DataListOption } from '../DataList';
import { SearchOption } from '../SearchInput';

interface AddressOptionsProps {
  hasFocus: boolean;
  onSelect: (value: Address) => void;
  options: SearchOption<Address>[];
}

const AddressOptionsWrapper = styled.div`
  // Data list needs margins.
  margin: auto 1rem;
  width: 100%;
`;

export function AddressOptions({
  hasFocus,
  onSelect,
  options,
}: AddressOptionsProps) {
  if (options.length < 1) {
    return null;
  }

  return (
    <AddressOptionsWrapper>
      <DataList visible={hasFocus}>
        {options.map(({ label, value: optionValue }) => (
          <DataListOption
            key={JSON.stringify(optionValue)}
            label={label}
            onClick={onSelect}
            value={optionValue}
          />
        ))}
      </DataList>
    </AddressOptionsWrapper>
  );
}
