import '@csstools/normalize.css';
import { FirebaseAuthUserProvider, initFirebase } from '@libraries/firebase';
import { initGoogleClient } from '@libraries/google';
import { MDXProvider } from '@mdx-js/react';
import {
  GlobalStyle,
  PageWrapper,
  PreviewAppRibbon,
  Section,
  theme,
} from '@shared';
import { SITE_NAME } from '@utils';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';

const mdxComponentsConfig = {
  // eslint-disable-next-line react/display-name
  wrapper: (props) => (
    <PageWrapper>
      <Section>
        <div {...props} />
      </Section>
    </PageWrapper>
  ),
};

initFirebase();

export default function App({
  Component,
  err,
  pageProps,
}: AppProps & { err: any }) {
  // Initialize Google client. To avoid re-rendering when the Google client is
  // loaded, we initialize it asynchronously and let dependent functions use it
  // as needed.
  initGoogleClient();

  return (
    <ThemeProvider theme={theme}>
      <FirebaseAuthUserProvider>
        <MDXProvider components={mdxComponentsConfig}>
          <Head>
            <title>{SITE_NAME}</title>
          </Head>
          <GlobalStyle />
          <PreviewAppRibbon />
          <Component {...pageProps} err={err} />
        </MDXProvider>
      </FirebaseAuthUserProvider>
    </ThemeProvider>
  );
}
