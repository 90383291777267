import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';
import { getBgColor } from './getBgColor';

const CheckboxSquare = styled.div<{ checked: boolean }>`
  background-color: ${(props) =>
    props.checked
      ? props.theme.cyan.mix(props.theme.white, 0.6).string()
      : getBgColor(props).string()};
  border-color: ${(props) =>
    props.checked
      ? props.theme.cyan.mix(props.theme.white, 0.6).string()
      : props.theme.navy.mix(props.theme.white, 0.8).string()};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 7px
    ${(props) => props.theme.grey.mix(props.theme.white, 0.9).string()};
  height: 0.9rem;
  margin-right: 0.3rem;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  width: 0.9rem;

  svg {
    bottom: 0.1rem;
    color: ${(props) => props.theme.textColor.string()};
    left: 0.1rem;
    position: absolute;
  }
`;

const CheckboxWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 0.6rem;

  &:hover ${CheckboxSquare} {
    box-shadow: 0 0 10px
      ${(props) => props.theme.grey.mix(props.theme.white, 0.6).string()};
`;

const CheckboxLabel = styled.p`
  margin: 0;
`;

interface CheckboxProps {
  checked?: boolean;
  id?: string;
  label: string;
  name?: string;
  onChange: (newValue: boolean) => void;
}

export function Checkbox({
  checked,
  id,
  label,
  name,
  onChange,
}: CheckboxProps) {
  return (
    <CheckboxWrapper onClick={() => onChange(!checked)}>
      <CheckboxSquare checked={checked} id={id || name} name={name}>
        {checked && <FaCheck />}
      </CheckboxSquare>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
}
