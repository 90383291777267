export const SCHOOL_ATTRIBUTE_DAY = 'day';
export const SCHOOL_ATTRIBUTE_EVENING = 'evening';
export const SCHOOL_ATTRIBUTE_OFFLINE = 'offline';
export const SCHOOL_ATTRIBUTE_ONLINE = 'online';
export const SCHOOL_ATTRIBUTE_TUITION = 'tuition';
export const SCHOOL_ATTRIBUTE_VERIFIED = 'verified';
export const SCHOOL_ATTRIBUTE_WEEKEND = 'weekend';

/**
 * Type for supported school attributes.
 */
export type SchoolAttribute =
  | typeof SCHOOL_ATTRIBUTE_DAY
  | typeof SCHOOL_ATTRIBUTE_EVENING
  | typeof SCHOOL_ATTRIBUTE_OFFLINE
  | typeof SCHOOL_ATTRIBUTE_ONLINE
  | typeof SCHOOL_ATTRIBUTE_TUITION
  | typeof SCHOOL_ATTRIBUTE_VERIFIED
  | typeof SCHOOL_ATTRIBUTE_WEEKEND;

/**
 * School attributes that can only take on "true" or "false" values.
 */
export type BooleanSchoolAttribute = Exclude<
  SchoolAttribute,
  typeof SCHOOL_ATTRIBUTE_TUITION
>;

/**
 * Map of school attributes to their names. The ordering matters.
 */
type SchoolAttributeNameMap = { [Attr in SchoolAttribute]: string };
export const SCHOOL_ATTRIBUTE_NAME_MAP: SchoolAttributeNameMap = {
  [SCHOOL_ATTRIBUTE_DAY]: 'Day classes',
  [SCHOOL_ATTRIBUTE_EVENING]: 'Evening classes',
  [SCHOOL_ATTRIBUTE_WEEKEND]: 'Weekend classes',
  [SCHOOL_ATTRIBUTE_OFFLINE]: 'In-person',
  [SCHOOL_ATTRIBUTE_ONLINE]: 'Hybrid/online',
  [SCHOOL_ATTRIBUTE_VERIFIED]: 'Verified',
  [SCHOOL_ATTRIBUTE_TUITION]: 'Tuition',
};

/**
 * List of all school attributes.
 */
export const SCHOOL_ATTRIBUTE_LIST: SchoolAttribute[] = Object.keys(
  SCHOOL_ATTRIBUTE_NAME_MAP,
) as SchoolAttribute[];

/**
 * List of all Boolean school attributes.
 */
export const BOOLEAN_SCHOOL_ATTRIBUTE_LIST: SchoolAttribute[] =
  SCHOOL_ATTRIBUTE_LIST.filter(
    (attribute) =>
      ![SCHOOL_ATTRIBUTE_VERIFIED, SCHOOL_ATTRIBUTE_TUITION].includes(
        attribute,
      ),
  );

/**
 * List of all school attribute names.
 */
export const SCHOOL_ATTRIBUTE_NAMES: string[] = Object.values(
  SCHOOL_ATTRIBUTE_NAME_MAP,
);
