import {
  collection,
  CollectionReference,
  doc,
  documentId,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  SchoolMetadata,
  SerializedSchoolMetadata,
} from './entities/schoolMetadata';

/** Handles data access for {@link SchoolMetadata} entities. */
export class SchoolMetadataDao {
  static readonly COLLECTION_NAME = 'schoolMetadata';

  /** Firestore data converter for {@link SchoolMetadata} entities. */
  static readonly CONVERTER: FirestoreDataConverter<SchoolMetadata> = {
    fromFirestore: function (document, options?) {
      const serialized = document.data() as SerializedSchoolMetadata;

      return SchoolMetadata.fromSerialized({ ...serialized, id: document.id });
    },
    toFirestore: function (metadata: SchoolMetadata, options?) {
      // Remove ID from serialized object.
      const { id, ...firestoreObject } = metadata.serialize();

      return firestoreObject;
    },
  };

  /** Returns a {@link SchoolMetadata} document for the given school ID. */
  static async getSchoolMetadata(id: string): Promise<SchoolMetadata> {
    if (!id) {
      return null;
    }

    const record = await getDoc(this.getDocumentRef(id));

    return record.exists() ? record.data() : SchoolMetadata.forSchoolId(id);
  }

  /** Lists {@link SchoolMetadata} documents for the given profile ID. */
  static async listSchoolMetadataByBookmarker(
    profileId: string,
  ): Promise<SchoolMetadata[]> {
    const results = await getDocs(
      query(
        this.getCollectionRef(),
        where('bookmarkedBy', 'array-contains', profileId),
      ),
    );

    return results.docs.map((result) => result.data());
  }

  /** Lists {@link SchoolMetadata} documents for the given school IDs. */
  static async listSchoolMetadataById(
    ids: string[],
  ): Promise<SchoolMetadata[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), where(documentId(), 'in', ids)),
    );

    return results.docs.map((result) => result.data());
  }

  /** Creates or updates a {@link SchoolMetadata} document. */
  static async upsertSchoolMetadata(
    metadata: SchoolMetadata,
  ): Promise<SchoolMetadata> {
    await setDoc(this.getDocumentRef(metadata.id), metadata);

    return metadata;
  }

  /** Returns a Firestore collection reference for school metadata. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<SchoolMetadata> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given school ID. */
  private static getDocumentRef(
    schoolId: string,
  ): DocumentReference<SchoolMetadata> {
    return doc(getFirestore(), this.COLLECTION_NAME, schoolId).withConverter(
      this.CONVERTER,
    );
  }
}
