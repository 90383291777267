import { ERROR_HAS_BEEN_REPORTED, ERROR_OCCURRED } from '../shared/messages';
import { logError } from '../shared/reporting';

/**
 * Handles generic errors by reporting them and alerting a message to the user.
 */
export function handleGenericError(error) {
  logError(error);
  alert(`${ERROR_OCCURRED} ${ERROR_HAS_BEEN_REPORTED}`);
}
