import styled from 'styled-components';
import { Card } from '../../layout/Card';

export const ResultCard = styled(Card)`
  background-color: ${(props) => props.theme.white.string()};
  box-sizing: border-box;
  color: ${(props) => props.theme.textColor.string()};
  cursor: pointer;
  display: flex;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize};
  height: auto;
  margin: 2em auto;
  text-decoration: none;
  transition:
    background-color 300ms,
    box-shadow 300ms;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 30px
      ${(props) => props.theme.grey.mix(props.theme.white, 0.75).string()};
  }
`;
