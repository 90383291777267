import { useState } from 'react';

export function useCheckbox(initialChecked = false) {
  const [checked, setChecked] = useState(initialChecked);

  function reset() {
    setChecked(initialChecked);
  }

  function toggle() {
    setChecked((checked) => !checked);
  }

  return { checked, reset, toggle };
}
