import { FirebaseApp, initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG } from './config';

/**
 * Returns an initialized instance of FirebaseApp for the frontend. If the app
 * has already been initialized, `initializeApp` will return the existing app.
 */
export function getFirebaseApp(): FirebaseApp {
  return initializeApp(FIREBASE_CONFIG);
}
