import { FirebaseAuthUserContext } from './FirebaseAuthUserContext';
import { useFirebaseAuthUser } from './hooks/useFirebaseAuthUser';

interface Props {
  children: React.ReactNode;
}

/**
 * Provides {@link FirebaseAuthUserContext} to child components.
 *
 * https://reactjs.org/docs/context.html
 */
export function FirebaseAuthUserProvider({ children }: Props) {
  const auth = useFirebaseAuthUser();

  return (
    <FirebaseAuthUserContext.Provider value={auth}>
      {children}
    </FirebaseAuthUserContext.Provider>
  );
}
