import { ProfileType } from '@dao';
import { useCityStateOptions, useSelectInput, useTextInput } from '@hooks';
import {
  Button,
  Card,
  Checkbox,
  FormTitle,
  SearchInput,
  SelectInput,
} from '@shared';
import { STATES_BY_NAME, STATE_NAMES } from '@utils';
import { useState } from 'react';
import styled from 'styled-components';
import { PersonnelSearchFilterOptions } from './types';

const STATES = STATE_NAMES.sort();

const ExpandedFiltersWrapper = styled(Card)``;

const CityStateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.25rem;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const SelectWrapper = styled.div`
  max-width: 300px;
`;

const Label = styled.label`
  color: ${(props) => props.theme.navy.string()};
`;

const TypeWrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
`;

interface ExpandedFiltersProps {
  onUpdateOptions: (options: PersonnelSearchFilterOptions) => void;
  options: PersonnelSearchFilterOptions;
}

export function ExpandedFilters({
  onUpdateOptions,
  options,
}: ExpandedFiltersProps) {
  const [coordinatorChecked, setCoordinatorChecked] = useState(
    !options.type || options.type.includes(ProfileType.Coordinator),
  );
  const [instructorChecked, setInstructorChecked] = useState(
    !options.type || options.type.includes(ProfileType.Instructor),
  );
  const city = useTextInput(options.city);
  const state = useSelectInput(options.state);
  const cityOptions = useCityStateOptions(city.value, state.value);

  /** Clears the city input and sets the state to the selected value. */
  function handleStateSelect(stateName: string) {
    city.updateValue('');
    state.updateValue(stateName);
  }

  /**
   * Splits the city/state at the comma and sets each to the selected value.
   * The city options may only include the city name, so state may be undefined.
   */
  function handleCitySelect(value: string) {
    const [cityName, stateName] = value.split(', ');

    city.updateValue(cityName);

    if (stateName) {
      state.updateValue(stateName);
    }
  }

  function handleCoordinatorCheck(checked: boolean) {
    setCoordinatorChecked(checked);
  }

  function handleInstructorCheck(checked: boolean) {
    setInstructorChecked(checked);
  }

  function handleUpdateFilters() {
    const types: ProfileType[] = [];

    if (coordinatorChecked) {
      types.push(ProfileType.Coordinator);
    }

    if (instructorChecked) {
      types.push(ProfileType.Instructor);
    }

    onUpdateOptions({ city: city.value, state: state.value, type: types });
  }

  return (
    <ExpandedFiltersWrapper>
      <FormTitle>Search filters</FormTitle>

      <CityStateWrapper>
        <SelectWrapper>
          <Label htmlFor="city">City</Label>
          <SearchInput<string>
            id="city"
            onChange={city.updateValue}
            onSelect={handleCitySelect}
            options={cityOptions}
            style={{ margin: 0 }}
            value={city.value}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Label htmlFor="state">State</Label>
          <SelectInput
            id="state"
            maxWidth="300px"
            onChange={handleStateSelect}
            style={{ margin: 0 }}
            value={state.value}>
            {STATES.map((state) => (
              <option key={state} value={STATES_BY_NAME[state]}>
                {state}
              </option>
            ))}
          </SelectInput>
        </SelectWrapper>
        <TypeWrapper>
          <Label>Personnel Type</Label>
          <CheckboxWrapper>
            <Checkbox
              checked={coordinatorChecked}
              label="Coordinator"
              onChange={handleCoordinatorCheck}
            />
            <Checkbox
              checked={instructorChecked}
              label="Instructor"
              onChange={handleInstructorCheck}
            />
          </CheckboxWrapper>
        </TypeWrapper>
      </CityStateWrapper>

      <Button onClick={handleUpdateFilters}>Update filters</Button>
    </ExpandedFiltersWrapper>
  );
}
