export const ADMIN_PRODUCT_DETAILS_ID = 'productDetails';

/**
 * Defines the details of a paid product. All products have an expiry date.
 */
export interface PaidProduct {
  title: string;
  description: string;
  // Monthly cost in cents (USD).
  monthlyCost: number;
  // Yearly cost in cents (USD).
  yearlyCost: number;
}

/**
 * Default details for "Premium Access" product.
 */
export const PREMIUM_ACCESS_PRODUCT_DEFAULT: PaidProduct = {
  title: 'Premium Access',
  description: '',
  monthlyCost: 1000, // $10 USD
  yearlyCost: 8000, // $80 USD
};

/**
 * Default details for "Promoted School Page" product.
 */
export const PROMOTED_PAGE_PRODUCT_DEFAULT: PaidProduct = {
  title: 'Promoted School Page',
  description: '',
  monthlyCost: 500, // $5 USD
  yearlyCost: 4000, // $40 USD
};
