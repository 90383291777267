/** Builds a route based on the provided arguments. */
export function buildRoute(root: string, paths: string[]) {
  return `/${[root, ...paths]
    .filter((path) => path && path.trim().length > 0)
    .join('/')}`;
}

/** Admin routes */

export function getAdminRoute(...paths: string[]) {
  return buildRoute('admin', paths);
}

/** Auth routes */

export function getLogInRoute(nextPage: string = '/', verified?: boolean) {
  if (verified) {
    return `/sign-in?next=${encodeURIComponent(nextPage)}&verified=1`;
  }

  return `/sign-in?next=${encodeURIComponent(nextPage)}`;
}

export function getRegisterRoute(nextPage: string = '/') {
  return `/sign-up?next=${encodeURIComponent(nextPage)}`;
}

export function getResetRoute(nextPage: string = '/') {
  return `/reset?next=${encodeURIComponent(nextPage)}`;
}

export function isAuthRoute(route: string) {
  return (
    route.startsWith('/sign-in') ||
    route.startsWith('/sign-up') ||
    route.startsWith('/reset')
  );
}

/** Home routes */

export function getHomeRoute() {
  return '/';
}

/** Invoice routes */

export function getInvoiceRoute(...paths: string[]) {
  return buildRoute('invoice', paths);
}

/* Personnel routes */

export function getPersonnelRoute(...paths: string[]) {
  return buildRoute('personnel', paths);
}

/** Profile routes */

export function getProfileRoute(...paths: string[]) {
  return buildRoute('profile', paths);
}

/** Promote routes */

export function getPromoteRoute(...paths: string[]) {
  return buildRoute('promote', paths);
}

/** School routes */

export function getAddSchoolRoute() {
  return buildRoute('schools', ['add']);
}

export function getSchoolRoute(...paths: string[]) {
  return buildRoute('schools', paths);
}

/** Other static pages */

export function getPrivacyRoute() {
  return '/privacy';
}

export function getTermsRoute() {
  return '/terms';
}
