import { FirestoreDataConverter } from 'firebase/firestore';
import { ProductDetails } from '../../entities/productDetails';

/**
 * Firestore data converter for ProductDetails entity.
 */
export const PRODUCT_DETAILS_CONVERTER: FirestoreDataConverter<ProductDetails> =
  {
    fromFirestore: function (document, options?) {
      const { premiumAccess, promotedSchoolPage } =
        document.data() as ProductDetails;

      return ProductDetails.create(premiumAccess, promotedSchoolPage);
    },
    toFirestore: function (details: ProductDetails, options?) {
      const { premiumAccess, promotedSchoolPage } = details;

      return { premiumAccess, promotedSchoolPage };
    },
  };
