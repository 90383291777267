export const ADMIN_STATS_SCHOOL_ID = 'schoolStats';

/**
 * Admin school stats.
 */
export interface AdminSchoolStats {
  // Number of approved schools.
  approved: number;
  // Number of schools ready to be reviewed (and potentially approved).
  ready: number;
}

export const DEFAULT_ADMIN_SCHOOL_STATS: AdminSchoolStats = {
  approved: 0,
  ready: 0,
};
