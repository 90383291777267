import NextLink from 'next/link';
import { HTMLProps } from 'react';
import styled from 'styled-components';

export const Anchor = styled.a<{ inHighlightedSection?: boolean }>`
  color: ${(props) =>
    props.inHighlightedSection
      ? props.theme.white.string()
      : props.theme.navy.string()};
  text-decoration: none;
  transition: color 300ms;

  &:active,
  &:hover {
    color: ${(props) =>
      props.inHighlightedSection
        ? props.theme.white.fade(0.4).string()
        : props.theme.navy.mix(props.theme.cyan).string()};
  }
`;

interface LinkProps extends HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;
  href: string;
  inHighlightedSection?: boolean;
}

export function Link({
  children,
  href,
  inHighlightedSection,
  ...anchorProps
}: LinkProps) {
  return (
    <NextLink href={href || '/'} legacyBehavior passHref>
      <Anchor {...anchorProps} inHighlightedSection={inHighlightedSection}>
        {children}
      </Anchor>
    </NextLink>
  );
}
