import { logDebug, logError } from '@reporting';
import { SchoolSearchFilters } from '@school';
import {
  CollectionReference,
  DocumentReference,
  FirestoreDataConverter,
  QueryConstraint,
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { Subscription } from './entities/subscription';
import { ListResourceResponse } from './listResponse';
import { ProductType } from './types';

/** Handles data access for {@link Subscription} entities. */
export class SubscriptionDao {
  static readonly COLLECTION_NAME = 'subscriptions';

  /** Firestore data converter for {@link Subscription} entities. */
  static readonly CONVERTER: FirestoreDataConverter<Subscription> = {
    fromFirestore: function (document, options?) {
      const {
        entityId,
        owner,
        product,
        duration,
        isRecurring,
        expiryDate,
        searchTags,
      } = document.data() as Subscription;

      return Subscription.create(
        entityId,
        owner,
        product,
        duration,
        isRecurring,
        new Date(expiryDate),
        searchTags ?? [],
        document.id,
      );
    },
    toFirestore: function (subscription: Subscription, options?) {
      return {
        entityId: subscription.entityId,
        owner: subscription.owner,
        product: subscription.product,
        duration: subscription.duration,
        isRecurring: subscription.isRecurring,
        expiryDate: subscription.expiryDate.valueOf(),
        searchTags: subscription.searchTags || [],
      };
    },
  };

  /** Returns a {@link Subscription} document by entity ID and product type. */
  static async getSubscriptionByEntityAndProduct(
    entityId: string,
    owner: string,
    product: ProductType,
  ): Promise<Subscription> {
    const results = await getDocs(
      query(
        this.getCollectionRef(),
        where('entityId', '==', entityId),
        where('product', '==', product),
      ),
    );

    if (results.size > 1) {
      logError(
        `Duplicate subscription for entity "${entityId}" and product "${product}".`,
      );
    }

    return results.size
      ? results.docs[0].data()
      : Subscription.forEntity(entityId, owner, product);
  }

  /** Lists promoted school {@link Subscription} documents. */
  static async listPromotedSchoolSubscriptions(
    filters: SchoolSearchFilters,
  ): Promise<ListResourceResponse<Subscription>> {
    const LIMIT = 300;

    logDebug('[dao]', '[listPromotedSchools]', filters);

    const clauses: QueryConstraint[] = [
      where('product', '==', ProductType.PromotedSchool),
    ];
    const clausesDebug = [`where product == ${ProductType.PromotedSchool}`];

    // There can only be one "array-contains" clause at a time.
    // https://firebase.google.com/docs/firestore/query-data/queries#query_limitations
    if (filters.city) {
      clauses.push(where('searchTags', 'array-contains', filters.city));
      clausesDebug.push(`searchTags array-contains ${filters.city}`);
    } else if (filters.state) {
      clauses.push(where('searchTags', 'array-contains', filters.state));
      clausesDebug.push(`searchTags array-contains ${filters.state}`);
    } else if (filters.zip) {
      clauses.push(where('searchTags', 'array-contains', filters.zip));
      clausesDebug.push(`searchTags array-contains ${filters.zip}`);
    }

    // Order results by recency.
    clauses.push(orderBy('expiryDate', 'desc'));
    clausesDebug.push('orderBy expiryDate desc');

    // Page size
    clauses.push(limit(LIMIT));
    clausesDebug.push(`limit == ${LIMIT}`);

    logDebug('[dao]', '[listPromotedSchools]', clausesDebug);

    const results = await getDocs(query(this.getCollectionRef(), ...clauses));

    return ListResourceResponse.fromFirestoreResults(results, LIMIT);
  }

  /** Lists {@link Subscription} documents for the given owner. */
  static async listSubscriptionsForOwner(
    owner: string,
  ): Promise<Subscription[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), where('owner', '==', owner)),
    );

    return results.docs.map((result) => result.data());
  }

  /** Creates or updates a {@link Subscription} document. */
  static async upsertSubscription(
    subscription: Subscription,
  ): Promise<Subscription> {
    if (subscription.id) {
      await setDoc(this.getDocumentRef(subscription.id), subscription);

      return subscription;
    }

    const docRef = await addDoc(this.getCollectionRef(), subscription);

    return subscription.withId(docRef.id);
  }

  /** Returns a Firestore collection reference for subscriptions. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<Subscription> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given subscription. */
  private static getDocumentRef(
    subscriptionId: string,
  ): DocumentReference<Subscription> {
    return doc(
      getFirestore(),
      this.COLLECTION_NAME,
      subscriptionId,
    ).withConverter(this.CONVERTER);
  }
}
