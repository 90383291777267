import logoImg from '@assets/logo.png';
import { signOut } from '@libraries/firebase';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import {
  getAdminRoute,
  getPersonnelRoute,
  getProfileRoute,
} from '../../../routes';
import { ButtonLink } from '../../Button';
import { Dropdown, DropdownMenuItem, DROPDOWN_CLASSNAME } from '../../Dropdown';
import { Link } from '../../Link';
import { NavChildProps } from './Nav';

const NavWrapper = styled.nav<{ highlight: boolean }>`
  display: none;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
  }

  @media print {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const NavMenu = styled.div`
  align-items: center;
  display: flex;

  a {
    margin: auto 1rem;
  }

  .logout {
    margin: auto 1rem auto 0;
  }
`;

const VerticalRule = styled.div<{ inHighlightedSection?: boolean }>`
  background-color: ${(props) =>
    props.inHighlightedSection
      ? props.theme.white.fade(0.8).string()
      : props.theme.grey.fade(0.8).string()};
  display: block;
  height: 40px;
  margin: 0 0.2rem;
  width: 1px;
`;

const MyAccount = styled.div`
  cursor: pointer;
  position: relative;

  &:hover .${DROPDOWN_CLASSNAME} {
    display: block;
  }
`;

export function DesktopNav(props: NavChildProps) {
  return (
    <NavWrapper highlight={props.inHighlightedSection}>
      <LogoWrapper>
        <Link href={props.homeRoute}>
          <Image
            alt="CNA Classes For Me Logo"
            height="60"
            src={logoImg}
            width="60"
          />
        </Link>
      </LogoWrapper>

      <NavMenu>
        {props.isPersonnelFeatureEnabled && (
          <Link
            href={getPersonnelRoute()}
            inHighlightedSection={props.inHighlightedSection}>
            Personnel
          </Link>
        )}

        <Link
          href={props.directoryRoute}
          inHighlightedSection={props.inHighlightedSection}>
          Schools
        </Link>

        <VerticalRule inHighlightedSection={props.inHighlightedSection} />

        <MyAccount>
          <Link
            href={getProfileRoute()}
            inHighlightedSection={props.inHighlightedSection}>
            My Account
          </Link>
          <Dropdown>
            {props.profile ? (
              <>
                {props.personnelProfileRoute && (
                  <DropdownMenuItem>
                    <Link
                      href={props.personnelProfileRoute}
                      style={{ display: 'block' }}>
                      My Personnel Profile
                    </Link>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem>
                  <Link href="#?" onClick={() => signOut()}>
                    Log Out
                  </Link>
                </DropdownMenuItem>
              </>
            ) : (
              <>
                <DropdownMenuItem>
                  <Link href={props.logInRoute}>Log In</Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link href={props.registerRoute}>Sign Up</Link>
                </DropdownMenuItem>
              </>
            )}
          </Dropdown>
        </MyAccount>

        {props.profile?.isAdmin ? (
          <ButtonLink href={getAdminRoute()} margin="0">
            Admin
          </ButtonLink>
        ) : (
          <ButtonLink href={props.newSchoolRoute} margin="0">
            Add your school
          </ButtonLink>
        )}
      </NavMenu>
    </NavWrapper>
  );
}
