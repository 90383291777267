import { PersonnelDao, PersonnelProfile } from '@dao';
import { FirestorePage } from '@libraries/firebase';
import { handleGenericError } from '@utils';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';
import { PersonnelSearchFilters } from '../filters/PersonnelSearchFilters';

interface PersonnelSearchResults {
  filters: PersonnelSearchFilters;
  isLoading: boolean;
  nextPage: FirestorePage;
  results: PersonnelProfile[];
}

/** React hook for retrieving personnel search results. */
export function usePersonnelSearchResults(
  query: ParsedUrlQuery,
): PersonnelSearchResults {
  const [filters, setFilters] = useState(PersonnelSearchFilters.BLANK);
  const [isLoading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState<FirestorePage>(FirestorePage.FIRST);
  const [results, setResults] = useState<PersonnelProfile[]>([]);

  useEffect(() => {
    setLoading(true);
    const searchFilters = PersonnelSearchFilters.fromUrlQuery(query);

    PersonnelDao.listPersonnel(searchFilters)
      .then((personnel) => {
        setFilters(searchFilters);
        setNextPage(personnel.nextPage);
        setResults(personnel.results);
      })
      .catch(handleGenericError)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)]);

  return { filters, isLoading, nextPage, results };
}
