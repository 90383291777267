export function getFileName(
  list: FileList,
  placeholder = '(no file selected)',
): string {
  if (!list) {
    return placeholder;
  }

  const names = [];

  for (let i = 0; i < list.length; i++) {
    names.push(list[0].name);
  }

  return names.join(', ');
}
