import { logDebug, logError } from '@reporting';
import { SchoolSearchFilters } from '@school';
import {
  Address,
  getKeywordDatabase,
  getKeywords,
  searchForAddresses,
} from '@utils';
import {
  CollectionReference,
  DocumentReference,
  FirestoreDataConverter,
  QueryConstraint,
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { AdminSchoolStats } from './admin/constants/schoolStats';
import { updateAdminSchoolStats } from './admin/updateAdminSchoolStats';
import { School, SchoolVisibility, SerializedSchool } from './entities/school';
import { ListResourceResponse } from './listResponse';
import { handleFirebaseError } from './utils/handleFirebaseError';

interface UpsertSchoolResult {
  duplicates: School[];
  isNew: boolean;
  school: School;
}

/** Handles data access for {@link School} entities. */
export class SchoolDao {
  static readonly COLLECTION_NAME = 'schools';

  /** Firestore data converter for {@link School} entities. */
  static readonly CONVERTER: FirestoreDataConverter<School> = {
    fromFirestore: function (document, options?) {
      const serialized = document.data() as SerializedSchool;

      return School.fromSerialized({ ...serialized, id: document.id });
    },
    toFirestore: function (school: School, options?) {
      // Remove ID from serialized object.
      const { id, ...firestoreObject } = school.serialize();

      return firestoreObject;
    },
  };

  /** Creates a {@link School} document. */
  static async createSchool(
    school: School,
    returnSchool: boolean = true,
  ): Promise<School> {
    const docRef = await addDoc(this.getCollectionRef(), school);

    if (!returnSchool) {
      return null;
    }

    return school.withId(docRef.id);
  }

  /** Returns a {@link School} document. */
  static async getSchool(id: string): Promise<School> {
    if (!id) {
      return null;
    }

    const record = await getDoc(this.getDocumentRef(id));

    return record.exists() ? record.data() : null;
  }

  /** Lists approved {@link School} documents. */
  static async getAllSchools(): Promise<School[]> {
    const results = await getDocs(
      query(
        this.getCollectionRef(),
        where('visibility', '==', SchoolVisibility.Approved),
      ),
    );

    return results.docs.map((result) => result.data());
  }

  /**
   * Lists {@link School} documents matching the given
   * {@link SchoolSearchFilters}.
   */
  static async listSchools(
    filters: SchoolSearchFilters,
    visibility: SchoolVisibility = SchoolVisibility.Approved,
  ): Promise<ListResourceResponse<School>> {
    logDebug('[dao]', '[listSchools]', '[filters]', filters);

    // The order of the where clauses might matter (for indexing purposes). It
    // should be kept in sync with upsertSchool().
    const clauses: QueryConstraint[] = [where('visibility', '==', visibility)];
    const clausesDebug = [`where visibility == ${visibility}`];
    const searchQuery = getKeywords(filters.query).join(' ');

    // There can only be 1 range filter. So we can only filter on maxTuition OR
    // name when either are specified. Also, if we include a range filter, it must
    // also be the first ordering field.
    // https://firebase.google.com/docs/firestore/query-data/queries#compound_queries
    // https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations

    if (!filters.city && !filters.state && !filters.zip && searchQuery.length) {
      clauses.push(where('searchDb', 'array-contains', searchQuery));
      clausesDebug.push(`searchDb array-contains ${searchQuery}`);
    } else if (filters.maxTuition) {
      clauses.push(
        where('minTuition', '<=', filters.maxTuition),
        orderBy('minTuition'),
      );
      clausesDebug.push(`minTuition <= ${filters.maxTuition}`);
    }

    // Order results by rating by default.
    clauses.push(orderBy('rating', 'desc'));
    clausesDebug.push('orderBy rating desc');

    if (filters.city) {
      clauses.push(where('city', '==', filters.city));
      clausesDebug.push(`city == ${filters.city}`);
    }

    if (filters.state) {
      clauses.push(where('state', '==', filters.state));
      clausesDebug.push(`state == ${filters.state}`);
    }

    if (filters.zip) {
      clauses.push(where('zip', '==', filters.zip));
      clausesDebug.push(`zip == ${filters.zip}`);
    }

    if (filters.hasDayClasses) {
      clauses.push(where('hasDayClasses', '==', true));
      clausesDebug.push('hasDayClasses == true');
    }

    if (filters.hasEveningClasses) {
      clauses.push(where('hasEveningClasses', '==', true));
      clausesDebug.push('hasEveningClasses == true');
    } else {
      clauses.push(orderBy('hasEveningClasses', 'desc'));
      clausesDebug.push('orderBy hasEveningClasses desc');
    }

    if (filters.hasOfflineClasses) {
      clauses.push(where('hasOfflineClasses', '==', true));
      clausesDebug.push('hasOfflineClasses == true');
    }

    if (filters.hasOnlineClasses) {
      clauses.push(where('hasOnlineClasses', '==', true));
      clausesDebug.push('hasOnlineClasses == true');
    } else {
      clauses.push(orderBy('hasOnlineClasses', 'desc'));
      clausesDebug.push('orderBy hasOnlineClasses desc');
    }

    if (filters.hasWeekendClasses) {
      clauses.push(where('hasWeekendClasses', '==', true));
      clausesDebug.push('hasWeekendClasses == true');
    } else {
      clauses.push(orderBy('hasWeekendClasses', 'desc'));
      clausesDebug.push('orderBy hasWeekendClasses desc');
    }

    if (filters.isVerified) {
      clauses.push(where('isVerified', '==', true));
      clausesDebug.push('isVerified == true');
    } else {
      clauses.push(orderBy('isVerified', 'desc'));
      clausesDebug.push('orderBy isVerified desc');
    }

    // Order results by name (low priority).
    clauses.push(orderBy('name'));
    clausesDebug.push('orderBy name');

    // Page size
    clauses.push(limit(filters.limit));
    clausesDebug.push(`limit == ${filters.limit}`);

    // Page token
    if (filters.page.isValid) {
      const record = await getDoc(this.getDocumentRef(filters.page.entityId));

      clauses.push(startAfter(record));
      clausesDebug.push(`startAfter == ${filters.page.entityId}`);
    }

    logDebug('[dao]', '[listSchools]', '[clauses]', clausesDebug);

    const results = await getDocs(query(this.getCollectionRef(), ...clauses));

    logDebug(
      '[dao]',
      '[listSchools]',
      '[results]',
      results.docs.map((r) => r.data().name),
    );

    return ListResourceResponse.fromFirestoreResults(results, filters.limit);
  }

  /** Lists {@link School} documents for the given editor ID's. */
  static async listSchoolsByEditors(ids: string[]): Promise<School[]> {
    const results = await getDocs(
      query(
        this.getCollectionRef(),
        where('editors', 'array-contains-any', ids),
      ),
    );

    return results.docs.map((result) => result.data());
  }

  /** Lists {@link School} documents for the given school ID's. */
  static async listSchoolsById(ids: string[]): Promise<School[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), where(documentId(), 'in', ids)),
    );

    return results.docs.map((result) => result.data());
  }

  /** Lists {@link School} documents for the given school name. */
  static async listSchoolsByName(name: string): Promise<School[]> {
    const results = await getDocs(
      query(
        this.getCollectionRef(),
        where('visibility', '==', SchoolVisibility.Approved),
        where('name', '==', name),
      ),
    );

    return results.docs.map((result) => result.data());
  }

  /** Updates a {@link School} document. */
  static async updateSchool(school: School): Promise<School> {
    const ref = this.getDocumentRef(school.id);
    const oldDoc = await getDoc(ref);
    const oldSchool = oldDoc.data();

    await setDoc(ref, school);

    // Update school stats manually.
    const updateStats: Partial<AdminSchoolStats> = {};

    if (oldSchool.visibility !== school.visibility) {
      if (oldSchool.visibility === SchoolVisibility.Approved) {
        updateStats.approved = -1;
      } else if (school.visibility === SchoolVisibility.Approved) {
        updateStats.approved = 1;
      }

      if (oldSchool.visibility === SchoolVisibility.Ready) {
        updateStats.ready = -1;
      } else if (school.visibility === SchoolVisibility.Ready) {
        updateStats.ready = 1;
      }

      if (updateStats.approved || updateStats.ready) {
        try {
          await updateAdminSchoolStats(updateStats);
        } catch (error) {
          handleFirebaseError(
            'Could not update admin stats while updating school',
            error,
          );
        }
      }
    }

    return school;
  }

  /** Creates or updates a {@link School} document. */
  static async upsertSchool(school: School): Promise<UpsertSchoolResult> {
    async function findSchool(
      name: string,
      state: string,
      city: string,
    ): Promise<School[]> {
      const nameKeyword = getKeywords(name).join(' ');

      if (!nameKeyword) {
        return [];
      }

      // The order of the where clauses might matter (for indexing purposes). It
      // should be kept in sync with listSchools().
      const whereClauses = [where('searchDb', 'array-contains', nameKeyword)];

      if (city) {
        whereClauses.push(where('city', '==', city));
      }

      if (state) {
        whereClauses.push(where('state', '==', state));
      }

      const queryDef = query(SchoolDao.getCollectionRef(), ...whereClauses);
      const allResults = await getDocs(queryDef);

      // Filter out results that aren't a full match.
      const results = allResults.docs
        .map((result) => result.data())
        .filter((school) => {
          const searchDb = getKeywordDatabase(school.name);

          return nameKeyword === searchDb[searchDb.length - 1];
        });

      // Return all results. If there's more than one, we probably have duplicates.
      return results;
    }

    function getBestAddressResult(school: School, results: Address[]) {
      let pool = [...results];

      if (school.stateCode) {
        pool = pool.filter(({ state }) => state === school.stateCode);
      }

      if (school.zip) {
        pool = pool.filter(({ zip }) => zip === school.zip);
      }

      return pool.length ? pool[0] : null;
    }

    // Check if school already exists.
    const existingSchools = await findSchool(
      school.name,
      school.stateCode,
      school.city,
    );

    const isNew = existingSchools.length < 1;
    const existingRecord = isNew ? null : existingSchools[0];
    const duplicates =
      existingSchools.length > 1
        ? existingSchools.filter((s) => s.id !== existingRecord.id)
        : [];

    // The record to be created or updated.
    let record = isNew ? school : existingSchools[0].merge(school);

    // Add geo-coordinates.
    if (!school.latLon) {
      try {
        const addressResults = await searchForAddresses(school.fullLocation);
        const bestResult = getBestAddressResult(school, addressResults);

        if (bestResult) {
          record = record.mergeAddress(bestResult);
        }
      } catch (error) {
        logError(error);
      }
    }

    const updatedRecord = isNew
      ? await this.createSchool(record)
      : await this.updateSchool(record);

    return { duplicates, isNew, school: updatedRecord };
  }

  /** Deletes a {@link School} document. */
  static async deleteSchool(schoolId: string): Promise<void> {
    await deleteDoc(this.getDocumentRef(schoolId));
  }

  /** Returns a Firestore collection reference for schools. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<School> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given school. */
  private static getDocumentRef(schoolId: string): DocumentReference<School> {
    return doc(getFirestore(), this.COLLECTION_NAME, schoolId).withConverter(
      this.CONVERTER,
    );
  }
}
