import { deserializeArray, deserializeNumber, deserializeString } from '@utils';
import { Entity } from './entity';

/**
 * Metadata for a school. This could be updated by any valid user, so we store
 * this separately to avoid making the school collection writable by everyone.
 */
export class SchoolMetadata extends Entity {
  /** Creates a SchoolMetadata instance with the given data. */
  static create(
    schoolId: string,
    bookmarkedBy: string[],
    numViews: number = 0,
  ): SchoolMetadata {
    return new SchoolMetadata(schoolId, bookmarkedBy, numViews);
  }

  /**
   * Creates a SchoolMetadata instance from a serialized object. This method is
   * meant to be used in conjunction with {@link serialize}.
   */
  static fromSerialized(obj: SerializedSchoolMetadata): SchoolMetadata {
    return SchoolMetadata.create(
      deserializeString(obj.id),
      deserializeArray(obj.bookmarkedBy),
      deserializeNumber(obj.numViews),
    );
  }

  /** Creates a SchoolMetadata instance with the given ID. */
  static forSchoolId(id: string): SchoolMetadata {
    return SchoolMetadata.create(id, []);
  }

  /**
   * Creates a copy of this SchoolMetadata instance with the given profile
   * ID added to the bookmarks.
   */
  addBookmarkFor(profileId: string): SchoolMetadata {
    return SchoolMetadata.create(
      this.id,
      Array.from(new Set([...this.bookmarkedBy, profileId])),
      this.numViews,
    );
  }

  /**
   * Creates a copy of this SchoolMetadata instance with the given profile
   * ID removed from the bookmarks.
   */
  removeBookmarkFor(profileId: string): SchoolMetadata {
    return SchoolMetadata.create(
      this.id,
      this.bookmarkedBy.filter((id) => id !== profileId),
      this.numViews,
    );
  }

  /** Determines whether the given profile has bookmarked this school or not. */
  isBookmarkedBy(profileId: string): boolean {
    return this.bookmarkedBy.includes(profileId);
  }

  /** Compares this SchoolMetadata instance to another. */
  isEqual(other: SchoolMetadata): boolean {
    return (
      other &&
      other.id === this.id &&
      other.bookmarkedBy.join() === this.bookmarkedBy.join() &&
      other.numViews === this.numViews
    );
  }

  /**
   * Serializes this SchoolMetadata instance. The resulting object can be
   * deserialized using {@link fromSerialized}.
   */
  serialize(): SerializedSchoolMetadata {
    return {
      id: this.id,
      bookmarkedBy: this.bookmarkedBy || [],
      numViews: this.numViews,
    };
  }

  protected constructor(
    // Firestore ID, corresponding to school related ID.
    readonly id: string,
    // IDs of the users who have bookmarked the school.
    readonly bookmarkedBy: string[],
    // View counter.
    // TODO: use Google Analytics instead?
    readonly numViews: number,
  ) {
    super('smd');
  }
}

export type SerializedSchoolMetadata = {
  [key: string]: number | string | string[];
};
