import { IS_STAGING_ENV } from '@utils';
import styled from 'styled-components';

const PreviewAppRibbonWrapper = styled.div`
  background: ${(props) => props.theme.cyan.fade(0.7).string()};
  color: ${(props) => props.theme.textColor.string()};
  font-size: 0.5rem;
  margin: 0;
  padding: 0.1rem 0;
  position: fixed;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100vw;
  z-index: 10;
`;

/**
 * Ribbon showing up at the top of the preview (staging) app.
 */
export function PreviewAppRibbon() {
  if (!IS_STAGING_ENV) {
    return null;
  }

  return <PreviewAppRibbonWrapper>Preview</PreviewAppRibbonWrapper>;
}
