import { PaidProduct } from '../constants';

/**
 * Ensures that the details of a product are valid.
 */
export function validateProductDetails(product: PaidProduct): true {
  if (!product) {
    throw new Error('Invalid product');
  }

  if (!product.title) {
    throw new Error('Product must have a title');
  }

  const name = `"${product.title}" product`;

  if (product.monthlyCost > 50000) {
    throw new Error(`Monthly cost for ${name} must be at most $500`);
  }

  if (product.monthlyCost < 100) {
    throw new Error(`Monthly cost for ${name} must be at least $1`);
  }

  if (product.yearlyCost > 500000) {
    throw new Error(`Yearly cost for ${name} must be at most $5,000`);
  }

  if (product.yearlyCost < 100) {
    throw new Error(`Yearly cost for ${name} must be at least $1`);
  }

  return true;
}
