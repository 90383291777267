import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  documentId,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { ProfileType, UserData, UserProfile } from './entities/userProfile';

/** Handles data access for {@link UserProfile} entities. */
export class UserProfileDao {
  static readonly COLLECTION_NAME = 'profiles';

  /** Firestore data converter for {@link UserProfile} entities. */
  static readonly CONVERTER: FirestoreDataConverter<UserProfile> = {
    fromFirestore: function (document, options?) {
      const { paymentData, roles, type, ...data } = document.data();

      return UserProfileDao.fromFirestore(document.id, data, type, roles);
    },
    toFirestore: function (userProfile: UserProfile, options?) {
      return {
        ...userProfile.data,
        // Ensure we have a first and last name.
        firstName: userProfile.data.firstName ?? userProfile.data.name ?? '',
        lastName: userProfile.data.lastName ?? '',
        faves: Array.from(userProfile.data?.faves || []),
        roles: userProfile.roles,
        type: userProfile.type,
      };
    },
  };

  /**
   * Internal method: not meant to be used by the app.
   */
  static fromFirestore(id, data, type, roles) {
    return UserProfile.create(
      {
        ...data,
        faves: new Set(data.faves || []),
        paymentMethods: data.paymentMethods || [],
      } as UserData,
      roles,
      type || ProfileType.Student,
      id,
    );
  }

  /** Returns a {@link UserProfile} document. */
  static async getUserProfile(uid: string): Promise<UserProfile | null> {
    const record = await getDoc(this.getDocumentRef(uid));

    return record.exists() ? record.data() : null;
  }

  /** Lists {@link UserProfile} documents for the given profile ID's. */
  static async listUserProfilesById(ids: string[]): Promise<UserProfile[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), where(documentId(), 'in', ids)),
    );

    return results.docs.map((result) => result.data());
  }

  /** Creates or updates a {@link UserProfile} document. */
  static async upsertUserProfile(profile: UserProfile): Promise<UserProfile> {
    await setDoc(this.getDocumentRef(profile.uid), profile);

    return profile;
  }

  /** Deletes a {@link UserProfile} document. */
  static async deleteUserProfile(profileId: string): Promise<void> {
    await deleteDoc(this.getDocumentRef(profileId));
  }

  /** Returns a Firestore collection reference for profiles. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<UserProfile> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given profile. */
  protected static getDocumentRef(
    profileId: string,
  ): DocumentReference<UserProfile> {
    return doc(getFirestore(), this.COLLECTION_NAME, profileId).withConverter(
      this.CONVERTER,
    );
  }
}
