import {
  deleteObject,
  getBlob,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { CloudFile } from './cloudFile';

export class FirebaseFile extends CloudFile {
  static create(filename: string) {
    return new FirebaseFile(filename);
  }

  get ref() {
    return ref(getStorage(), this.filename);
  }

  getFullPath() {
    return this.ref.fullPath;
  }

  getName() {
    return this.ref.name;
  }

  async delete() {
    const result = await deleteObject(this.ref);

    return true;
  }

  async download() {
    const result = await getBlob(this.ref);

    return result;
  }

  async upload(file: Blob) {
    const result = await uploadBytes(this.ref, file);

    return true;
  }

  protected constructor(readonly filename: string) {
    super();
  }
}
