import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const InteractiveMapWrapper = styled.div`
  height: 100%;
`;

interface InteractiveMapProps extends google.maps.MapOptions {}

export function InteractiveMap(props: InteractiveMapProps) {
  const ref = useRef();
  const { ...mapOptions } = props;

  useEffect(() => {
    // The global "google" variable will not be available when rendering pages
    // on the server.
    if (!window.google?.maps?.Map) {
      return;
    }

    const map = new window.google.maps.Map(ref.current, mapOptions);

    new window.google.maps.Marker({
      position: mapOptions.center,
      map,
    });
  });

  return <InteractiveMapWrapper ref={ref}></InteractiveMapWrapper>;
}
