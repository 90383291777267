import { PersonnelDao, PersonnelProfile } from '@dao';
import { logError } from '@reporting';
import { useEffect, useState } from 'react';

export function usePersonnelProfile(userId: string) {
  const [loading, setLoading] = useState(true);
  const [personnelProfile, setPersonnelProfile] =
    useState<PersonnelProfile>(null);

  useEffect(() => {
    if (!userId) {
      return;
    }

    PersonnelDao.getPersonnelProfile(userId)
      .then(setPersonnelProfile)
      .catch((err) =>
        logError(
          new Error('Could not retrieve or create personnel profile', {
            cause: err,
          }),
        ),
      )
      .finally(() => setLoading(false));
  }, [userId]);

  return { loading, personnelProfile };
}
