import { Analytics, getAnalytics } from 'firebase/analytics';

/** Initializes Google Analytics (only in the browser). */
export function getFirebaseAnalytics(): Analytics | null {
  if (typeof window !== 'object') {
    return null;
  }

  return getAnalytics();
}
