import {
  Address,
  deserializeArray,
  deserializeNumber,
  deserializeString,
  formatPhone,
  formatStateCode,
  getKeywordDatabase,
  getProtocolAndHost,
  getSlug,
  STATES_BY_CODE,
} from '@utils';
import { getSchoolAttributeKey } from '../../school/utils/attributes/getSchoolAttributeKey';
import { SchoolAttributeKey } from '../../school/utils/attributes/keyAttributeMap';
import { BOOLEAN_SCHOOL_ATTRIBUTE_LIST } from '../../school/utils/attributes/list';
import { getSchoolRoute } from '../../shared/routes';
import { SocialHandles } from '../types';
import { Entity } from './entity';
import { UserProfile } from './userProfile';

/**
 * Represents a CNA school. To learn more about classes, see:
 *
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes
 * - https://www.typescriptlang.org/docs/handbook/2/classes.html
 */
export class School extends Entity {
  /** Create a school instance with the given data. */
  static create(
    data: SchoolData,
    visibility = SchoolVisibility.Draft,
    editors: string[] = [],
    schoolId = '',
  ): School {
    return new School(schoolId, visibility, editors, { ...data });
  }

  /**
   * Creates a School instance from a serialized object. This method is meant to
   * be used in conjunction with {@link serialize}.
   */
  static fromSerialized(obj: SerializedSchool): School {
    const { editors, id, visibility, ...data } = obj;

    return School.create(
      {
        ...data,
        description: deserializeString(data.description),
        name: deserializeString(data.name, 'Unknown'),
      },
      deserializeNumber(visibility, SchoolVisibility.Draft),
      deserializeArray(editors),
      deserializeString(id),
    );
  }

  get name(): string {
    return this.data.name?.trim();
  }

  get description(): string {
    return this.data.description?.trim();
  }

  get slug(): string {
    return getSlug(this.id, this.data.name);
  }

  get route(): string {
    return getSchoolRoute(this.slug);
  }

  /** Full URL path including protocol and host. */
  get url(): string {
    return `${getProtocolAndHost()}${this.route}`;
  }

  get editRoute(): string {
    return getSchoolRoute(this.slug, 'edit');
  }

  get city(): string {
    return this.data.city?.trim();
  }

  /** @deprecated Use this.stateCode instead. */
  get state(): string {
    return formatStateCode(this.data.state);
  }

  get stateCode(): string {
    return formatStateCode(this.data.state);
  }

  get stateName(): string {
    const code = this.stateCode;

    return code in STATES_BY_CODE ? STATES_BY_CODE[code] : code;
  }

  get zip(): string {
    return this.data.zip;
  }

  get address(): string {
    // Remove ZIP code.
    let cleaned = this.data.address?.replace(/.([0-9]{5})/, '') ?? '';

    // Remove country.
    cleaned = cleaned.replace(/(, USA)/g, '');

    if (this.city) {
      cleaned = cleaned.replace(`, ${this.city}`, '');
    }

    if (this.stateCode) {
      cleaned = cleaned.replace(`, ${this.stateCode}`, '');
    }

    return cleaned.trim();
  }

  get latLon(): { lat: number; lng: number } {
    const { latitude: lat, longitude: lng } = this.data;

    return lat && lng ? { lat, lng } : null;
  }

  get phone(): string {
    return formatPhone(this.data.phone, '');
  }

  get location(): string {
    if (!this.city && !this.stateCode) {
      return '';
    }

    if (!this.stateCode) {
      return this.city;
    }

    if (!this.city) {
      return this.stateName;
    }

    return `${this.city}, ${this.stateCode}`;
  }

  get fullLocation(): string {
    const address = this.address;
    const location = this.location;

    if (!address) {
      return location;
    }

    if (!location) {
      return address;
    }

    return `${address}, ${location}`;
  }

  get hasDayClasses(): boolean {
    return !!this.data.hasDayClasses;
  }

  get hasEveningClasses(): boolean {
    return !!this.data.hasEveningClasses;
  }

  get hasWeekendClasses(): boolean {
    return !!this.data.hasWeekendClasses;
  }

  get hasOfflineClasses(): boolean {
    return !!this.data.hasOfflineClasses;
  }

  get hasOnlineClasses(): boolean {
    return !!this.data.hasOnlineClasses;
  }

  get attributes(): Partial<{ [Key in SchoolAttributeKey]: boolean }> {
    const attrs = {};

    for (const attribute of BOOLEAN_SCHOOL_ATTRIBUTE_LIST) {
      const key = getSchoolAttributeKey(attribute);

      attrs[key] = !!this.data[key];
    }

    return attrs;
  }

  get directionsUrl(): string {
    const destination = encodeURIComponent(`${this.address}, ${this.location}`);

    return `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  }

  get facebookUrl(): string {
    return this.data.social?.fb
      ? `https://www.facebook.com/${this.data.social.fb}`
      : '';
  }

  get instagramUrl(): string {
    return this.data.social?.ig
      ? `https://www.instagram.com/${this.data.social.ig}`
      : '';
  }

  get linkedInUrl(): string {
    return this.data.social?.in
      ? `https://www.linkedin.com/${this.data.social.in}`
      : '';
  }

  get twitterUrl(): string {
    return this.data.social?.tw
      ? `https://twitter.com/${this.data.social.tw}`
      : '';
  }

  get youtubeUrl(): string {
    return this.data.social?.yt
      ? `https://www.youtube.com/${this.data.social.yt}`
      : '';
  }

  get websiteUrl(): string {
    try {
      const url = new URL(this.data.website);

      return url.toString();
    } catch {
      return '';
    }
  }

  /**
   * Internal score describing the completeness of the school profile. This has
   * no relation to the quality of the school itself.
   */
  get rating(): number {
    // Every school should start with a score of 1 if they have a valid name.
    let internalRating = this.name ? 1 : 0;

    internalRating += this.description ? 1 : 0;
    internalRating += this.stateCode ? 1 : 0;
    internalRating += this.city ? 1 : 0;
    internalRating += this.zip ? 1 : 0;
    internalRating += this.websiteUrl ? 1 : 0;

    // Give a point for having at least one attribute set.
    if (
      this.hasDayClasses ||
      this.hasEveningClasses ||
      this.hasOfflineClasses ||
      this.hasOnlineClasses ||
      this.hasWeekendClasses
    ) {
      internalRating += 1;
    }

    // Give a point for having at least one social page.
    if (
      this.facebookUrl ||
      this.instagramUrl ||
      this.linkedInUrl ||
      this.twitterUrl ||
      this.youtubeUrl
    ) {
      internalRating += 1;
    }

    return internalRating;
  }

  /**
   * Determines whether the school has the basic info to be made public. This
   * should be kept in sync with the submit handler in src/school/edit/Form.tsx.
   */
  get isPublishable(): boolean {
    return (
      this.name.length > 0 &&
      this.description.length > 0 &&
      this.address.length > 0
    );
  }

  /** Formats the video embed URL. */
  get videoEmbedUrl(): string {
    if (!this.data.videoEmbedUrl) {
      return '';
    }

    if (this.data.videoEmbedUrl.startsWith('https://www.youtube.com')) {
      // https://www.youtube.com/embed/wKBu_dEaF9E

      const videoId = this.data.videoEmbedUrl.substring(
        this.data.videoEmbedUrl.lastIndexOf('/') + 1,
      );

      return `${this.data.videoEmbedUrl}?autoplay=1&loop=1&mute=1&playlist=${videoId}`;
    }

    return this.data.videoEmbedUrl;
  }

  get programs(): Program[] {
    return this.data.programs || [];
  }

  get minTuition(): number {
    let tuition = null;

    for (const program of this.programs) {
      if (!tuition || program.tuition < tuition) {
        tuition = program.tuition;
      }
    }

    return tuition;
  }

  get publicBasePath(): string {
    return `/schools/${this.id}/public`;
  }

  get logo(): string {
    return this.data.logo || '';
  }

  get email(): string {
    return this.data.email || '';
  }

  /** Creates a copy of this {@link School} instance. */
  copy(): School {
    return this.withId(this.id);
  }

  /** Merges this school instance with another. */
  merge(other: School): School {
    return School.create(
      { ...other.data, ...this.data },
      this.visibility ?? other.visibility,
      this.editors ?? other.editors,
      this.id ?? other.id,
    );
  }

  /** Merges an address into this school instance. */
  mergeAddress(address: Address): School {
    return School.create(
      { ...this.data, ...address },
      this.visibility,
      this.editors,
      this.id,
    );
  }

  /** Creates a copy of this school instance with the provided ID. */
  withId(id: string): School {
    return School.create({ ...this.data }, this.visibility, this.editors, id);
  }

  /** Creates a copy of this school with the given editor. */
  withEditor(userId: string): School {
    return this.withEditors(userId);
  }

  /** Creates a copy of this {@link School} instance with the given editors. */
  withEditors(...users: Array<string | UserProfile>): School {
    const ids = users.map((user) =>
      typeof user === 'string' ? user : user.uid,
    );

    return School.create(
      { ...this.data },
      this.visibility,
      Array.from(new Set([...this.editors, ...ids])),
      this.id,
    );
  }

  /** Create a copy of this school with the given visibility. */
  withVisibility(visibility: SchoolVisibility): School {
    return School.create({ ...this.data }, visibility, this.editors, this.id);
  }

  /** Creates a copy of this school instance with modified school data. */
  withSchoolData(data: Partial<SchoolData>): School {
    return School.create(
      { ...this.data, ...data },
      this.visibility,
      this.editors,
      this.id,
    );
  }

  /** Compares this school instance to another school instance. */
  isEqual(other: School): boolean {
    return (
      other &&
      other.id === this.id &&
      other.visibility === this.visibility &&
      other.editors.join() === this.editors.join() &&
      other.data.name === this.data.name &&
      other.data.description === this.data.description &&
      other.data.state === this.data.state &&
      other.data.city === this.data.city &&
      other.data.zip === this.data.zip &&
      other.data.address === this.data.address &&
      other.data.phone === this.data.phone &&
      other.data.website === this.data.website &&
      JSON.stringify(other.data.social) === JSON.stringify(this.data.social) &&
      other.data.hasDayClasses === this.data.hasDayClasses &&
      other.data.hasEveningClasses === this.data.hasEveningClasses &&
      other.data.hasWeekendClasses === this.data.hasWeekendClasses &&
      other.data.hasOfflineClasses === this.data.hasOfflineClasses &&
      other.data.hasOnlineClasses === this.data.hasOnlineClasses &&
      other.data.minTuition === this.data.minTuition &&
      other.data.passRate === this.data.passRate &&
      JSON.stringify(other.data.programs) ===
        JSON.stringify(this.data.programs) &&
      other.data.isVerified === this.data.isVerified &&
      other.data.videoEmbedUrl === this.data.videoEmbedUrl &&
      other.data.email === this.data.email
    );
  }

  /**
   * Serializes this School instance. The resulting object can be deserialized
   * using {@link fromSerialized}.
   */
  serialize(includeId = false): SerializedSchool {
    const serialized = {
      ...this.data,
      social: Object(this.data.social ?? {}),
      editors: this.editors,
      isVerified: false,
      minTuition: this.minTuition,
      rating: this.rating,
      searchDb: getKeywordDatabase(this.data.name),
      visibility: this.visibility,
    };

    return includeId ? { ...serialized, id: this.id } : serialized;
  }

  protected constructor(
    // Firestore ID.
    readonly id: string,
    // School status
    readonly visibility: SchoolVisibility,
    // List of users who can edit this school.
    readonly editors: string[],
    // School details.
    readonly data: SchoolData,
  ) {
    super('s');
  }
}

/** Data passed to School class when creating an instance. */
interface SchoolData {
  // Name of the school.
  name: string;
  // Short summary or description.
  description: string;
  // 2-letter state code.
  state?: string;
  // City.
  city?: string;
  // ZIP code.
  zip?: string;
  // Address.
  address?: string;
  // Latitude.
  latitude?: number;
  // Longitude.
  longitude?: number;
  // Contact phone number.
  phone?: string;
  // School website.
  website?: string;
  // Social media links.
  social?: SocialHandles;
  // Whether day classes are offered.
  hasDayClasses?: boolean;
  // Whether evening classes are offered.
  hasEveningClasses?: boolean;
  // Whether weekend classes are offered.
  hasWeekendClasses?: boolean;
  // Whether in-person classes are offered.
  hasOfflineClasses?: boolean;
  // Whether online classes are offered.
  hasOnlineClasses?: boolean;
  // Lowest of all tuitions from the "programs" array (in USD cents). This field
  // is meant to be used in search filtering. For actual tuition amounts, see
  // the "programs" field below.
  minTuition?: number;
  // Student pass rate (percentage).
  passRate?: number;
  // Programs.
  programs?: Program[];
  // Whether school information has been verified.
  isVerified?: boolean;
  // Video embed for schools with subscription.
  videoEmbedUrl?: string;
  // Image filename for the school logo.
  logo?: string;
  // School email.
  email?: string;
}

export enum SchoolVisibility {
  Draft = 1,
  Ready = 2,
  Approved = 9,
  Archived = 10,
}

export interface Program {
  description: string;
  name: string;
  // In USD cents.
  tuition: number;
}

export type SerializedSchool = {
  [key: string]: boolean | number | object | string | string[];
};
