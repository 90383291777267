import { useState } from 'react';
import { getTrackBackground, Range as ReactRange } from 'react-range';
import styled from 'styled-components';

const RangeWrapper = styled.div<{ fixedWidth?: string; maxWidth?: string }>`
  display: ${(props) => (props.fixedWidth ? 'inline-block' : 'flex')};
  text-align: center;
  width: 100%;

  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}

  & > div:first-child {
    ${(props) =>
      props.fixedWidth ? `width: ${props.fixedWidth};` : 'flex-grow: 1;'}
  }

  @media (min-width: 800px) {
    text-align: left;
  }
`;

const Track = styled.div<{
  active?: boolean;
  disabled?: boolean;
  min: number;
  max: number;
  value: number;
}>`
  background: ${(props) =>
    getTrackBackground({
      values: [props.value],
      colors: [props.theme.navy.string(), props.theme.grey.fade(0.8).string()],
      min: props.min,
      max: props.max,
    })};
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  height: 2px;
  justify-content: center;
  margin: 11px 0;
  vertical-align: middle;
`;

const Thumb = styled.div`
  background: ${(props) => props.theme.backgroundColor.string()};
  border: 1px solid ${(props) => props.theme.navy.string()};
  border-radius: 12px;
  box-shadow: 0 0 20px #eee;
  cursor: pointer;
  height: 24px;
  top: 0px;
  vertical-align: middle;
  width: 24px;
`;

const Label = styled.div`
  display: block;
  margin-left: auto;

  @media (min-width: 400px) {
    display: inline-block;
    margin-left: 1.5rem;
  }
`;

interface RangeProps {
  disabled?: boolean;
  fixedWidth?: string;
  min: number;
  max: number;
  maxWidth?: string;
  onChange: (value: number) => void;
  renderLabel?: (value: number) => string;
  step: number;
  value: number;
}

export function Range({
  disabled,
  fixedWidth,
  min,
  max,
  maxWidth,
  onChange,
  renderLabel,
  step,
  value,
}: RangeProps) {
  const [internalValue, setInternalValue] = useState(value);

  return (
    <RangeWrapper maxWidth={maxWidth} fixedWidth={fixedWidth}>
      <ReactRange
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        values={[internalValue]}
        onChange={(values) => setInternalValue(values[0])}
        onFinalChange={(values) => onChange(values[0])}
        renderTrack={({ children, isDragged, props }) => (
          <Track
            {...props}
            active={isDragged}
            disabled={disabled}
            min={min}
            max={max}
            value={internalValue}>
            {children}
          </Track>
        )}
        renderThumb={({ isDragged, props }) => (
          <Thumb {...props} active={isDragged} disabled={disabled} />
        )}
      />

      {renderLabel && <Label>{renderLabel(internalValue)}</Label>}
    </RangeWrapper>
  );
}

Range.defaultProps = {
  disabled: false,
  renderLabel: null,
};
