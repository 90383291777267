import { limit, QueryConstraint, where } from 'firebase/firestore';
import { SchoolClaimStatus } from '../entities/schoolClaim';

/**
 * Filter for listing school claims.
 */
export class SchoolClaimFilter {
  /** Blank filter for retrieving all claims. */
  static BLANK = SchoolClaimFilter.create(null, null);

  /** Creates a new school claim filter instance. */
  static create(
    schoolId: string,
    status: SchoolClaimStatus,
    limit: number = 0,
  ): SchoolClaimFilter {
    return new SchoolClaimFilter(limit, schoolId, status);
  }

  /** Creates a school claim filter with only a school ID. */
  static forSchool(
    schoolId: string,
    status: SchoolClaimStatus,
  ): SchoolClaimFilter {
    return SchoolClaimFilter.create(schoolId, status);
  }

  /**
   * Generates a list of Firestore "where" clauses from the given filters. Note
   * that the ordering of the clauses matters.
   */
  getQueryConstraints(): QueryConstraint[] {
    const clauses = [];

    if (this.schoolId) {
      clauses.push(where('schoolId', '==', this.schoolId));
    }

    if (this.status) {
      clauses.push(where('status', '==', this.status));
    }

    if (this.limit > 0) {
      clauses.push(limit(this.limit));
    }

    return clauses;
  }

  withLimit(limit: number): SchoolClaimFilter {
    return SchoolClaimFilter.create(this.schoolId, this.status, limit);
  }

  protected constructor(
    readonly limit: number,
    readonly schoolId: string,
    readonly status: SchoolClaimStatus,
  ) {}
}
