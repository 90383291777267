import { PersonnelProfile } from '@dao';
import { ResultsWrapper, Section } from '@shared';
import { Filters } from '../filters/Filters';
import { PersonnelSearchFilters } from '../filters/PersonnelSearchFilters';
import { PersonnelSearchFilterOptions } from '../filters/types';
import { Results } from './results/Results';

interface PersonnelDirectoryProps {
  filters: PersonnelSearchFilters;
  isLoading: boolean;
  onUpdateOptions: (options: PersonnelSearchFilterOptions) => void;
  results: PersonnelProfile[];
}

export function PersonnelDirectory({
  filters,
  isLoading,
  onUpdateOptions,
  results,
}: PersonnelDirectoryProps) {
  return (
    <Section>
      <ResultsWrapper>
        <Filters onUpdateOptions={onUpdateOptions} options={filters.options} />
        <Results isLoading={isLoading} personnel={results} />
      </ResultsWrapper>
    </Section>
  );
}
