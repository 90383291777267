import { useState } from 'react';

export function useTextInput(initialValue?: string) {
  const [error, setError] = useState('');
  const [value, setValue] = useState(initialValue || '');

  function clear() {
    setError('');
    setValue('');
  }

  function updateError(error: string) {
    setError(error);
  }

  function updateValue(value: string) {
    setError('');
    setValue(value);
  }

  return { clear, error, updateValue, updateError, value };
}
