import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { logError } from '../../../shared/reporting';
import { handleError } from './handleError';

export async function resetPassword(
  email: string,
  onError: (emailError: string) => void,
  onEmailSent: () => void,
) {
  try {
    await sendPasswordResetEmail(getAuth(), email);

    onEmailSent();
  } catch (error) {
    const [errorField, errorMsg] = handleError(error);

    if (errorField !== 'email') {
      logError(error);
    } else {
      onError(errorMsg);
    }
  }
}
