import { DocumentReference, getDoc } from 'firebase/firestore';
import {
  AdminSchoolStats,
  ADMIN_STATS_SCHOOL_ID,
  DEFAULT_ADMIN_SCHOOL_STATS,
} from '../constants/schoolStats';
import { getAdminDocRef } from './getAdminDocRef';

export function getSchoolStatsDocRef(): DocumentReference<AdminSchoolStats> {
  return getAdminDocRef<AdminSchoolStats>(ADMIN_STATS_SCHOOL_ID);
}

/**
 * Retrieves the document snapshot for school stats.
 */
export async function getSchoolStatsFromRef(
  ref: DocumentReference<AdminSchoolStats>,
): Promise<AdminSchoolStats> {
  const doc = await getDoc(ref);

  return { ...DEFAULT_ADMIN_SCHOOL_STATS, ...doc.data() };
}
