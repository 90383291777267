import { getQueryParamValue } from '@utils';
import { useRouter } from 'next/router';
import { EmailVerifiedMessage } from '../EmailVerifiedMessage';
import { FacebookPixel } from '../FacebookPixel';
import { HeadTitle } from '../HeadTitle';
import { Footer } from './Footer';
import { Nav } from './nav/Nav';

interface PageWrapperProps {
  children: React.ReactNode;
  highlight?: boolean;
  pageTitle?: string;
}

export function PageWrapper({
  children,
  highlight,
  pageTitle,
}: PageWrapperProps) {
  const router = useRouter();
  const emailVerifiedQP = getQueryParamValue(router.query.verified);

  return (
    <>
      <FacebookPixel />
      {pageTitle && <HeadTitle title={pageTitle} />}
      <Nav inHighlightedSection={highlight} />
      <main>
        <EmailVerifiedMessage verified={emailVerifiedQP === '1'} />
        {children}
      </main>
      <Footer />
    </>
  );
}
