import { useEffect, useState } from 'react';
import { logError } from '../../shared/reporting';
import { FirebaseFile } from '../entities/firebaseFile';

/** Gets the blob of the cloud file. */
export function useCloudFile(basePath: string, filename: string) {
  const [blobUrl, setBlobUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!filename) {
      return setLoading(false);
    }

    const firebaseFile = FirebaseFile.create(`${basePath}/${filename}`);

    firebaseFile
      .download()
      .then((blob) => setBlobUrl(URL.createObjectURL(blob)))
      .catch((err) => {
        const downloadError = new Error('Could not download cloud file', {
          cause: err,
        });

        logError(downloadError);
      })
      .finally(() => setLoading(false));

    return () => URL.revokeObjectURL(blobUrl);
    // Hook does not depend on blobUrl
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, filename]);

  return { blobUrl, loading };
}
