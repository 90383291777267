import { School } from '@dao';
import { SchoolSearchFilterOptions } from '../../search/filters/types';
import {
  SchoolAttribute,
  SCHOOL_ATTRIBUTE_DAY,
  SCHOOL_ATTRIBUTE_EVENING,
  SCHOOL_ATTRIBUTE_OFFLINE,
  SCHOOL_ATTRIBUTE_ONLINE,
  SCHOOL_ATTRIBUTE_WEEKEND,
} from './list';

/**
 * Defines keys that are common to {@link School} and
 * {@link SchoolSearchFilterOptions}.
 */
type AllSchoolAndOptionsKeys = keyof School & keyof SchoolSearchFilterOptions;

/**
 * Defines keys that aren't school attributes.
 */
type ExcludedKeys = 'city' | 'state' | 'zip';

/**
 * Defines keys that represent school attributes, e.g. "hasDayClasses".
 */
export type SchoolAttributeKey = Exclude<AllSchoolAndOptionsKeys, ExcludedKeys>;

/**
 * Map of keys to school attributes. This is the inverse of
 * {@link SCHOOL_ATTRIBUTE_KEY_MAP} below.
 */
type KeyAttributeMap = { [Key in SchoolAttributeKey]: SchoolAttribute };
export const SCHOOL_KEY_ATTRIBUTE_MAP: KeyAttributeMap = {
  hasDayClasses: SCHOOL_ATTRIBUTE_DAY,
  hasEveningClasses: SCHOOL_ATTRIBUTE_EVENING,
  hasOfflineClasses: SCHOOL_ATTRIBUTE_OFFLINE,
  hasOnlineClasses: SCHOOL_ATTRIBUTE_ONLINE,
  hasWeekendClasses: SCHOOL_ATTRIBUTE_WEEKEND,
};

/**
 * Map of school attributes to keys. This is the inverse of
 * {@link SCHOOL_KEY_ATTRIBUTE_MAP} above.
 */
type AttributeKeyMap = { [Attr in SchoolAttribute]: SchoolAttributeKey };
export const SCHOOL_ATTRIBUTE_KEY_MAP: AttributeKeyMap = Object.fromEntries(
  Object.entries(SCHOOL_KEY_ATTRIBUTE_MAP).map(([key, name]) => [name, key]),
) as AttributeKeyMap;

/**
 * List of all school attribute keys.
 */
export const SCHOOL_ATTRIBUTE_KEY_LIST = Object.keys(SCHOOL_KEY_ATTRIBUTE_MAP);
