import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { Invoice } from './entities/invoice';

/** Handles data access for {@link Invoice} entities. */
export class InvoiceDao {
  static readonly COLLECTION_NAME = 'invoices';

  /** Firestore data converter for {@link Invoice} entities. */
  static readonly CONVERTER: FirestoreDataConverter<Invoice> = {
    fromFirestore: function (document, options?) {
      const { profileId, date, description, amount, taxes, ...contact } =
        document.data();

      return Invoice.create(
        profileId,
        new Date(date),
        description ?? '',
        amount ?? 0,
        taxes ?? [],
        {
          address: contact.address ?? '',
          city: contact.city ?? '',
          companyName: contact.companyName ?? '',
          email: contact.email ?? '',
          firstName: contact.firstName ?? '',
          lastName: contact.lastName ?? '',
          state: contact.state ?? '',
          zip: contact.zip ?? '',
        },
        document.id,
      );
    },
    toFirestore: function (invoice: Invoice, options?) {
      return {
        profileId: invoice.profileId,
        date: invoice.date.valueOf(),
        description: invoice.description,
        amount: invoice.amount,
        taxes: invoice.taxes,
        ...invoice.contact,
      };
    },
  };

  /** Returns an {@link Invoice} document for the given ID. */
  static async getInvoiceById(
    profileId: string,
    invoiceId: string,
  ): Promise<Invoice> {
    if (!profileId || !invoiceId) {
      return null;
    }

    const record = await getDoc(this.getDocumentRef(invoiceId));

    if (!record.exists()) {
      return null;
    }

    const invoice = record.data();

    if (invoice.profileId !== profileId) {
      return null;
    }

    return invoice;
  }

  /** Lists {@link Invoice} documents for the given profile ID. */
  static async listInvoicesForProfileId(profileId: string): Promise<Invoice[]> {
    const results = await getDocs(
      query(this.getCollectionRef(), where('profileId', '==', profileId)),
    );

    return results.docs.map((result) => result.data());
  }

  /** Returns a Firestore collection reference for invoices. */
  private static getCollectionRef(
    ...segments: string[]
  ): CollectionReference<Invoice> {
    return collection(
      getFirestore(),
      this.COLLECTION_NAME,
      ...segments,
    ).withConverter(this.CONVERTER);
  }

  /** Returns a Firestore document reference for the given invoice. */
  private static getDocumentRef(invoiceId: string): DocumentReference<Invoice> {
    return doc(getFirestore(), this.COLLECTION_NAME, invoiceId).withConverter(
      this.CONVERTER,
    );
  }
}
