import { setDoc } from 'firebase/firestore';
import { ProductDetails } from '../../entities/productDetails';
import { getProductDetailsDocRef } from './utils/getProductDetailsDocRef';

/** Creates or updates the ProductDetails record in Firestore. */
export async function updateProductDetails(
  productDetails: ProductDetails,
): Promise<ProductDetails> {
  await setDoc(getProductDetailsDocRef(), productDetails);

  return productDetails;
}
