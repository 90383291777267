/** Returns the calculated tax amount rounded to the nearest cent. */
export function calculateTaxAmount(baseAmount: number, taxPercentage: number) {
  return Math.round(baseAmount * taxPercentage);
}

/** Converts cents into a dollar string (eg. $DD.CC). */
export function costToString(cost: number, includeSymbols: boolean = true) {
  const dollars = Math.floor(cost / 100);
  const cents = cost - dollars * 100;

  if (includeSymbols) {
    return `$${dollars.toLocaleString()}.${cents.toString().padStart(2, '0')}`;
  }

  return `${dollars}.${cents.toString().padStart(2, '0')}`;
}

/** Converts a dollar string (eg. $DD.CC USD) into cents. */
export function dollarStringToCents(dollarStr: string) {
  const cleanDollarStr = dollarStr.replaceAll(/[^\d.]/g, '');
  const [dollarPart, centPart] = cleanDollarStr.split('.');

  if (centPart?.length === 1) {
    return Number(dollarPart) * 100 + Number(`${centPart}0`);
  }

  if (centPart?.length > 1) {
    return Number(dollarPart) * 100 + Number(centPart.substring(0, 2));
  }

  return Number(dollarPart) * 100;
}

/** Formats a string into a dollar string. */
export function toDollarString(str: string): string {
  if (str.includes('.')) {
    const [dollars, cents] = str.split('.');

    return `${dollars}.${cents.padEnd(2, '0')}`;
  }

  return str;
}
