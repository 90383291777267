import lineImg from '@assets/title-highlight.svg';
import Image from 'next/legacy/image';
import styled from 'styled-components';

const HighlightWrapper = styled.span`
  color: ${(props) => props.theme.cyan.string()};
  position: relative;
`;

const LineWrapper = styled.span<{ lineTop: string; ratioHeight: string }>`
  display: block;
  left: 0;
  height: 1rem;
  position: absolute;
  top: 1.2em;
  width: 110%;

  @media (min-width: 800px) {
    left: -10%;
    padding-top: ${(props) => props.ratioHeight};
    top: ${(props) => props.lineTop};
    width: 120%;
  }
`;

interface TitleHighlightProps {
  children: React.ReactNode;
  lineTop?: string;
  ratioHeight?: string;
}

export function TitleHighlight({
  children,
  lineTop,
  ratioHeight,
}: TitleHighlightProps) {
  return (
    <HighlightWrapper>
      {children}
      <LineWrapper lineTop={lineTop} ratioHeight={ratioHeight}>
        <Image alt="" layout="fill" objectFit="fill" src={lineImg} />
      </LineWrapper>
    </HighlightWrapper>
  );
}

TitleHighlight.defaultProps = {
  lineTop: '2.3rem',
  ratioHeight: '17%',
};
