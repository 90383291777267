import { Address } from '@utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAddressSearchOptions } from '../../../hooks/useAddressSearchOptions';
import { Input } from '../Input';
import { InputProps } from '../types';
import { InputWrapper } from './InputWrapper';
import { AddressMap } from './Map';
import { AddressOptions } from './Options';
import { AddressWithMapWrapper } from './Wrapper';

interface AddressWithMapProps
  extends Omit<InputProps, 'onChange' | 'onSelect'> {
  address?: Address;
  onChange: (value: string) => void;
  onSelect: (value: Address) => void;
  value?: string;
}

export function AddressWithMap({
  address,
  onChange,
  onSelect,
  value,
}: AddressWithMapProps) {
  const addressText = value ?? address?.address;

  const { isLoading, options } = useAddressSearchOptions(addressText);
  const [hasFocus, setFocus] = useState(false);

  // Set a timeout on Input blur to give time for the component tree to update.
  // If the input was blurred after selecting an option, we cancel the timeout.
  // Otherwise, we set focus to false after the timeout.
  const [hasFocusTimeout, setFocusTimeout] = useState(false);

  useEffect(() => {
    if (!hasFocusTimeout) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setFocus(false);
      setFocusTimeout(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [hasFocusTimeout]);

  function handleBlur() {
    setFocusTimeout(true);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  function handleFocus() {
    setFocus(true);
  }

  return (
    <AddressWithMapWrapper>
      <AddressMap address={address} />
      <InputWrapper>
        <Input
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder="Address"
          value={addressText}
        />
        <AddressOptions
          hasFocus={hasFocus}
          onSelect={onSelect}
          options={options}
        />
      </InputWrapper>
    </AddressWithMapWrapper>
  );
}
