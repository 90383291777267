import { useState } from 'react';
import styled from 'styled-components';
import { CollapsedFilters } from './CollapsedFilters';
import { ExpandedFilters } from './ExpandedFilters';
import { PersonnelSearchFilterOptions } from './types';

const FiltersWrapper = styled.div`
  margin-bottom: -1rem;
`;

interface FiltersProps {
  onUpdateOptions: (options: PersonnelSearchFilterOptions) => void;
  options: PersonnelSearchFilterOptions;
}

export function Filters({ onUpdateOptions, options }: FiltersProps) {
  const [collapsed, setCollapsed] = useState(true);

  function handleUpdateOptions(updatedOptions: PersonnelSearchFilterOptions) {
    setCollapsed(true);
    onUpdateOptions(updatedOptions);
  }

  function handleExpand() {
    setCollapsed(false);
  }

  return (
    <FiltersWrapper>
      {collapsed ? (
        <CollapsedFilters onExpand={handleExpand} options={options} />
      ) : (
        <ExpandedFilters
          onUpdateOptions={handleUpdateOptions}
          options={options}
        />
      )}
    </FiltersWrapper>
  );
}
