import { Loader } from '@googlemaps/js-api-loader';
import { logError } from '@reporting';

/**
 * Google API client. The client is loaded asynchronously.
 */
let googleClient: typeof google;

let isLoading = false;

export async function initGoogleClient() {
  if (!process.browser || isLoading || googleClient) {
    return;
  }

  isLoading = true;

  try {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      language: 'en',
      libraries: ['geometry', 'places'],
      region: 'US',
    });

    googleClient = await loader.load();
    isLoading = false;
  } catch (error) {
    logError(error);

    // Set loading to false, and allow trying again later.
    isLoading = false;
  }
}

/**
 * Returns the Google API client if it's loaded, or null otherwise.
 */
export function getGoogleClient(): typeof google | null {
  return googleClient;
}
