import { useEffect, useState } from 'react';

/**
 * Determines the user's location.
 */
export function useUserCoordinates() {
  const [coordinates, setCoordinates] = useState<GeolocationCoordinates>(null);

  useEffect(() => {
    if (!navigator?.geolocation?.getCurrentPosition) {
      return;
    }

    navigator.geolocation.getCurrentPosition(({ coords }) =>
      setCoordinates(coords),
    );
  }, [setCoordinates]);

  return coordinates;
}
