import { UserProfile } from '@dao';
import { Feature, useFeatures } from '@features';
import { useUserProfile } from '@hooks';
import { getRedirectPath } from '@utils';
import { useRouter } from 'next/router';
import { usePersonnelProfile } from '../../../../personnel';
import {
  getAddSchoolRoute,
  getHomeRoute,
  getLogInRoute,
  getPersonnelRoute,
  getRegisterRoute,
  getSchoolRoute,
} from '../../../routes';
import { COLOR_GREY, COLOR_WHITE } from '../../../theme';
import { Section } from '../Section';
import { DesktopNav } from './Desktop';
import { MobileNav } from './Mobile';

interface NavProps {
  inHighlightedSection: boolean;
}

export interface NavChildProps extends NavProps {
  directoryRoute: string;
  homeRoute: string;
  isPersonnelFeatureEnabled: boolean;
  logInRoute: string;
  newSchoolRoute: string;
  personnelProfileRoute: string;
  profile: UserProfile;
  registerRoute: string;
}

export function Nav({ inHighlightedSection }: NavProps) {
  const profile = useUserProfile();
  const { personnelProfile } = usePersonnelProfile(profile?.uid);
  const router = useRouter();
  const redirectTo = getRedirectPath(router);
  const homeRoute = getHomeRoute();
  const directoryRoute = getSchoolRoute();
  const logInRoute = getLogInRoute(redirectTo);
  const personnelProfileRoute = personnelProfile?.isValid
    ? getPersonnelRoute(personnelProfile.userId)
    : '';
  const realNewSchoolRoute = getAddSchoolRoute();
  const registerRoute = getRegisterRoute(redirectTo);
  const newSchoolRoute = profile
    ? realNewSchoolRoute
    : getRegisterRoute(realNewSchoolRoute);
  const borderBottom = inHighlightedSection
    ? `1px solid ${COLOR_WHITE.fade(0.8).string()}`
    : `1px solid ${COLOR_GREY.fade(0.8).string()}`;
  const { enabled } = useFeatures(Feature.PaidProductUi);

  const navProps: NavChildProps = {
    directoryRoute,
    homeRoute,
    inHighlightedSection,
    isPersonnelFeatureEnabled: enabled,
    logInRoute,
    newSchoolRoute,
    personnelProfileRoute,
    profile,
    registerRoute,
  };

  return (
    <Section
      borderBottom={borderBottom}
      highlight={inHighlightedSection}
      paddingBottom="1rem"
      paddingTop="1rem">
      <DesktopNav {...navProps} />
      <MobileNav {...navProps} />
    </Section>
  );
}
