import styled from 'styled-components';

const VerticalNavPageWrapper = styled.div`
  flex-grow: 1;
`;

export const VerticalNavWrapper = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;
`;

interface VerticalNavPageProps {
  children: React.ReactNode;
}

export function VerticalNavPage({ children }: VerticalNavPageProps) {
  return <VerticalNavPageWrapper>{children}</VerticalNavPageWrapper>;
}
