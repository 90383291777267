import { useFileInput } from '@hooks';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { uploadAndDelete } from '../../dao/cloudFile/uploadAndDelete';
import { useCloudFile } from '../../dao/cloudFile/useCloudFile';
import { FirebaseFile } from '../../dao/entities/firebaseFile';
import { logError } from '../reporting';

const ImageWrapper = styled.div`
  cursor: pointer;
  height: 200px;
  position: relative;
  width: 200px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const Status = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.white.string()};
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.7;
  width: 100%;
`;

const EmptyState = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.white.string()};
  color: ${(props) => props.theme.textColor.string()};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 300ms;
  width: 100%;

  :hover {
    opacity: 0.9;
  }
`;

const EditWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.white.string()};
  border-radius: 50%;
  bottom: 0;
  display: flex;
  font-size: 1.4rem;
  height: 28px;
  justify-content: center;
  line-height: initial;
  margin: 10px;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 28px;

  svg {
    max-width: 45%;
    position: relative;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const DropdownWrapper = styled.div<{ show: boolean }>`
  animation: ${fadeIn} 200ms ease-in-out;
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  width: 100%;
`;

const DropdownButton = styled.button`
  background-color: ${(props) => props.theme.backgroundColor.string()};
  border: none;
  cursor: pointer;
  font-size: 20px;
  width: 100%;

  :hover {
    background-color: ${(props) =>
      props.theme.textColor.mix(props.theme.backgroundColor, 0.95).string()};
  }
`;

const FileInput = styled.input`
  display: none;
`;

interface ImageUploadProps {
  alt: string;
  basePath: string;
  emptyText?: string;
  filename?: string;
  onDelete: () => void;
  onUpload: (filename: string) => void;
}

export function ImageUpload({
  alt,
  basePath,
  emptyText,
  filename,
  onDelete,
  onUpload,
}: ImageUploadProps) {
  const [cloudFileName, setCloudFileName] = useState(filename ?? '');
  const [displayDropdown, setDropdown] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>();
  const imageWrapperElement = useRef<HTMLElement>();

  const { blobUrl, loading } = useCloudFile(basePath, filename);
  const localFile = useFileInput();

  function deleteCloudFile() {
    setDeleting(true);

    const firebaseFile = FirebaseFile.create(`${basePath}/${cloudFileName}`);

    firebaseFile
      .delete()
      .then(() => {
        setCloudFileName('');
        localFile.removeFiles();
        onDelete();
      })
      .catch((err) => {
        const deleteError = new Error('Could not delete cloud file', {
          cause: err,
        });

        logError(deleteError);
      })
      .finally(() => setDeleting(false));
  }

  function hideDropdown() {
    setDropdown(false);
  }

  function selectFile() {
    fileInput.current?.click();
    hideDropdown();
  }

  function toggleDropdown() {
    setDropdown((currDropdown) => !currDropdown);
  }

  function uploadFile(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files?.length) {
      return;
    }

    const files = event.target.files;

    setUploading(true);
    localFile.updateFiles(files);

    uploadAndDelete(basePath, files[0], cloudFileName)
      .then((filename) => {
        setCloudFileName(filename);
        onUpload(filename);
      })
      .catch((err) => {
        const uploadError = new Error('Could not upload cloud file', {
          cause: err,
        });

        logError(uploadError);
      })
      .finally(() => setUploading(false));
  }

  useEffect(() => {
    function clickedOutside(event) {
      if (
        displayDropdown &&
        !imageWrapperElement?.current?.contains(event.target)
      ) {
        setDropdown(false);
      }
    }

    document.addEventListener('click', clickedOutside);

    return () => {
      document.removeEventListener('click', clickedOutside);
    };
  });

  if (loading) {
    return (
      <ImageWrapper>
        <Status>Loading...</Status>
      </ImageWrapper>
    );
  }

  if (uploading) {
    return (
      <ImageWrapper>
        <Status>Uploading...</Status>
      </ImageWrapper>
    );
  }

  if (deleting) {
    return (
      <ImageWrapper>
        <Status>Deleting...</Status>
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper ref={imageWrapperElement}>
      {cloudFileName ? (
        <Image
          alt={alt}
          onClick={toggleDropdown}
          src={localFile.blobUrls[0] || blobUrl}
        />
      ) : (
        <EmptyState onClick={toggleDropdown}>
          {emptyText || 'Upload Image'}
        </EmptyState>
      )}

      <EditWrapper onClick={toggleDropdown}>
        <FaPencilAlt color="black" />
      </EditWrapper>

      <DropdownWrapper show={displayDropdown}>
        <DropdownButton onClick={selectFile}>
          {cloudFileName ? 'Change Photo' : 'Upload Photo'}
        </DropdownButton>
        {cloudFileName && (
          <DropdownButton onClick={deleteCloudFile}>
            Remove Photo
          </DropdownButton>
        )}
        <DropdownButton onClick={hideDropdown}>Cancel</DropdownButton>
      </DropdownWrapper>

      <FileInput onChange={uploadFile} ref={fileInput} type="file" />
    </ImageWrapper>
  );
}
